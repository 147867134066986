import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

import { useConnectedFormComponents } from '@/components/form';
import AppError from '@/interfaces/AppError';
import { IVehicleExpenseCategory } from '@/interfaces/vehicles/IVehicleExpenseCategory';
import vehiclesService from '@/services/vehicles';

type Form = {
  name: string;
  description: string;
};

const schema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  description: Yup.string().required('Description is required'),
});

interface Props {
  initialData?: Partial<Form>;
  open: boolean;
  onClose: () => void;
  onSucess: (expenseCategory: IVehicleExpenseCategory) => void;
}

export default function CreateExpenseCategoryModal({
  initialData,
  onSucess,
  open,
  onClose,
}: Props) {
  const [submitting, setSubmitting] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  const { reset, control, handleSubmit } = useForm<Form>({
    resolver: yupResolver(schema),
  });

  const { TextInput } = useConnectedFormComponents<Form>({
    control,
  });

  useEffect(() => {
    console.log(`reset with data? ${JSON.stringify(initialData || {})}`)
    reset({
      name: initialData?.name,
      description: initialData?.description,
    });
  }, [initialData, reset]);

  const handleFormSubmit = useCallback(
    async (data: Form) => {
      try {
        setSubmitting(true);
        const expenseCategory =
          await vehiclesService.createVehicleExpenseCategory(data);
        console.log(JSON.stringify(expenseCategory));
        onSucess(expenseCategory);
        onClose();
      } catch (error) {
        console.error(error);
        enqueueSnackbar(
          error instanceof AppError
            ? error?.message
            : 'Error on creating expense category. Please, try again later',
          { variant: 'error' }
        );
      } finally {
        setSubmitting(false);
      }
    },
    [enqueueSnackbar, setSubmitting, onSucess, onClose]
  );

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={onClose}>
      <DialogTitle>Create Expense Category</DialogTitle>
      <form>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
        >
          <TextInput margin="none" label="Name" fullWidth fieldName="name" />
          <TextInput
            margin="none"
            label="Description"
            fullWidth
            fieldName="description"
            multiline
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={submitting} onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={submitting}
            onClick={handleSubmit(handleFormSubmit)}
          >
            Create
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
