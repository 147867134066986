import { forwardRef, ReactElement } from 'react';
import { IMask, IMaskInput } from 'react-imask';

type OmitDefaultProps<T, K extends string = ''> = Omit<
  T,
  'onAccept' | 'inputRef' | 'onChange' | 'mask' | K
>;

type IMaskInputPropsMapped =
  | { mask: string; maskOptions: OmitDefaultProps<IMask.MaskedPatternOptions> }
  | {
      mask: number | NumberConstructor;
      maskOptions: OmitDefaultProps<IMask.MaskedNumberOptions>;
    }
  | { mask: string; maskOptions: OmitDefaultProps<IMask.MaskedEnumOptions> }
  | {
      mask: DateConstructor;
      maskOptions: OmitDefaultProps<IMask.MaskedDateOptions>;
    }
  | { mask: string; maskOptions: OmitDefaultProps<IMask.MaskedRangeOptions> }
  | { mask: RegExp; maskOptions: OmitDefaultProps<IMask.MaskedPatternOptions> };

export type MaskedInputProps = {
  onChange: (event: { target: { value: string; unmasked?: string } }) => void;
  mask: IMaskInputPropsMapped['mask'];
  maskOptions?: IMaskInputPropsMapped['maskOptions'];
};

const MaskedInput = forwardRef<ReactElement, MaskedInputProps>(
  ({ onChange, mask, maskOptions, ...props }, ref) => (
    <IMaskInput
      {...props}
      overwrite
      {...((maskOptions || {}) as any)}
      mask={mask}
      inputRef={ref}
      onAccept={(value: unknown, m) => {
        onChange({
          target: { value: value as string, unmasked: m.unmaskedValue },
        });
      }}
    />
  )
);

export default MaskedInput;
