import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import { Password, Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Tooltip,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

import { useConnectedFormComponents } from '@/components/form';
import { randomPassword } from '@/helpers/password';
import AppError from '@/interfaces/AppError';
import { UserRoles } from '@/interfaces/system-users/UserRoles';
import systemUsersService from '@/services/system-users';

const schema = yup.object().shape({
  name: yup.string().required('Name is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  password: yup
    .string()
    .min(6, 'Password must have at least 6 characters')
    .required('Password is required'),
  roles: yup.array().of(yup.string().required()).required('Roles are required'),
});

type Form = {
  name: string;
  email: string;
  password: string;
  roles: UserRoles[];
};

export default function SystemUserForm() {
  const { handleSubmit, control, setValue } = useForm<Form>({
    resolver: yupResolver(schema),
  });
  const { id } = useParams();
  const navigate = useNavigate();
  const { TextInput, AutoComplete } = useConnectedFormComponents<Form>({
    control,
  });
  const { enqueueSnackbar } = useSnackbar();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const isEditing = useMemo(() => !!id, [id]);

  const fetchUser = useCallback(async () => {
    if (isEditing && id) {
      const user = await systemUsersService.getSystemUser(id);
      setValue('name', user.name);
      setValue('email', user.email);
      setValue('roles', user.roles);
      setValue('password', 'fetched-password');
    }
  }, [id, isEditing]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  const onSubmit = async (data: Form) => {
    try {
      setSubmitLoading(true);
      if (isEditing && id) {
        await systemUsersService.updateSystemUser(
          id,
          JSON.parse(
            JSON.stringify({
              ...data,
              password:
                data.password === 'fetched-password'
                  ? undefined
                  : data.password,
            })
          )
        );
      } else {
        await systemUsersService.createSystemUser(data);
      }
      navigate('/system-users/list');
    } catch (error) {
      console.error(error);
      enqueueSnackbar(
        error instanceof AppError
          ? error?.message
          : 'Error on creating system user. Please, try again later',
        { variant: 'error' }
      );
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      sx={{ mt: 1 }}
    >
      <Grid
        container
        direction={{ xs: 'column', md: 'row' }}
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={3}>
          <TextInput
            margin="normal"
            required
            label="Name"
            fullWidth
            fieldName="name"
          />
        </Grid>
        <Grid item xs={3}>
          <TextInput
            margin="normal"
            required
            label="Email"
            fullWidth
            fieldName="email"
          />
        </Grid>
        <Grid item xs={3}>
          <TextInput
            // disabled={isEditing}
            margin="normal"
            required
            label="Password"
            fullWidth
            fieldName="password"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: [
                <InputAdornment position="end" key="showPassword">
                  <IconButton
                    // disabled={isEditing}
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(prev => !prev)}
                    onMouseDown={ev => ev.preventDefault()}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>,
                <Tooltip
                  title="Generate random password"
                  key="autofill-password"
                >
                  <InputAdornment position="end">
                    <IconButton
                      // disabled={isEditing}
                      aria-label="auto fill password with random"
                      onClick={() => {
                        setValue('password', randomPassword());
                        setShowPassword(true);
                      }}
                      onMouseDown={ev => ev.preventDefault()}
                      edge="end"
                    >
                      <Password />
                    </IconButton>
                  </InputAdornment>
                </Tooltip>,
              ],
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction={{ xs: 'column', md: 'row' }}
        alignItems="center"
        spacing={2}
        mb={2}
      >
        <Grid item xs={3}>
          <AutoComplete
            multiple
            options={Object.values(UserRoles)}
            fieldName="roles"
            fullWidth
            label="Roles"
            textFieldProps={{
              required: false,
            }}
            defaultValue={[]}
          />
        </Grid>
      </Grid>

      <Grid>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={submitLoading}
          onClick={handleSubmit(onSubmit)}
        >
          {submitLoading ? 'Loading...' : 'Submit'}
        </Button>
      </Grid>
    </Box>
  );
}
