import { PropsWithChildren, ReactNode } from 'react';

import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
} from '@mui/material';

interface Props {
  title: string;
  icon: ReactNode;
}

export default function MaintenanceAccordion({
  title,
  icon,
  children,
}: PropsWithChildren<Props>) {
  return (
    <Accordion sx={{ marginBottom: 2 }}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
      >
        <Stack gap={1} direction="row" alignItems="center">
          {icon}
          <Typography variant="h6">{title}</Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}
