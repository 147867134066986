import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, DialogActions } from '@mui/material';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

import { closeDialog } from '@/components/Dialog';
import { useConnectedFormComponents } from '@/components/form';
import AppError from '@/interfaces/AppError';
import { IMaintenance } from '@/interfaces/maintenance/IMaintenance';
import maintenancesService from '@/services/maintenances';

type Form = {
  vehicleMileage: number;
};

const schema = Yup.object().shape({
  vehicleMileage: Yup.number().min(1).required('vehicleMileage is required'),
});

interface Props {
  maintenanceId: string;
  isFinish?: boolean;
  onUpdate(updatedMaintenance: IMaintenance): void;
}

export default function MaintenanceStateForm({
  maintenanceId,
  isFinish,
  onUpdate,
}: Props) {
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const { handleSubmit, control, ...formHook } = useForm<Form>({
    resolver: yupResolver(schema),
  });

  const { TextInput } = useConnectedFormComponents<Form>({
    control,
  });

  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async (data: Form) => {
    try {
      setSubmitLoading(true);

      const maintenanceData = {
        maintenanceId,
        vehicleMileage: data.vehicleMileage,
      };

      const updatedMaintenancePromise = !isFinish
        ? maintenancesService.startMaintenance(maintenanceData)
        : maintenancesService.finishMaintenance(maintenanceData);

      const updatedMaintenance = await updatedMaintenancePromise;

      onUpdate(updatedMaintenance);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(
        error instanceof AppError
          ? error?.message
          : 'Error on maintenance. Please, try again later',
        { variant: 'error' }
      );
    } finally {
      closeDialog();
      setSubmitLoading(false);
    }
  };

  return (
    <FormProvider control={control} handleSubmit={handleSubmit} {...formHook}>
      <Box sx={{ width: { sm: '350px' } }} component="form" onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          label="Vehicle mileage"
          fullWidth
          fieldName="vehicleMileage"
          required
        />

        <DialogActions>
          <Button onClick={closeDialog} disabled={submitLoading}>
            Cancel
          </Button>

          <Button
            type="submit"
            color="primary"
            disabled={submitLoading}
            onClick={handleSubmit(onSubmit)}
          >
            {submitLoading ? 'Loading...' : 'Submit'}
          </Button>
        </DialogActions>
      </Box>
    </FormProvider>
  );
}
