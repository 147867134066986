import { ReactNode, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  ChipProps,
  List,
  ListItem,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';

import { closeDialog, openDialog } from '@/components/Dialog';
import FileModule from '@/components/FileModule';
import { ConnectedFormComponents } from '@/components/form';
import openConfirmDialog from '@/components/openConfirmDialog';
import { formatCurrency } from '@/helpers/currency';
import { formatDate } from '@/helpers/date';
import {
  formatInstallmentStatus,
  formatInvoiceStatus,
} from '@/helpers/payments';
import { vehicleDisplayName } from '@/helpers/vehicles';
import { useAuthorization } from '@/hooks/auth';
import AppError from '@/interfaces/AppError';
import { IInstallmentStatus } from '@/interfaces/payments/IInstallment';
import IInvoice, { IInvoiceStatus } from '@/interfaces/payments/IInvoice';
import ISubscriptionVehicle from '@/interfaces/subscriptions/ISubscriptionVehicle';
import { UserRoles } from '@/interfaces/system-users/UserRoles';
import EditInvoice from '@/pages/Subscriptions/Invoices/Edit';
import SplitInstalment from '@/pages/Subscriptions/Invoices/SplitInstalment';
import PaymentDetails from '@/pages/Subscriptions/Payments/Details';
import installmentsService from '@/services/installments';
import invoicesService from '@/services/invoices';

function InfoListItem({
  label,
  value,
  to,
  onPressValue,
  onPressLabel,
}: {
  label: string | ReactNode;
  value?: string | number | ReactNode;
  to?: string;
  onPressValue?: () => void;
  onPressLabel?: () => void;
}) {
  return (
    <ListItem>
      <ListItemText
        primary={label}
        primaryTypographyProps={{
          variant: 'body2',
          color: 'text.secondary',
          component: to || onPressValue ? Link : undefined,
          to: to || '',
          onClick: onPressLabel || undefined,
          sx:
            to || onPressValue
              ? { cursor: 'pointer', textDecoration: 'underline' }
              : undefined,
        }}
        secondary={value}
        secondaryTypographyProps={{
          variant: 'body1',
          color: 'text.primary',
          fontWeight: 'bold',
          component: to || onPressValue ? Link : undefined,
          to: to || '',
          onClick: onPressValue || undefined,
        }}
      />
    </ListItem>
  );
}

type Props = {
  invoices: IInvoice[];
  onChangeData?: () => void;
  subscriptionVehicles: ISubscriptionVehicle[];
};

export default function SubscriptionInvoicesList({
  invoices,
  onChangeData = () => {},
  subscriptionVehicles,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { checkUserHasRole } = useAuthorization();
  const isAdminUser = useMemo(
    () => checkUserHasRole([UserRoles.ADMIN]),
    [checkUserHasRole]
  );

  const checkIfInvoiceIsCancellable = useCallback(
    (invoice: IInvoice) =>
      invoice.installments.some(
        installment => installment.status === IInstallmentStatus.PAID
      ),
    []
  );

  const getInvoiceVehicle = useCallback(
    (invoice: IInvoice) => {
      const subscriptionVehicle = subscriptionVehicles.find(
        subVehicle => subVehicle.id === invoice.subscriptionVehicle.id
      );
      if (subscriptionVehicle) {
        return vehicleDisplayName(subscriptionVehicle.vehicle);
      }
      return invoice.subscriptionVehicle.vehicle.vin;
    },
    [subscriptionVehicles]
  );

  return (
    <Box sx={{ maxHeight: 500, overflow: 'auto', padding: 1 }}>
      {invoices.map(invoice => (
        <Accordion
          key={invoice.id}
          elevation={3}
          sx={{
            marginBottom: 1,
          }}
        >
          <AccordionSummary>
            <Stack
              direction="row"
              spacing={1}
              flex={1}
              justifyContent="space-between"
            >
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="body1"># - {invoice.name}</Typography>
                <Chip
                  color={
                    {
                      [IInvoiceStatus.PAID]: 'success',
                      [IInvoiceStatus.OPEN]: 'info',
                      [IInvoiceStatus.PAST_DUE]: 'error',
                      [IInvoiceStatus.RESCHEDULED]: 'warning',
                    }[invoice.status] as ChipProps['color']
                  }
                  label={formatInvoiceStatus(invoice.status)}
                />
                <Typography
                  variant="body1"
                  sx={{
                    lineHeight: '100%',
                    fontWeight: 'bold',
                  }}
                >
                  {formatCurrency(
                    invoice.status === IInvoiceStatus.PAID
                      ? invoice.totalAmount
                      : invoice.installments
                          .filter(i => i.status !== IInstallmentStatus.PAID)
                          .reduce((acc, i) => acc + i.totalAmount, 0)
                  )}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyItems="flex-end"
                spacing={1}
              >
                {invoice.nextDueDate && (
                  <Typography
                    variant="body1"
                    sx={{
                      lineHeight: '100%',
                      fontWeight: 'bold',
                    }}
                  >
                    {formatDate(invoice.nextDueDate)}
                  </Typography>
                )}
                <Button
                  color="error"
                  variant="outlined"
                  disabled={
                    checkIfInvoiceIsCancellable(invoice) || !isAdminUser
                  }
                  onClick={() => {
                    openConfirmDialog({
                      onConfirm: async () => {
                        try {
                          await invoicesService.cancelInvoice(invoice.id);
                          enqueueSnackbar('Invoice cancelled successfully', {
                            variant: 'success',
                          });
                          closeDialog();
                          if (onChangeData) {
                            onChangeData();
                          }
                        } catch (e) {
                          console.error(e);
                          enqueueSnackbar(
                            'There was an error to cancel your invoice',
                            {
                              variant: 'error',
                            }
                          );
                        }
                      },
                    });
                  }}
                >
                  Cancel invoice
                </Button>
                <Button
                  color="primary"
                  variant="outlined"
                  disabled={
                    invoice.status === IInvoiceStatus.PAID || !isAdminUser
                  }
                  onClick={() => {
                    openDialog({
                      title: 'Edit invoice',
                      content: (
                        <EditInvoice
                          invoice={invoice}
                          onEditSuccess={onChangeData}
                          subscriptionVehicles={subscriptionVehicles}
                        />
                      ),
                    });
                  }}
                >
                  Edit invoice
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    openDialog({
                      title: 'Files',
                      content: (
                        <FileModule
                          moduleName="invoices"
                          entityId={invoice.id}
                        />
                      ),
                    });
                  }}
                >
                  Files
                </Button>
              </Stack>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <InfoListItem
              label="Invoice Vehicle:"
              value={getInvoiceVehicle(invoice)}
            />
            <InfoListItem
              label="Total Invoice amount:"
              value={formatCurrency(invoice.totalAmount)}
            />
            <List
              sx={{
                marginLeft: 20,
              }}
            >
              {invoice.installments.map((installment, index) => (
                <Accordion
                  elevation={0}
                  sx={{
                    border: 1,
                  }}
                  key={installment.id}
                >
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Stack direction="row" alignItems="center" gap={2} flex={1}>
                      <Typography variant="body1">
                        Installment #{index + 1}
                      </Typography>
                      <Chip
                        size="small"
                        color={
                          {
                            [IInstallmentStatus.PAID]: 'success',
                            [IInstallmentStatus.OPEN]: 'info',
                            [IInstallmentStatus.PAST_DUE]: 'error',
                            [IInstallmentStatus.RESCHEDULED]: 'warning',
                          }[installment.status] as ChipProps['color']
                        }
                        variant="outlined"
                        label={formatInstallmentStatus(installment.status)}
                      />
                      <Typography variant="body2" flex={1}>
                        {formatCurrency(installment.totalAmount)}
                      </Typography>

                      <Typography variant="body2">
                        {formatDate(
                          {
                            [IInstallmentStatus.PAID]: installment.paidAt,
                            [IInstallmentStatus.OPEN]: installment.dueDate,
                            [IInstallmentStatus.PAST_DUE]:
                              installment.rescheduleDate || installment.dueDate,
                            [IInstallmentStatus.RESCHEDULED]:
                              installment.rescheduleDate,
                          }[installment.status] as Date
                        )}
                      </Typography>
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Stack direction="row" gap={2}>
                      {installment.status !== IInstallmentStatus.OPEN && (
                        <InfoListItem
                          label="Due Date"
                          value={formatDate(installment.dueDate)}
                        />
                      )}
                      {installment.rescheduleDate && (
                        <InfoListItem
                          label="Reschedule Date"
                          value={formatDate(installment.rescheduleDate)}
                        />
                      )}
                    </Stack>
                    <Stack flex={1} direction="row" gap={2}>
                      {!!installment.paymentsHistory.length && (
                        <Box flex={1}>
                          {installment.paymentsHistory.map(payment => {
                            if (payment.refundPaymentId) {
                              return null;
                            }

                            const refundPayment =
                              installment.paymentsHistory.find(
                                pay => pay.refundPaymentId === payment.id
                              );

                            return (
                              <Box mb={-2} key={payment.id}>
                                <InfoListItem
                                  label={
                                    <>
                                      <Tooltip
                                        title={`Payment ID: ${payment.id}`}
                                      >
                                        <Typography
                                          component="span"
                                          sx={{
                                            textDecoration: 'underline',
                                            cursor: 'pointer',
                                          }}
                                        >
                                          Payment #
                                          {payment.id.substring(
                                            payment.id.length - 4
                                          )}
                                        </Typography>
                                      </Tooltip>
                                      {!!refundPayment && (
                                        <Chip
                                          component="span"
                                          color="error"
                                          label="Refunded"
                                          size="small"
                                          sx={{ ml: 1 }}
                                        />
                                      )}
                                    </>
                                  }
                                  onPressLabel={() => {
                                    openDialog({
                                      content: (
                                        <PaymentDetails
                                          payment={payment}
                                          refundPayment={refundPayment}
                                        />
                                      ),
                                      title: `Payment details`,
                                      actions: (
                                        <PaymentDetails.Actions
                                          payment={payment}
                                          refundPayment={refundPayment}
                                          onRefund={() => {
                                            if (onChangeData) {
                                              onChangeData();
                                            }
                                          }}
                                        />
                                      ),
                                    });
                                  }}
                                />
                              </Box>
                            );
                          })}
                        </Box>
                      )}
                      {installment.status === IInstallmentStatus.PAID && (
                        <Box flex={1}>
                          <InfoListItem
                            label="Payment Total Value"
                            value={formatCurrency(
                              installment.payment?.amount ?? 0
                            )}
                          />
                        </Box>
                      )}
                      {installment.status === IInstallmentStatus.PAID &&
                        installment.payment?.cardEnd && (
                          <Box flex={1}>
                            <InfoListItem
                              label="Card End"
                              value={installment.payment?.cardEnd}
                            />
                          </Box>
                        )}
                    </Stack>

                    <Stack
                      mt={2}
                      justifyContent="flex-end"
                      gap={2}
                      direction="row"
                    >
                      <Button
                        disabled={
                          installment.status !== IInstallmentStatus.OPEN ||
                          !isAdminUser
                        }
                        variant="outlined"
                        color="secondary"
                        onClick={() => {
                          openDialog({
                            title: `Change ${invoice.name} Installment #${
                              index + 1
                            }`,
                            content: {
                              onSubmit: async values => {
                                try {
                                  await installmentsService.editInstallment(
                                    installment.id,
                                    values.newDate as Date
                                  );
                                  closeDialog();

                                  if (onChangeData) {
                                    onChangeData();
                                  }
                                } catch (error) {
                                  console.error(error);
                                  enqueueSnackbar(
                                    error instanceof AppError
                                      ? error?.message
                                      : 'Error on editing installment. Please, try again later',
                                    { variant: 'error' }
                                  );
                                }
                              },
                              description:
                                'Set the new date for this installment',
                              inputs: [
                                {
                                  key: 'newDate',
                                  type: ConnectedFormComponents.DATE_PICKER,
                                  props: {
                                    fieldName: 'newDate',
                                    label: 'New Date',
                                    minDate: new Date(),
                                    defaultValue: installment.dueDate,
                                  },
                                },
                              ],
                            },
                          });
                        }}
                      >
                        Change Due Date
                      </Button>

                      <Button
                        disabled={
                          installment.status === IInstallmentStatus.PAID ||
                          !isAdminUser
                        }
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          openDialog({
                            title: `Split ${invoice.name} Installment #${
                              index + 1
                            }`,
                            content: (
                              <SplitInstalment
                                installment={installment}
                                onSplitSuccess={onChangeData}
                              />
                            ),
                          });
                        }}
                      >
                        Split
                      </Button>

                      <Button
                        disabled={
                          installment.status === IInstallmentStatus.PAID ||
                          !isAdminUser
                        }
                        variant="contained"
                        onClick={() => {
                          openDialog({
                            title: 'Reschedule Installment',
                            content: {
                              onSubmit: async values => {
                                try {
                                  await installmentsService.rescheduleInstallment(
                                    installment.id,
                                    values.newDate as Date
                                  );
                                  closeDialog();

                                  if (onChangeData) {
                                    onChangeData();
                                  }
                                } catch (error) {
                                  console.error(error);
                                  enqueueSnackbar(
                                    error instanceof AppError
                                      ? error?.message
                                      : 'Error on rescheduling installment. Please, try again later',
                                    { variant: 'error' }
                                  );
                                }
                              },
                              description:
                                'Set the rescheduled date for this installment',
                              inputs: [
                                {
                                  key: 'newDate',
                                  type: ConnectedFormComponents.DATE_PICKER,
                                  props: {
                                    fieldName: 'newDate',
                                    label: 'New Date',
                                    minDate: new Date(),
                                    defaultValue: installment.dueDate,
                                  },
                                },
                              ],
                            },
                          });
                        }}
                      >
                        Reschedule
                      </Button>
                    </Stack>
                  </AccordionDetails>
                </Accordion>
              ))}
            </List>
          </AccordionDetails>
          {/* <CardActions /> */}
        </Accordion>
      ))}
    </Box>
  );
}
