import { useNavigate } from 'react-router-dom';

import { Grid } from '@mui/material';

import PageCard from '@/components/PageCard';
import { CUSTOMER_PAGES } from '@/constants/customer-pages';

export default function Customers() {
  const navigate = useNavigate();

  return (
    <Grid
      container
      spacing={2}
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      {CUSTOMER_PAGES.menuItems.map(
        ({ icon: Icon, path, title, showOnNavMenus }) => {
          if (showOnNavMenus !== undefined && !showOnNavMenus) {
            return null;
          }
          return (
            <Grid key={path} item onClick={() => navigate(path)}>
              <PageCard path={path} title={title} CardIcon={Icon} />
            </Grid>
          );
        }
      )}
    </Grid>
  );
}
