import React, { useEffect, useMemo, useState } from 'react';

interface MetaContextType {
  pageTitle: string | null;
  setPageTitle: React.Dispatch<React.SetStateAction<string | null>>;
}

const MetaContext = React.createContext<MetaContextType>({
  pageTitle: null,
  setPageTitle: () => {},
});

export function MetaProvider({ children }: { children: React.ReactNode }) {
  const [pageTitle, setPageTitle] = useState<string | null>(null);

  useEffect(() => {
    console.log('MetaProvider: useEffect', pageTitle);
    if (pageTitle) {
      document.title = `${pageTitle} - DriveOn Subscriptions`;
    } else {
      document.title = 'DriveOn Subscriptions Admin';
    }
  }, [pageTitle]);

  const value = useMemo(() => ({ pageTitle, setPageTitle }), [pageTitle]);

  return <MetaContext.Provider value={value}>{children}</MetaContext.Provider>;
}

export function useMeta() {
  const context = React.useContext(MetaContext);

  if (!context) {
    throw new Error('useMeta must be used within a MetaProvider');
  }

  useEffect(() => () => context.setPageTitle(null), []);

  return context;
}
