import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { type SchedulerHelpers } from '@aldabil/react-scheduler/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, DialogContent, DialogTitle } from '@mui/material';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

import { useConnectedFormComponents } from '@/components/form';
import {
  convertMaintenanceToProcessedEvent,
  parseMaintenanceDatesISOToDuration,
} from '@/helpers/maintenances';
import AppError from '@/interfaces/AppError';
import { IMaintenance } from '@/interfaces/maintenance/IMaintenance';
import maintenancesService from '@/services/maintenances';

import MaintenanceFormActions from './MaintenanceFormActions';
import MaintenanceTypeFields, {
  type MaintenanceTypeFormFields,
} from './MaintenanceTypeFields';
import MaintenanceVehicleFields, {
  type MaintenanceVehicleFormFields,
} from './MaintenanceVehicleFields';

type Form = MaintenanceTypeFormFields &
  MaintenanceVehicleFormFields & {
    startVehicleMileage: number;
    description?: string;
  };

type Props = {
  scheduler: SchedulerHelpers;
};

const schema = Yup.object().shape({
  maintenanceType: Yup.object()
    .shape({
      id: Yup.string().required(),
    })
    .required('Maintenance Type is required'),
  vehicle: Yup.object()
    .shape({
      vin: Yup.string().required(),
    })
    .required('Vehicle is required'),
  duration: Yup.number(),
  description: Yup.string().nullable(),
});

export default function MaintenanceForm({ scheduler }: Props) {
  const event = scheduler.edited;

  const { enqueueSnackbar } = useSnackbar();

  const maintenanceEdit = event?.maintenance as IMaintenance | undefined;

  const defaultValues: Partial<Form> = {
    duration: 0,
    description: maintenanceEdit?.description,
    maintenanceType: maintenanceEdit?.type,
    vehicle: maintenanceEdit?.vehicle,
    subscription: maintenanceEdit?.subscriptionVehicle?.subscription,
  };

  const { handleSubmit, control, ...formHook } = useForm<Form>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { TextInput } = useConnectedFormComponents<Form>({
    control,
  });

  const onSubmit = async (data: Form) => {
    try {
      scheduler.loading(true);

      let maintenance;

      if (!maintenanceEdit) {
        maintenance = await maintenancesService.createMaintenance({
          vehicleId: data.vehicle.vin,
          maintenanceTypeId: data.maintenanceType.id,
          startDate: scheduler.state.start.value,
          duration: data.duration,
          description: data.description,
          ...(data.subscription
            ? { subscriptionVehicleId: data.subscription.currentVehicle.id }
            : {}),
        });
      } else {
        maintenance = await maintenancesService.editMaintenance({
          maintenanceId: maintenanceEdit.id,
          vehicleId: data.vehicle.vin,
          maintenanceTypeId: data.maintenanceType.id,
          startDate: scheduler.state.start.value,
          duration: data.duration,
          description: data.description,
          ...(data.subscription
            ? { subscriptionVehicleId: data.subscription.currentVehicle.id }
            : {}),
        });
      }

      if (maintenance) {
        const addedUpdatedEvent =
          convertMaintenanceToProcessedEvent(maintenance);
        scheduler.onConfirm(
          addedUpdatedEvent,
          maintenanceEdit ? 'edit' : 'create'
        );
      }
      scheduler.close();
    } catch (error) {
      console.error(error);
      enqueueSnackbar(
        error instanceof AppError
          ? error?.message
          : `Error ${
              maintenanceEdit ? 'updating' : 'booking'
            } maintenance. Please, try again later`,
        { variant: 'error' }
      );
    } finally {
      scheduler.loading(false);
    }
  };

  const maintenanceDuration = useMemo(
    () =>
      maintenanceEdit
        ? parseMaintenanceDatesISOToDuration(
            maintenanceEdit.expectedStartDate,
            maintenanceEdit.expectedEndDate
          )
        : undefined,
    [maintenanceEdit]
  );

  return (
    <FormProvider control={control} handleSubmit={handleSubmit} {...formHook}>
      <Box
        sx={{ width: { sm: '500px' } }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <DialogTitle>
          {!event ? 'Book Maintenance' : 'Edit Maintenance'}
        </DialogTitle>

        <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
          <MaintenanceVehicleFields />

          <MaintenanceTypeFields duration={maintenanceDuration} />

          <TextInput
            fieldName="description"
            label="Description"
            fullWidth
            multiline
          />
        </DialogContent>

        <MaintenanceFormActions isEdit={!!event} onClose={scheduler.close} />
      </Box>
    </FormProvider>
  );
}
