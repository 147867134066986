import { useCallback, useEffect, useState } from 'react';

import { Add, Delete, OpenInNew } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';

import AppError from '@/interfaces/AppError';
import IEntityFile from '@/interfaces/files/IEntityFile';
import entityFilesService from '@/services/entityFiles';

import { closeDialog } from '../Dialog';
import openConfirmDialog, {
  updateOpenedConfirmDialog,
} from '../openConfirmDialog';
import UploadDropzoneDialog from '../UploadDropzoneDialog';

type Props = {
  moduleName: string;
  entityId: string;
  customPathToSave?: string;
};

export default function FileModule({
  moduleName,
  entityId,
  customPathToSave,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState<IEntityFile[]>([]);
  const [showDropZone, setShowDropZone] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const fetchFiles = useCallback(async () => {
    try {
      setIsLoading(true);
      const fetchedFiles = await entityFilesService.listEntityFiles({
        moduleName,
        entityId,
      });
      setFiles(fetchedFiles);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(
        error instanceof AppError
          ? error?.message
          : 'Error on fetching files. Please, try again later',
        { variant: 'error' }
      );
    }
    setIsLoading(false);
  }, [moduleName, entityId]);

  useEffect(() => {
    fetchFiles();
  }, [fetchFiles]);

  const deleteFile = useCallback(
    (fileId: string) => {
      openConfirmDialog({
        onConfirm: async () => {
          try {
            updateOpenedConfirmDialog({
              confirmButtonText: 'Deleting...',
              confirmButtonProps: {
                disabled: true,
              },
              cancelButtonProps: {
                disabled: true,
              },
            });
            await entityFilesService.deleteEntityFile(fileId);
            closeDialog();
            fetchFiles();
          } catch (error) {
            closeDialog();
            console.error(error);
            enqueueSnackbar(
              error instanceof AppError
                ? error?.message
                : 'Error on deleting file. Please, try again later',
              { variant: 'error' }
            );
          }
        },
      });
    },
    [enqueueSnackbar, fetchFiles]
  );

  return (
    <>
      <Stack direction="column" p={4}>
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="center"
        >
          {!showDropZone && (
            <Button
              variant="outlined"
              onClick={() => setShowDropZone(true)}
              startIcon={<Add />}
              disabled={isLoading || showDropZone}
              style={{ marginBottom: 32 }}
            >
              Add files
            </Button>
          )}
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Box p={2}>
              {files.length === 0 ? (
                <Typography variant="body2" align="center">
                  There are no files for this entity
                </Typography>
              ) : (
                <Typography variant="body1">
                  These are the files related to this entity:
                </Typography>
              )}
            </Box>
          )}
        </Stack>
        <List>
          {files.map(file => (
            <ListItem key={file.id}>
              <ListItemText primary={file.path} />
              <Tooltip title="Preview">
                <IconButton
                  aria-label="preview"
                  onClick={() => window.open(encodeURI(file.url!), '_blank')}
                >
                  <OpenInNew />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete file">
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    deleteFile(file.id);
                  }}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            </ListItem>
          ))}
        </List>
      </Stack>
      <UploadDropzoneDialog
        open={showDropZone}
        dialogTitle="Upload Files"
        acceptedFiles={['image/jpeg', 'image/png', 'application/pdf']}
        onSave={async filesToUpload => {
          try {
            setIsLoading(true);
            setShowDropZone(false);

            await entityFilesService.uploadEntityFiles({
              moduleName,
              entityId,
              files: filesToUpload,
              customPath: customPathToSave,
            });
            enqueueSnackbar('Files uploaded successfully', {
              variant: 'success',
            });
            await fetchFiles();
          } catch (error) {
            setIsLoading(false);
            console.error(error);
            enqueueSnackbar(
              error instanceof AppError
                ? error?.message
                : 'Error on uploading files',
              { variant: 'error' }
            );
          }
        }}
        onClose={() => setShowDropZone(false)}
      />
    </>
  );
}
