import { CUSTOMER_PAGES } from '@/constants/customer-pages';
import { SYSTEM_USER_PAGES } from '@/constants/system-user-pages';
import { IPageDetails } from '@/interfaces/IPageDetails';

import { MAINTENANCE_PAGES } from './maintenance-pages';
import { REPORTS_PAGES } from './reports-pages';
import { SUBSCRIPTION_PAGES } from './subscription-pages';
import { VEHICLE_PAGES } from './vehicle-pages';

export const PAGES: IPageDetails[] = [
  VEHICLE_PAGES,
  SUBSCRIPTION_PAGES,
  CUSTOMER_PAGES,
  SYSTEM_USER_PAGES,
  REPORTS_PAGES,
  MAINTENANCE_PAGES,
];
