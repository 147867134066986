import { ICreateMaintenanceDTO } from '@/interfaces/maintenance/ICreateMaintenanceDTO';
import ICreateMaintenanceTaskDTO from '@/interfaces/maintenance/ICreateMaintenanceTaskDTO';
import { ICreateMaintenanceTypeDTO } from '@/interfaces/maintenance/ICreateMaintenanceTypeDTO';
import IEditMaintenanceDTO from '@/interfaces/maintenance/IEditMaintenanceDTO';
import { IEditMaintenanceTypeDTO } from '@/interfaces/maintenance/IEditMaintenanceTypeDTO';
import IListMaintenanceTasksDTO from '@/interfaces/maintenance/IListMaintenanceTasksDTO';
import { IMaintenance } from '@/interfaces/maintenance/IMaintenance';
import { IMaintenanceTasks } from '@/interfaces/maintenance/IMaintenanceTasks';
import { IMaintenanceType } from '@/interfaces/maintenance/IMaintenanceType';
import IStartMaintenanceDTO from '@/interfaces/maintenance/IStartMaintenanceDTO';
import { IUpdateMaintenanceTasksDTO } from '@/interfaces/maintenance/IUpdateMaintenanceTasksDTO';
import { IVehicleExpense } from '@/interfaces/vehicles/IVehicleExpense';
import { http } from '@/services/http';

class MaintenancesService {
  static BASE_PATH = 'maintenances';

  async listMaintenanceTypes(): Promise<IMaintenanceType[]> {
    const { data } = await http.get(`/${MaintenancesService.BASE_PATH}/types`);
    return data;
  }

  async createMaintenanceType(
    maintenanceTypeData: ICreateMaintenanceTypeDTO
  ): Promise<IMaintenanceType> {
    const { data } = await http.post(
      `/${MaintenancesService.BASE_PATH}/types`,
      maintenanceTypeData
    );
    return data;
  }

  async updateMaintenanceType(
    maintenanceTypeId: string,
    maintenanceTypeData: IEditMaintenanceTypeDTO
  ): Promise<IMaintenanceType> {
    const { data } = await http.put(
      `/${MaintenancesService.BASE_PATH}/types/${maintenanceTypeId}`,
      maintenanceTypeData
    );
    return data;
  }

  async getMaintenanceType(id: string): Promise<IMaintenanceType> {
    const { data } = await http.get(
      `/${MaintenancesService.BASE_PATH}/types/${id}`
    );
    return data;
  }

  async listMaintenances(): Promise<IMaintenance[]> {
    const { data } = await http.get(`/${MaintenancesService.BASE_PATH}`);
    return data;
  }

  async createMaintenance({
    vehicleId,
    maintenanceTypeId,
    startDate,
    description,
    duration,
    subscriptionVehicleId,
  }: ICreateMaintenanceDTO): Promise<IMaintenance> {
    const { data } = await http.post(
      `/${MaintenancesService.BASE_PATH}/vehicles/${vehicleId}`,
      {
        maintenanceTypeId,
        startDateMillis: startDate.getTime(),
        duration,
        subscriptionVehicleId: !subscriptionVehicleId ? undefined : subscriptionVehicleId,
        description: !description ? undefined : description,
      }
    );
    return data;
  }

  async getMaintenance(id: string): Promise<IMaintenance | undefined> {
    const { data } = await http.get(`/${MaintenancesService.BASE_PATH}/${id}`);
    return data;
  }

  async cancelMaintenance(id: string): Promise<IMaintenance> {
    const { data } = await http.post(
      `/${MaintenancesService.BASE_PATH}/${id}/cancel`
    );
    return data;
  }

  async startMaintenance({
    maintenanceId,
    vehicleMileage,
  }: IStartMaintenanceDTO): Promise<IMaintenance> {
    const { data } = await http.post(
      `/${MaintenancesService.BASE_PATH}/${maintenanceId}/start`,
      {
        vehicleMileage,
      }
    );
    return data;
  }

  async finishMaintenance({
    maintenanceId,
    vehicleMileage,
  }: IStartMaintenanceDTO): Promise<IMaintenance> {
    const { data } = await http.post(
      `/${MaintenancesService.BASE_PATH}/${maintenanceId}/finish`,
      {
        vehicleMileage,
      }
    );
    return data;
  }

  async listVehicleMaintenances(vehicleId: string): Promise<IMaintenance[]> {
    const { data } = await http.get(
      `/${MaintenancesService.BASE_PATH}/vehicles/${vehicleId}`
    );
    return data;
  }

  async updateMaintenanceTasks({
    maintenanceId,
    tasks,
  }: IUpdateMaintenanceTasksDTO): Promise<IMaintenanceTasks> {
    const { data } = await http.patch(
      `/${MaintenancesService.BASE_PATH}/tasks/${maintenanceId}`,
      {
        tasks,
      }
    );
    return data;
  }

  async listMaintenanceTasks({
    maintenanceId,
  }: IListMaintenanceTasksDTO): Promise<IMaintenanceTasks> {
    const { data } = await http.get(
      `/${MaintenancesService.BASE_PATH}/tasks/${maintenanceId}`
    );
    return data;
  }

  async editMaintenance({
    maintenanceId,
    vehicleId,
    maintenanceTypeId,
    startDate,
    description,
    duration,
    subscriptionVehicleId,
  }: IEditMaintenanceDTO): Promise<IMaintenance> {
    const { data } = await http.patch(
      `/${MaintenancesService.BASE_PATH}/${maintenanceId}`,
      {
        vehicleId,
        maintenanceTypeId,
        startDateMillis: startDate.getTime(),
        duration,
        description: !description ? undefined : description,
        subscriptionVehicleId: !subscriptionVehicleId ? undefined : subscriptionVehicleId,
      }
    );
    return data;
  }

  async createMaintenanceTask({
    maintenanceId,
    task,
  }: ICreateMaintenanceTaskDTO) {
    const { data } = await http.post(
      `/${MaintenancesService.BASE_PATH}/tasks/${maintenanceId}`,
      {
        task,
      }
    );
    return data;
  }

  async listMaintenanceExpenses(
    maintenanceId: string
  ): Promise<IVehicleExpense[]> {
    const { data } = await http.get(
      `/${MaintenancesService.BASE_PATH}/${maintenanceId}/expenses`
    );
    return data;
  }

  async listVehicleExpenses(
    vehicleId: string
  ): Promise<IVehicleExpense[]> {
    const { data } = await http.get(
      `/vehicles/expenses/${vehicleId}`
    );
    return data;
  }
}

const maintenancesService = new MaintenancesService();

export default maintenancesService;
