const Formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export function formatCurrency(value: number | string): string {
  const amount = Number(value);
  if (Number.isNaN(amount)) {
    return '';
  }

  return Formatter.format(amount).replace('$', '$ ');
}
