import { useCallback, useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Stack, Typography } from '@mui/material';
import { addMonths, isAfter, startOfDay } from 'date-fns';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

import { closeDialog, openDialog } from '@/components/Dialog';
import { useConnectedFormComponents } from '@/components/form';
import { currencyMask } from '@/constants/mask';
import AppError from '@/interfaces/AppError';
import { ChargeType } from '@/interfaces/payments/IInvoice';
import invoicesService from '@/services/invoices';

type Form = {
  name: string;
  totalAmount: number;
  type: string;
  numberOfInstallments: number;
  installmentsDates: { date: string }[];
};

type Props = {
  subscriptionId: number;
  onSubmitSuccess?: () => void | Promise<void>;
};

const defaultValues = {
  numberOfInstallments: 1,
  installmentsDates: [{ date: new Date().toISOString() }],
};

const createInvoiceSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  totalAmount: yup
    .number()
    .transform((value, original) => parseFloat(original.unmasked))
    .required('Amount is required'),
  type: yup.string().required('Type is required'),
  numberOfInstallments: yup
    .number()
    .required('Number of installments is required'),
  installmentsDates: yup
    .array()
    .of(
      yup.object().shape({
        date: yup
          .date()
          .min(
            startOfDay(new Date()),
            'Date must be greater than or equal today'
          )
          .typeError('This is an invalid date')
          .test(
            'date-less-than-previous',
            'Date cannot be less than the previous installment date',
            (value, context) => {
              const index = Number(
                context.path.split('[').pop()!.split(']').shift()
              );
              if (index === 0) {
                return true;
              }

              const currentFormValue: typeof defaultValues = (context as any)
                .from[1].value;
              const minDate =
                currentFormValue.installmentsDates[index - 1].date;
              const dateIsAfterLastInstallmentDate = isAfter(
                value!,
                new Date(minDate)
              );
              return dateIsAfterLastInstallmentDate;
            }
          )
          .required(),
      })
    )
    .length(yup.ref('numberOfInstallments'))
    .required(),
});

export function CreateInvoice({ onSubmitSuccess, subscriptionId }: Props) {
  const {
    control,
    formState: { isValid },
    watch,
    handleSubmit,
  } = useForm<Form>({
    resolver: yupResolver(createInvoiceSchema),
    mode: 'onChange',
    defaultValues,
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'installmentsDates',
  });
  const { TextInput, DatePicker, AutoComplete } =
    useConnectedFormComponents<Form>({
      control,
    });
  const numberOfInstallments = watch('numberOfInstallments');
  const { enqueueSnackbar } = useSnackbar();
  console.log('render');

  useEffect(() => {
    if (fields.length < numberOfInstallments) {
      const newFields: { date: string }[] = [];
      for (let i = fields.length; i < numberOfInstallments; i++) {
        newFields.push({
          date: addMonths(new Date(), i).toISOString(),
        });
      }
      append(newFields);
    } else if (fields.length > numberOfInstallments) {
      const fieldsIndexesToRemove: number[] = [];
      for (let i = fields.length; i > numberOfInstallments; i--) {
        fieldsIndexesToRemove.push(i - 1);
      }
      remove(fieldsIndexesToRemove);
    }
  }, [numberOfInstallments, fields, append, remove]);

  const onSubmit = useCallback(
    async (values: Form) => {
      console.log('values', values);
      try {
        await invoicesService.createInvoice(subscriptionId, {
          name: values.name,
          totalAmount: values.totalAmount,
          type: values.type as ChargeType,
          numberOfInstallments: values.numberOfInstallments,
          installmentsDates: values.installmentsDates.map(
            iDates => new Date(iDates.date)
          ),
        });
        closeDialog();

        if (onSubmitSuccess) {
          onSubmitSuccess();
        }
        enqueueSnackbar('Invoice created successfully', {
          variant: 'success',
        });
      } catch (error) {
        console.error(error);
        enqueueSnackbar(
          error instanceof AppError
            ? error?.message
            : 'Error on creating invoice. Please, try again later',
          { variant: 'error' }
        );
      }
    },
    [enqueueSnackbar, onSubmitSuccess, subscriptionId]
  );

  return (
    <>
      <Typography variant="body1">
        Fill out the form to add a new invoice
      </Typography>

      <TextInput autoFocus fieldName="name" label="Name" />

      <AutoComplete
        options={Object.values(ChargeType)}
        fieldName="type"
        label="Type"
      />

      <Stack flex={1} direction="row" alignItems="center" spacing={2}>
        <Box flex={1}>
          <TextInput
            fieldName="totalAmount"
            label="Total Amount"
            maskProps={currencyMask()}
          />
        </Box>

        <Box flex={1}>
          <TextInput
            fieldName="numberOfInstallments"
            label="Number of Installments"
            type="number"
            inputProps={{
              min: 1,
              step: 1,
            }}
          />
        </Box>
      </Stack>

      {fields.map((field, index) => (
        <DatePicker
          key={field.id}
          fieldName={`installmentsDates.${index}.date` as const}
          label={`Installment #${index + 1} Date`}
          minDate={new Date()}
          textFieldProps={{
            fullWidth: true,
          }}
        />
      ))}

      <Stack direction="row" gap={2} justifyContent="flex-end">
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => closeDialog()}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={!isValid}
          onClick={handleSubmit(onSubmit)}
        >
          Submit
        </Button>
      </Stack>
    </>
  );
}

export default function openCreateInvoiceDialog({
  onSubmitSuccess,
  subscriptionId,
}: Props) {
  openDialog({
    title: 'Add invoice',
    content: (
      <CreateInvoice
        onSubmitSuccess={onSubmitSuccess}
        subscriptionId={subscriptionId}
      />
    ),
  });
}
