import { useCallback, useEffect, useMemo } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Divider, Stack, Typography } from '@mui/material';
import { addMonths, isAfter, startOfDay } from 'date-fns';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

import { closeDialog } from '@/components/Dialog';
import { useConnectedFormComponents } from '@/components/form';
import { currencyMask } from '@/constants/mask';
import { formatCurrency } from '@/helpers/currency';
import { formatDecimalAmount } from '@/helpers/number';
import AppError from '@/interfaces/AppError';
import IInstallment from '@/interfaces/payments/IInstallment';
import installmentsService from '@/services/installments';

type Props = {
  installment: IInstallment;
  onSplitSuccess?: () => void | Promise<void>;
};

export default function SplitInstalment({
  installment,
  onSplitSuccess,
}: Props) {
  const defaultValues = useMemo(
    () => ({
      numberOfInstallments: 2,
      newTotalAmount: {
        masked: formatDecimalAmount(installment.totalAmount),
        unmasked: installment.totalAmount.toString(),
      },
      installmentsDates: [
        { date: installment.dueDate },
        { date: addMonths(new Date(installment.dueDate), 1).toISOString() },
      ],
    }),
    [installment]
  );
  const schema = yup.object().shape({
    numberOfInstallments: yup
      .number()
      .typeError('You must specify a number')
      .min(2, 'Number of installments must be greater than or equal 2')
      .required(),
    newTotalAmount: yup
      .mixed()
      .test(
        'test-amount-less-than-current',
        `Total amount can not be less than current total amount`,
        value => Number(value?.unmasked) >= installment.totalAmount
      ),
    installmentsDates: yup
      .array()
      .of(
        yup.object().shape({
          date: yup
            .date()
            .min(
              startOfDay(new Date()),
              'Date must be greater than or equal today'
            )
            .typeError('This is an invalid date')
            .test(
              'date-less-than-previous',
              'Date cannot be less than the previous installment date',
              (value, context) => {
                const index = Number(
                  context.path.split('[').pop()!.split(']').shift()
                );
                if (index === 0) {
                  return true;
                }

                const currentFormValue: typeof defaultValues = (context as any)
                  .from[1].value;
                const minDate =
                  currentFormValue.installmentsDates[index - 1].date;
                const dateIsAfterLastInstallmentDate = isAfter(
                  value!,
                  new Date(minDate)
                );
                return dateIsAfterLastInstallmentDate;
              }
            )
            .required(),
        })
      )
      .length(yup.ref('numberOfInstallments'))
      .required(),
  });
  const {
    control,
    formState: { isValid },
    watch,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues,
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'installmentsDates',
  });
  const { TextInput, DatePicker } = useConnectedFormComponents({
    control,
  });
  const numberOfInstallments = watch('numberOfInstallments');
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (fields.length < numberOfInstallments) {
      const newFields: { date: string }[] = [];
      for (let i = fields.length; i < numberOfInstallments; i++) {
        newFields.push({
          date: addMonths(new Date(installment.dueDate), i).toISOString(),
        });
      }
      append(newFields);
    } else if (fields.length > numberOfInstallments) {
      const fieldsIndexesToRemove: number[] = [];
      for (let i = fields.length; i > numberOfInstallments; i--) {
        fieldsIndexesToRemove.push(i - 1);
      }
      remove(fieldsIndexesToRemove);
    }
  }, [numberOfInstallments, fields, append, installment, remove]);

  const onSubmit = useCallback(
    async (values: typeof defaultValues) => {
      try {
        await installmentsService.splitInstallment(installment.id, {
          installmentsDates: values.installmentsDates.map(
            iDates => new Date(iDates.date)
          ),
          newTotalAmount: Number(
            Number(values.newTotalAmount.unmasked).toFixed(2)
          ),
          numberOfInstallments: values.numberOfInstallments,
        });
        closeDialog();

        if (onSplitSuccess) {
          onSplitSuccess();
        }
      } catch (error) {
        console.error(error);
        enqueueSnackbar(
          error instanceof AppError
            ? error?.message
            : 'Error on splitting installment. Please, try again later',
          { variant: 'error' }
        );
      }
    },
    [enqueueSnackbar, installment, onSplitSuccess]
  );

  return (
    <>
      <div>
        <div>Current details:</div>
        <Typography variant="body2">
          Amount: {formatCurrency(installment.amount)}
        </Typography>
        <Typography variant="body2">
          Tax Rate: {formatDecimalAmount(installment.taxRate * 100)}%
        </Typography>
        <Typography variant="body2">
          Total Amount: {formatCurrency(installment.totalAmount)}
        </Typography>
      </div>

      <Divider sx={{ my: 2 }} />

      <TextInput
        fieldName="newTotalAmount"
        label="New Total Amount"
        maskProps={currencyMask()}
      />

      <TextInput
        fieldName="numberOfInstallments"
        label="Number of Installments"
        type="number"
        inputProps={{
          min: 2,
          step: 1,
        }}
      />

      {fields.map((field, index) => (
        <DatePicker
          key={field.id}
          fieldName={`installmentsDates.${index}.date` as const}
          label={`Installment #${index + 1} Date`}
          minDate={new Date()}
          textFieldProps={{
            fullWidth: true,
          }}
        />
      ))}

      <Stack direction="row" gap={2} justifyContent="flex-end">
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => closeDialog()}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={!isValid}
          onClick={handleSubmit(onSubmit)}
        >
          Submit
        </Button>
      </Stack>
    </>
  );
}
