import { ICustomer } from '@/interfaces/customers/ICustomer';
import ISubscriptionType from '@/interfaces/subscriptions/ISubscriptionType';
import { ISystemUser } from '@/interfaces/system-users/ISystemUser';

import type IInstallment from '../payments/IInstallment';
import type ISubscriptionVehicle from './ISubscriptionVehicle';

export enum EndSubscriptionReason {
  FINISH_CONTRACT = 'FINISH CONTRACT',
  REPOED = 'REPOED',
  VOLUNTARY = 'VOLUNTARY',
  ACCIDENT = 'ACCIDENT',
  RENEW = 'RENEW',
  CANCEL_CONTRACT = 'CANCEL CONTRACT',
  OTHER = 'OTHER',
}

export default interface ISubscription {
  id: number;
  currentVehicle: ISubscriptionVehicle;
  vehicles: ISubscriptionVehicle[];
  startDate: Date;
  expectedEndDate: Date;
  endDate?: Date;
  type: ISubscriptionType;
  endReason?: EndSubscriptionReason;
  endReasonDescription?: string;
  createdAt: Date;
  updatedAt: Date;
  customer: ICustomer;
  sales: ISystemUser;
  nextInstallment?: IInstallment;
  warnings: number;
  renewedFromSubscription?: ISubscription;
}
