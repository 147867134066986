import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid } from '@mui/material';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

import { useConnectedFormComponents } from '@/components/form';
import { vehicleDisplayName } from '@/helpers/vehicles';
import AppError from '@/interfaces/AppError';
import ICreateVehicleGPSDTO from '@/interfaces/vehicles/ICreateVehicleGPSDTO';
import IVehicle from '@/interfaces/vehicles/IVehicle';
import vehiclesService from '@/services/vehicles';

type Form = ICreateVehicleGPSDTO;

const schema = Yup.object().shape({
  vehicle: Yup.object().nullable(),
  number: Yup.number().required('Number is required'),
});

export default function VehicleGPSForm() {
  const { handleSubmit, control } = useForm<Form>({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const { TextInput, AutoComplete } = useConnectedFormComponents<Form>({
    control,
  });
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [vehiclesList, setVehiclesList] = useState<IVehicle[]>([]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const vehicles = await vehiclesService.listVehicles();

        setVehiclesList(vehicles);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const onSubmit = async (data: Form) => {
    try {
      setSubmitLoading(true);
      await vehiclesService.createVehicleGPS(data);
      navigate('/vehicles/gps');
    } catch (error) {
      console.error(error);
      enqueueSnackbar(
        error instanceof AppError
          ? error?.message
          : 'Error on creating vehicle GPS. Please, try again later',
        { variant: 'error' }
      );
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      sx={{ mt: 1 }}
    >
      <Grid
        container
        direction={{ xs: 'column', md: 'row' }}
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={3}>
          <TextInput
            margin="normal"
            required
            label="Number"
            fullWidth
            fieldName="number"
          />
        </Grid>
        <Grid item xs={3}>
          <AutoComplete
            options={vehiclesList}
            label="Vehicle"
            textFieldProps={{
              required: false,
            }}
            freeSolo={false}
            getOptionLabel={option => vehicleDisplayName(option)}
            isOptionEqualToValue={(option, value) => option.vin === value.vin}
            fieldName="vehicle"
          />
        </Grid>
      </Grid>

      <Grid>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading || submitLoading}
          onClick={handleSubmit(onSubmit)}
        >
          {loading || submitLoading ? 'Loading...' : 'Submit'}
        </Button>
      </Grid>
    </Box>
  );
}
