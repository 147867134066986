import { useNavigate } from 'react-router-dom';

import { Grid } from '@mui/material';

import PageCard from '@/components/PageCard';
import { REPORTS_PAGES } from '@/constants/reports-pages';

export default function Reports() {
  const navigate = useNavigate();

  return (
    <Grid
      container
      spacing={2}
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      {REPORTS_PAGES.menuItems.map(
        ({ icon: Icon, path, title, showOnNavMenus }) => {
          if (showOnNavMenus !== undefined && !showOnNavMenus) {
            return null;
          }
          return (
            <Grid key={path} item onClick={() => navigate(path)}>
              <PageCard
                path={path}
                title={typeof title === 'string' ? title : title(path)}
                CardIcon={Icon}
              />
            </Grid>
          );
        }
      )}
    </Grid>
  );
}
