import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { useConnectedFormComponents } from '@/components/form';
import { vehicleDisplayName } from '@/helpers/vehicles';
import ISubscription from '@/interfaces/subscriptions/ISubscription';
import IVehicle from '@/interfaces/vehicles/IVehicle';
import subscriptionsService from '@/services/subscriptions';
import vehiclesService from '@/services/vehicles';

export type MaintenanceVehicleFormFields = {
  vehicle: IVehicle;
  subscription?: ISubscription;
};

export default function MaintenanceVehicleFields() {
  const [vehiclesList, setVehiclesList] = useState<IVehicle[]>([]);
  const [subscriptionList, setSubscriptionList] = useState<ISubscription[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const { control, setValue, resetField } =
    useFormContext<MaintenanceVehicleFormFields>();

  const { AutoComplete } =
    useConnectedFormComponents<MaintenanceVehicleFormFields>({
      control,
    });

  useEffect(() => {
    (async () => {
      try {
        const vehiclesResponse = await vehiclesService.listVehicles();
        setVehiclesList(vehiclesResponse);
        const subscriptionsResponse =
          await subscriptionsService.listSubscriptions();
        setSubscriptionList(
          subscriptionsResponse.filter(
            subscription => !!subscription.currentVehicle
          )
        );
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <>
      <AutoComplete
        textFieldProps={{
          required: false,
        }}
        disabled={loading}
        options={subscriptionList}
        freeSolo={false}
        onChange={(_, value) => {
          const subscription = value as ISubscription;
          const vehicleFound = vehiclesList.find(vehicle =>
            subscription.currentVehicle
              ? vehicle.vin === subscription.currentVehicle.vehicle.vin
              : subscription.vehicles[0].vehicle.vin === vehicle.vin
          );
          if (vehicleFound) {
            setValue('subscription', subscription);
            setValue('vehicle', vehicleFound);
          }
        }}
        getOptionLabel={subscription => {
          const vehicleFound = vehiclesList.find(vehicle =>
            subscription.currentVehicle
              ? vehicle.vin === subscription.currentVehicle.vehicle.vin
              : subscription.vehicles[0].vehicle.vin === vehicle.vin
          );
          if (vehicleFound) {
            return `${subscription.id} - ${subscription.customer.name} - ${vehicleFound.vin}`;
          }
          return '';
        }}
        isOptionEqualToValue={(subscription, value) =>
          subscription.id === value.id
        }
        fieldName="subscription"
        label="Subscription"
      />
      <AutoComplete
        options={vehiclesList}
        freeSolo={false}
        disabled={loading}
        onChange={(_, value) => {
          setValue('vehicle', value as IVehicle);
          resetField('subscription');
        }}
        getOptionLabel={vehicle => vehicleDisplayName(vehicle)}
        isOptionEqualToValue={(option, value) => option.vin === value.vin}
        fieldName="vehicle"
        label="Vehicle"
      />
    </>
  );
}
