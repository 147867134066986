import { Paid, Percent, Report } from '@mui/icons-material';

import { IPageDetails } from '@/interfaces/IPageDetails';
import { UserRoles } from '@/interfaces/system-users/UserRoles';

export const REPORTS_PAGES: IPageDetails = {
  title: 'Reports',
  path: '/reports',
  icon: Report,
  roles: [UserRoles.ADMIN],
  menuItems: [
    {
      title: 'Payments',
      path: '/reports/payments',
      icon: Paid,
      showOnNavMenus: true,
    },
    {
      title: 'Taxes',
      path: '/reports/Taxes',
      icon: Percent,
      showOnNavMenus: true,
    },
  ],
};
