import { useEffect, useState } from 'react';
import { Control, useFormContext } from 'react-hook-form';

import { createFilterOptions } from '@mui/material';
import { useSnackbar } from 'notistack';

import { useConnectedFormComponents } from '@/components/form';
import AppError from '@/interfaces/AppError';
import { IVehicleExpenseCategory } from '@/interfaces/vehicles/IVehicleExpenseCategory';
import vehiclesService from '@/services/vehicles';

import CreateExpenseCategoryModal from './components/CreateExpenseCategoryModal';

interface Props {
  control: Control<any>;
}

type ExpenseCategory = {
  id: string;
  name: string;
  inputValue?: string;
};

const filter = createFilterOptions<ExpenseCategory>();

export default function ExpenseCategorySelect({ control }: Props) {
  const [creatingCategoryName, setCreatingCategoryName] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [categories, setCategories] = useState<IVehicleExpenseCategory[]>([]);

  const { enqueueSnackbar } = useSnackbar();

  const { AutoComplete } = useConnectedFormComponents({
    control,
  });

  const { setValue, watch } = useFormContext();

  const value: ExpenseCategory | null = watch('expenseCategory');

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        setLoading(true);

        const categoriesResponse =
          await vehiclesService.listVehicleExpenseCategories();
        setCategories(categoriesResponse);
      } catch (error) {
        console.error(error);
        enqueueSnackbar(
          error instanceof AppError
            ? error?.message
            : 'Error on listing categories. Please, try again later',
          { variant: 'error' }
        );
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  return (
    <>
      <CreateExpenseCategoryModal
        initialData={{ name: creatingCategoryName }}
        open={!!creatingCategoryName}
        onSucess={expenseCategory => {
          setValue('expenseCategory', expenseCategory);
          setCategories(prev => [...prev, expenseCategory]);
        }}
        onClose={() => setCreatingCategoryName(undefined)}
      />

      <AutoComplete
        value={value || ({} as ExpenseCategory)}
        onChange={(_, newValue) => {
          const newValueExpense = newValue as ExpenseCategory | null;
          if (newValueExpense) {
            if (newValueExpense.id === 'dummy' && newValueExpense.inputValue) {
              setCreatingCategoryName(newValueExpense.inputValue);
            } else {
              setValue('expenseCategory', newValueExpense);
            }
          }
        }}
        filterOptions={(options, params) => {
          const categoryOptions = options as ExpenseCategory[];

          const filtered = filter(categoryOptions, params);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = categoryOptions.filter(option => inputValue === option.name).length > 0;
          if (inputValue !== '' && !isExisting) {
            filtered.push({
              id: 'dummy',
              name: `Create "${inputValue}"`,
              inputValue,
            });
          }

          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        loading={loading}
        fieldName="expenseCategory"
        label="Expense Category"
        options={categories.map(category => ({
          id: category.id,
          name: category.name,
        }))}
        isOptionEqualToValue={(option, valueToCompare) =>
          option.id === valueToCompare.id
        }
        getOptionLabel={option => {
          if (typeof option === 'object' && Object.keys(option).length === 0) {
            return '';
          }

          if (typeof option === 'string') {
            return option;
          }
          return option.name;
        }}
        freeSolo
      />
    </>
  );
}
