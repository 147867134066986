import { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, CircularProgress, Grid } from '@mui/material';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

import { useConnectedFormComponents } from '@/components/form';
import { currencyMask } from '@/constants/mask';
import { formatCurrency } from '@/helpers/currency';
import {
  emptyStringToNull,
  vehicleAndSubTypeSelectedErrorString,
} from '@/helpers/validations';
import { vehicleDisplayName } from '@/helpers/vehicles';
import { useAuth, useAuthorization } from '@/hooks/auth';
import AppError from '@/interfaces/AppError';
import { ICustomer } from '@/interfaces/customers/ICustomer';
import ISubscription from '@/interfaces/subscriptions/ISubscription';
import ISubscriptionType from '@/interfaces/subscriptions/ISubscriptionType';
import { ISystemUser } from '@/interfaces/system-users/ISystemUser';
import { UserRoles } from '@/interfaces/system-users/UserRoles';
import IVehicle from '@/interfaces/vehicles/IVehicle';
import customersService from '@/services/customers';
import subscriptionsService from '@/services/subscriptions';
import systemUsersService from '@/services/system-users';
import vehiclesService from '@/services/vehicles';

type Form = {
  vehicle: IVehicle;
  dealerFee: {
    masked: string;
    unmasked: number;
  };
  startDate: Date;
  subscriptionType: ISubscriptionType;
  activationValue?: {
    masked: string;
    unmasked: string;
  };
  monthlyValue?: {
    masked: string;
    unmasked: string;
  };
  customer: ICustomer;
  sales?: ISystemUser;
  durationInMonths?: number;
  vehicleMileage?: number;
};

interface Props {
  subscription?: ISubscription;
  onSuccess?: () => void;
  onAfterSubmit?: () => void;
}

export default function SubscriptionForm(props: PropsWithChildren<Props>) {
  const { subscription, onSuccess, onAfterSubmit, children } = props;

  const isRenew = useMemo(() => !!subscription, [subscription]);

  const { checkUserHasRole } = useAuthorization();
  const { user } = useAuth();
  const isAdmin = useMemo(
    () => checkUserHasRole([UserRoles.ADMIN]),
    [checkUserHasRole]
  );
  const salesRequired = useMemo(
    () => isAdmin && !checkUserHasRole([UserRoles.SALES]),
    [checkUserHasRole, isAdmin]
  );
  const schema = useMemo(() => {
    const baseSchemaShape = {
      vehicle: Yup.object().required('Vehicle is required'),
      dealerFee: Yup.object()
        .shape({
          masked: Yup.string().required(),
          unmasked: Yup.number()
            .transform(emptyStringToNull)
            .min(0, 'Dealer Fee value must be greater than or equal to 0')
            .nullable(),
        })
        .nullable(),
      startDate: Yup.date().required('Start date is required'),
      subscriptionType: Yup.object().required('Subscription type is required'),
      activationValue: Yup.object()
        .shape({
          masked: Yup.string().required(),
          unmasked: Yup.number()
            .transform(emptyStringToNull)
            .min(0, 'Activation value must be greater than 0')
            .nullable(),
        })
        .nullable(),
      monthlyValue: Yup.object()
        .shape({
          masked: Yup.string().required(),
          unmasked: Yup.number()
            .transform(emptyStringToNull)
            .min(0, 'Monthly value must be greater than 0')
            .nullable(),
        })
        .nullable(),
      ...(!isRenew
        ? {
            customer: Yup.object().required('Customer is required'),
          }
        : {}),
      ...(isRenew
        ? {
            vehicleMileage: Yup.number()
              .required('Vehicle Mileage is required')
              .typeError('Vehicle Mileage is required'),
          }
        : {}),
    };

    if (salesRequired) {
      Object.assign(baseSchemaShape, {
        sales: Yup.object().required('Sales is required'),
      });
    }

    return Yup.object().shape(baseSchemaShape);
  }, [salesRequired, isRenew]);
  const { handleSubmit, watch, setValue, control } = useForm<Form>({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const { TextInput, AutoComplete, DatePicker } =
    useConnectedFormComponents<Form>({
      control,
    });
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [vehiclesList, setVehiclesList] = useState<IVehicle[]>([]);
  const [subscriptionTypeList, setSubscriptionTypeList] = useState<
    ISubscriptionType[]
  >([]);
  const [customersList, setCustomersList] = useState<ICustomer[]>([]);
  const [systemUsersList, setSystemUsersList] = useState<ISystemUser[]>([]);

  const vehicleSelected = watch('vehicle');
  const subscriptionTypeSelected = watch('subscriptionType');
  const activationValue = watch('activationValue');

  const activationValueParsed = activationValue
    ? parseInt(activationValue.unmasked, 10)
    : 0;


  const renewDiscount = useMemo(() => {
    if (isRenew && subscriptionTypeSelected && subscription) {
      return Math.min(subscriptionTypeSelected.renewDiscountPercentage,
         subscription.type.renewDiscountPercentage)
    }
    return 0;
  }, [isRenew, subscription, subscriptionTypeSelected])


  const activationValueToShow = useMemo(() => {
    if(!subscriptionTypeSelected || !vehicleSelected) {
      return undefined;
    }
    const defaultValue = (subscriptionTypeSelected?.activationBaseValue ?? 0) +
    vehicleSelected.referencePrice *
      (subscriptionTypeSelected?.activationMultiplierValue ||
        1)

    const typedValue = activationValueParsed;

    const valueToApplyDiscount = typedValue || defaultValue;

    return {
      defaultValue,
      typedValue,
      valueToApplyDiscount,
      valueWithDiscount: valueToApplyDiscount - (valueToApplyDiscount * renewDiscount)
    }
  }, [activationValueParsed, renewDiscount, subscriptionTypeSelected, vehicleSelected])


  useEffect(() => {
    if (subscription) {
      setValue('vehicle', subscription.currentVehicle.vehicle);
      setValue('subscriptionType', subscription.type);
    }
  }, [setValue, subscription]);

  const onSubmit = async (data: Form) => {
    try {
      setSubmitLoading(true);
      const adminValues: Record<string, string | number | undefined> = {};
      if (isAdmin) {
        adminValues.salesId = data.sales ? data.sales.id : undefined;
        adminValues.activationValue = data.activationValue?.unmasked
          ? Number(data.activationValue?.unmasked)
          : undefined;
        adminValues.monthlyValue = data.monthlyValue?.unmasked
          ? Number(data.monthlyValue?.unmasked)
          : undefined;
      }

      const baseValues = {
        subscriptionTypeId: data.subscriptionType.id,
        startDate: data.startDate,
        dealerFee: data.dealerFee?.unmasked ?? 200,
        ...adminValues,
      };

      if (subscription) {
        const renewedSubscription =
          await subscriptionsService.renewSubscription({
            ...baseValues,
            ...(data.vehicle.vin ? { vehicleVin: data.vehicle.vin } : {}),
            vehicleMileage: data.vehicleMileage ?? 0,
            subscriptionId: subscription.id,
          });
        navigate(`/subscriptions/list/${renewedSubscription.id}`);
      } else {
        const sub = await subscriptionsService.createSubscription({
          ...baseValues,
          vehicleVin: data.vehicle.vin,
          customerId: data.customer.id,
        });
        navigate(`/subscriptions/list/${sub.id}`);
      }
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(
        error instanceof AppError
          ? error?.message
          : 'Error on creating subscription. Please, try again later',
        { variant: 'error' }
      );
    } finally {
      if (onAfterSubmit) {
        onAfterSubmit();
      }
      setSubmitLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const [vehicles, subscriptionTypes, customers] = await Promise.all([
          vehiclesService.listVehicles({
            available: true,
          }),
          subscriptionsService.listSubscriptionTypes(),
          customersService.listCustomers(),
        ]);

        setVehiclesList(vehicles);
        setSubscriptionTypeList(subscriptionTypes);
        setCustomersList(customers);

        if (isAdmin && user) {
          const systemUsers = await systemUsersService.listSystemUsers();
          setSystemUsersList(
            systemUsers.filter(u => u.roles.includes(UserRoles.SALES))
          );
        } else if (!isAdmin && user) {
          setSystemUsersList([user as unknown as ISystemUser]);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [isAdmin, user]);

  if (loading) {
    return (
      <Box>
        <Grid container direction="row" alignItems="center" spacing={4}>
          <Grid item>
            <CircularProgress size={32} />
          </Grid>
        </Grid>
      </Box>
    );
  }

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      sx={{ mt: 1 }}
    >
      <Grid
        container
        direction={!isRenew ? { xs: 'column', md: 'row' } : 'column'}
        spacing={2}
      >
        {isRenew && (
          <Grid item xs={1}>
            <TextInput
              label="Current Vehicle Mileage"
              fullWidth
              fieldName="vehicleMileage"
              required
            />
          </Grid>
        )}

        {!isRenew && (
          <Grid item xs={!isRenew ? 3 : 1}>
            <AutoComplete
              options={customersList}
              getOptionLabel={customer =>
                `${customer.name} (${customer.email})`
              }
              isOptionEqualToValue={(option, value) => option.id === value.id}
              fieldName="customer"
              freeSolo={false}
              label="Customer"
            />
          </Grid>
        )}
        <Grid item xs={!isRenew ? 3 : 1}>
          <AutoComplete
            options={vehiclesList}
            freeSolo={false}
            getOptionLabel={vehicle => vehicleDisplayName(vehicle)}
            isOptionEqualToValue={(option, value) => option.vin === value.vin}
            fieldName="vehicle"
            label="Vehicle"
          />
        </Grid>
        <Grid item xs={!isRenew ? 3 : 1}>
          <AutoComplete
            options={subscriptionTypeList}
            freeSolo={false}
            getOptionLabel={type => type.displayName}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            fieldName="subscriptionType"
            label="Subscription Type"
          />
        </Grid>
        <Grid item xs={!isRenew ? 3 : 1}>
          <TextInput
            margin="normal"
            label="Activation Value"
            fullWidth
            fieldName="activationValue"
            disabled={!isAdmin}
            InputLabelProps={{
              shrink:
                !!subscriptionTypeSelected?.activationBaseValue || undefined,
            }}
            maskProps={currencyMask()}
            helperText={
              <>
                <p style={{ margin: 0 }}>
                  {activationValueToShow
                    ? `Default would be: ${formatCurrency(activationValueToShow.defaultValue)}`
                    : vehicleAndSubTypeSelectedErrorString(
                        vehicleSelected,
                        subscriptionTypeSelected
                      )}
                </p>
                {isRenew &&
                  activationValueToShow &&
                  renewDiscount > 0 && (
                    <p style={{ margin: 0 }}>
                      Activation with renew discount:{' '}
                      {formatCurrency(activationValueToShow.valueWithDiscount)}
                    </p>
                  )}
              </>
            }
          />
        </Grid>
        <Grid item xs={!isRenew ? 3 : 1}>
          <TextInput
            margin="normal"
            label="Monthly Value"
            fullWidth
            fieldName="monthlyValue"
            disabled={!isAdmin}
            InputLabelProps={{
              shrink: !!subscriptionTypeSelected?.monthlyBaseValue || undefined,
            }}
            maskProps={currencyMask()}
            helperText={
              subscriptionTypeSelected && vehicleSelected
                ? `Default would be: ${formatCurrency(
                    (subscriptionTypeSelected?.monthlyBaseValue ?? 0) +
                      vehicleSelected.referencePrice *
                        (subscriptionTypeSelected?.monthlyMultiplierValue || 1)
                  )}`
                : vehicleAndSubTypeSelectedErrorString(
                    vehicleSelected,
                    subscriptionTypeSelected
                  )
            }
          />
        </Grid>
        <Grid item xs={!isRenew ? 3 : 1}>
          <DatePicker
            fieldName="startDate"
            label="Start Date"
            textFieldProps={{
              fullWidth: true,
            }}
            defaultValue={new Date()}
          />
        </Grid>
        <Grid item xs={!isRenew ? 3 : 1}>
          <AutoComplete<ISystemUser, undefined, undefined, undefined>
            options={systemUsersList}
            getOptionLabel={u => `${u.name} (${u.email})`}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            fieldName="sales"
            disabled={!isAdmin}
            value={isAdmin ? undefined : user}
            label="Sales User"
            textFieldProps={{ required: salesRequired }}
          />
        </Grid>

        <Grid item xs={!isRenew ? 3 : 1}>
          <TextInput
            margin="normal"
            label="Dealer Fee"
            fullWidth
            fieldName="dealerFee"
            helperText="Default is: $200"
            maskProps={currencyMask()}
          />
        </Grid>
      </Grid>

      <Grid sx={{ display: 'flex', gap: '16px', marginTop: '16px' }}>
        {children}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading || submitLoading}
          onClick={handleSubmit(onSubmit)}
        >
          {loading || submitLoading ? 'Loading...' : 'Submit'}
        </Button>
      </Grid>
    </Box>
  );
}
