/* eslint-disable react/no-unstable-nested-components */
import { useEffect, useMemo, useState } from 'react';

import { Chip, CircularProgress, Stack, Tooltip, Typography } from '@mui/material';
import { endOfDay, startOfDay, startOfMonth } from 'date-fns';
import { useSnackbar } from 'notistack';

import DatePicker from '@/components/DatePicker';
import DownloadbleDataTable from '@/components/DownloadbleDataTable';
import { formatCurrency } from '@/helpers/currency';
import { formatDateWithTime } from '@/helpers/date';
import IDataTableColumn from '@/interfaces/IDataTableColumn';
import ITaxes from '@/interfaces/payments/ITaxes';
import paymentsService from '@/services/payments';

export default function TaxesReport() {
  const { enqueueSnackbar } = useSnackbar();
  const [taxesList, setTaxesList] = useState<ITaxes[]>([]);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState<Date | null>(
    startOfMonth(new Date())
  );
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [tableTotalAmount, setTableTotalAmount] = useState<number>(0);
  const [tableTotalTax, setTableTotalTax] = useState<number>(0);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        if (startDate) {
          const payments = await paymentsService.listTaxesReport(
            startDate,
            endDate
          );
          setTaxesList(payments);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [startDate, endDate]);

  const columns: IDataTableColumn[] = useMemo<IDataTableColumn[]>(
    () => [
      {
        name: 'vin',
        label: 'VIN',
        options: {
          filter: true,
          filterType: 'textField',
        },
      },
      {
        name: 'subscriptionId',
        label: 'Subscription Id',
        options: {
          filter: true,
          filterType: 'textField',
        },
      },
      {
        name: 'name',
        label: 'Invoice Name',
        options: {
          filter: true,
          filterType: 'textField',
        },
      },
      {
        name: 'type',
        label: 'Invoice Type',
        options: {
          filter: true,
        },
      },
      {
        name: 'amount',
        label: 'Amount',
        options: {
          filter: true,
          filterType: 'textField',
          customBodyRender(value) {
            const formattedAmount = formatCurrency(Number(value));
            return formattedAmount;
          },
          customCSVBodyRender(value) {
            const formattedAmount = Number(value
            .replaceAll('$', '')
            .replaceAll(',', '')
            .replaceAll(' ', '')
            .trim())
            return formattedAmount
          },
        },
      },
      {
        name: 'tax',
        label: 'Tax',
        options: {
          filter: true,
          filterType: 'textField',
          customBodyRender(value) {
            const formattedAmount = formatCurrency(Number(value));
            return formattedAmount;
          },
          customCSVBodyRender(value) {
            return Number(value
              .replaceAll('$', '')
              .replaceAll(',', '')
              .replaceAll(' ', '')
              .trim());
          },
        },
      },
      {
        name: 'paidAt',
        label: 'Paid At',
        options: {
          filter: false,
          customBodyRender(value) {
            return formatDateWithTime(value);
          },
        },
      },
      {
        name: 'paymentId',
        label: 'Payment Id',
        options: {
          filter: true,
          filterType: 'textField',
        },
      },
      {
        name: 'refundPaymentId',
        label: 'Transaction Type',
        csvLabel: 'Refund Payment Id',
        options: {
          filter: false,
          customBodyRender(value) {
            return value ? (
              <Tooltip title={value}>
                <Chip label="Refund" color="error" size="small" onClick={() => {
                  navigator.clipboard.writeText(value);
                  enqueueSnackbar('Copied to clipboard');
                }}/>
              </Tooltip>
            ) : (
              <Chip label="Payment" color="success" size="small" />
            );
          },
          customCSVBodyRender(value) {
            return value ? `Refund` : 'Payment';
          },
        },
      },
    ],
    [enqueueSnackbar]
  );

  return (
    <DownloadbleDataTable
      title="Taxes"
      data={taxesList}
      columns={columns}
      options={{
        downloadOptions: {
          filename: 'taxes.csv',
          filterOptions: {
            useDisplayedColumnsOnly: true,
            useDisplayedRowsOnly: true,
          },
        },
        onTableChange: (action, tableState) => {
          if (action === 'filterChange' || action === 'propsUpdate') {
            setTableTotalAmount(
              tableState.displayData.reduce((acc, row) => {
                const amount = row.data[4]
                  .replaceAll('$', '')
                  .replaceAll(',', '')
                  .replaceAll(' ', '')
                  .trim();
                return acc + Number(amount);
              }, 0)
            );

            setTableTotalTax(
              tableState.displayData.reduce((acc, row) => {
                const amount = row.data[5]
                  .replaceAll('$', '')
                  .replaceAll(',', '')
                  .replaceAll(' ', '')
                  .trim();
                return acc + Number(amount);
              }, 0)
            );

          }
        },
        rowsPerPage: 100,
        customToolbar: () => (
          <>
            <DatePicker
              value={startDate}
              onChange={(date: Date | null) => setStartDate(date ? startOfDay(date) : date)}
              label="Start Date"
            />
            <DatePicker
              value={endDate}
              onChange={(date: Date | null) => setEndDate(date ? endOfDay(date) : date)}
              label="End Date"
            />
            <Stack mt={1}>
              <Typography variant="body2">
                Total Amount: {formatCurrency(tableTotalAmount)}
              </Typography>
              <Typography variant="body2">
                Total Tax: {formatCurrency(tableTotalTax)}
              </Typography>
            </Stack>
          </>
        ),
        rowsPerPageOptions: [100, 200, 500, 1000, 10000],
        sortOrder: {
          name: 'created_at',
          direction: 'desc',
        },
        print: false,
        enableNestedDataAccess: '.',
        rowHover: true,
        selectableRows: 'none',
        textLabels: {
          body: {
            noMatch: loading ? (
              <CircularProgress />
            ) : (
              'Sorry, no matching records found'
            ),
          },
        },
      }}
    />
  );
}
