import * as React from 'react';
import { useCallback } from 'react';
import { Controller, Path, UseControllerProps } from 'react-hook-form';

import { TextFieldProps } from '@mui/material';
import TextField from '@mui/material/TextField';
import {
  DatePicker as MuiDatePicker,
  DatePickerProps,
} from '@mui/x-date-pickers';

type Props<TFieldValues> = Partial<DatePickerProps<Date>> &
  Omit<UseControllerProps<TFieldValues>, 'name'> & {
    label?: string;
    fieldName: Path<TFieldValues>;
    textFieldProps?: Omit<TextFieldProps, 'error' | 'helperText' | 'label'>;
  };

export type DatePickerPropsWithoutConnectionProps<TFieldValues> = Omit<
  Props<TFieldValues>,
  'control'
>;

export default function DatePicker<TFieldValues>({
  fieldName,
  control,
  label,
  defaultValue,
  textFieldProps,
  ...props
}: Props<TFieldValues>) {
  const renderField = useCallback(
    ({ field, fieldState: { error } }) => (
      <MuiDatePicker<Date>
        label={label}
        value={field.value}
        onChange={newValue => {
          field.onChange(newValue);
        }}
        allowSameDateSelection
        renderInput={params => (
          <TextField
            margin="normal"
            required
            {...(textFieldProps || {})}
            {...params}
            label={label}
            helperText={error?.message}
            error={!!error}
          />
        )}
        {...props}
      />
    ),
    [label, props, textFieldProps]
  );

  return (
    <Controller
      render={renderField}
      control={control}
      name={fieldName}
      defaultValue={defaultValue}
    />
  );
}
