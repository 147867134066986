import { useCallback, useMemo, useState } from 'react';

import { Add, Flag } from '@mui/icons-material';
import { Box, IconButton, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';

import subscriptionsService from '@/services/subscriptions';

const MAX_WARNINGS = 10;

interface Props {
  subscriptionId: number;
  defaultValue?: number;
}

const calculateWarnings = (warnings: number, index: number) => {
  // Decrease
  if (warnings >= index + 1) {
    // Same index checking
    if (warnings === index + 1) {
      return warnings - 1;
    }
    return warnings - (warnings - (index + 1));
  }

  // Increase
  return warnings < MAX_WARNINGS ? warnings + 1 : warnings;
};

export default function Warnings({ subscriptionId, defaultValue = 0 }: Props) {
  const [warnings, setWarnings] = useState<number>(defaultValue);

  const { enqueueSnackbar } = useSnackbar();

  const warningsList = useMemo(
    () =>
      Array.from(
        Array(warnings === MAX_WARNINGS ? warnings : warnings + 1).keys()
      ),
    [warnings]
  );

  const handleClick = useCallback(
    async (index: number) => {
      const warningsAfterClick = calculateWarnings(warnings, index);

      setWarnings(warningsAfterClick);

      await subscriptionsService.changeSubscriptionWarnings({
        subscriptionId,
        warnings: warningsAfterClick,
      });

      enqueueSnackbar('Warnings updated successfully', {
        variant: 'success',
      });
    },
    [subscriptionId, warnings, enqueueSnackbar]
  );

  return (
    <Stack direction="row" mt={2}>
      {warningsList.map((key, index) => (
        <Box key={`warning-${key}`}>
          <IconButton onClick={() => handleClick(index)}>
            {warnings === MAX_WARNINGS || warnings >= index + 1 ? (
              <Flag />
            ) : (
              <Add />
            )}
          </IconButton>
        </Box>
      ))}
    </Stack>
  );
}
