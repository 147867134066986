import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  closestCenter,
  DndContext,
  DragEndEvent,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
} from '@dnd-kit/sortable';
import { Dangerous, RestoreFromTrash, Send, Undo } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  ImageList,
  ImageListItem,
  LinearProgress,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';

import UploadDropzoneDialog from '@/components/UploadDropzoneDialog';
import { useAuthorization } from '@/hooks/auth';
import { UserRoles } from '@/interfaces/system-users/UserRoles';
import { IVehicleImage } from '@/interfaces/vehicles/IVehicleImage';
import SortableImage from '@/pages/Vehicles/VehicleImages/SortableImages';
import vehiclesService from '@/services/vehicles';

export default function VehicleImages() {
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cancelClicked, setCancelClicked] = useState(false);
  const { vin } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [images, setImages] = useState<IVehicleImage[]>([]);
  const [deletedImages, setDeletedImages] = useState<IVehicleImage[]>([]);
  const sensors = useSensors(useSensor(PointerSensor), useSensor(TouchSensor));
  const originalImages = useRef<IVehicleImage[]>([]);
  const { checkUserHasRole } = useAuthorization();

  const showOnlyForEdit = useMemo(
    () => checkUserHasRole([UserRoles.ADMIN]),
    [checkUserHasRole]
  );

  const isDifferentOrder = useMemo(() => {
    const currentOrder = images.map(img => img.id);
    const originalOrder = originalImages.current.map(img => img.id);
    return JSON.stringify(currentOrder) !== JSON.stringify(originalOrder);
  }, [images]);

  const isChangedSomething = useMemo(
    () => !!(isDifferentOrder || deletedImages.length),
    [isDifferentOrder, deletedImages]
  );

  const loadVehicleImages = useCallback(async () => {
    if (!vin) {
      return;
    }

    setLoading(true);
    try {
      const vehicleImages = await vehiclesService.listVehicleImages(vin);

      originalImages.current = vehicleImages;
      setImages(vehicleImages);
      setDeletedImages([]);
    } catch (e) {
      // catch
    } finally {
      setLoading(false);
    }
  }, [vin]);

  useEffect(() => {
    loadVehicleImages();
  }, [vin]);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setImages(prevImages => {
        const oldIndex = prevImages.findIndex(img => img.id === active.id);
        const newIndex = prevImages.findIndex(img => img.id === over!.id);

        return arrayMove(prevImages, oldIndex, newIndex);
      });
    }
  };

  const saveVehicleImagesChanges = useCallback(async () => {
    try {
      const imagesIdsAndOrders: Record<string, number | null> = {};
      images.forEach((img, index) => {
        imagesIdsAndOrders[img.id] = index;
      });
      if (deletedImages.length) {
        deletedImages.forEach(img => {
          imagesIdsAndOrders[img.id] = null;
        });
      }
      await vehiclesService.changeVehicleImages(vin!, imagesIdsAndOrders);
      enqueueSnackbar('Changes on vehicle images saved successfully!', {
        variant: 'success',
      });
      loadVehicleImages();
    } catch (e) {
      // catch
    }
  }, [images, deletedImages, vin, loadVehicleImages]);

  const sendButton = useMemo(
    () => (
      <>
        <Button
          variant="contained"
          onClick={saveVehicleImagesChanges}
          disabled={!isChangedSomething}
          endIcon={<Send />}
        >
          Save changes
        </Button>
        <Tooltip title="Undo changes">
          <span>
            <IconButton
              disabled={!isChangedSomething}
              onClick={() => {
                setImages(originalImages.current);
                setDeletedImages([]);
              }}
            >
              <Undo />
            </IconButton>
          </span>
        </Tooltip>
      </>
    ),
    [isChangedSomething, saveVehicleImagesChanges]
  );

  return (
    <div>
      <UploadDropzoneDialog
        open={uploadDialogOpen}
        onSave={async files => {
          try {
            setIsUploading(true);
            setUploadDialogOpen(false);
            await vehiclesService.uploadVehicleImages(vin!, files);
            setIsUploading(false);
            enqueueSnackbar('Images uploaded successfully', {
              variant: 'success',
            });
            loadVehicleImages();
          } catch (e) {
            setIsUploading(false);
            enqueueSnackbar('Error on upload files', {
              variant: 'error',
            });
          }
        }}
        onClose={() => setUploadDialogOpen(false)}
      />
      <Dialog open={isUploading}>
        <DialogContent>
          <Typography variant="body1" mb={2}>
            Uploading images for vehicle: <strong>{vin}</strong>
          </Typography>

          <Typography variant="body2" mb={2}>
            Please, wait until it finishes.
          </Typography>
          <LinearProgress />
        </DialogContent>
        <DialogActions>
          <Button
            variant={cancelClicked ? 'contained' : 'outlined'}
            color="error"
            startIcon={cancelClicked ? <Dangerous /> : null}
            onClick={() => {
              if (cancelClicked) {
                setIsUploading(false);
                setCancelClicked(false);
              } else {
                setCancelClicked(true);
              }
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {loading ? (
        <CircularProgress />
      ) : (
        <Box sx={{ maxWidth: 1100, mx: 'auto' }}>
          <Card sx={{ px: 2 }} elevation={3}>
            <CardContent>
              {showOnlyForEdit && (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box>
                    <Typography variant="h5">
                      Drag and drop images to reorder them
                    </Typography>
                  </Box>

                  <Box>
                    <Stack spacing={2} direction="row">
                      <Button
                        variant="outlined"
                        onClick={() => setUploadDialogOpen(true)}
                      >
                        Add More Images
                      </Button>
                      {sendButton}
                    </Stack>
                  </Box>
                </Stack>
              )}

              <Typography variant="body1">
                The first image is the main image for the vehicle.
              </Typography>

              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
              >
                <ImageList
                  variant="quilted"
                  sx={{ maxWidth: 1000, mx: 'auto' }}
                  cols={4}
                  gap={10}
                >
                  <SortableContext
                    items={images}
                    strategy={rectSortingStrategy}
                  >
                    {images.map((image, index) => (
                      <SortableImage
                        key={image.id}
                        index={index}
                        disableHover={!showOnlyForEdit}
                        onClickDelete={() => {
                          setImages(prev => {
                            const copy = [...prev];
                            copy.splice(index, 1);
                            return copy;
                          });
                          setDeletedImages(prev => {
                            const copy = [...prev];
                            copy.push(image);
                            return copy;
                          });
                        }}
                        path={image.url!}
                        order={image.order}
                        id={image.id}
                      />
                    ))}
                  </SortableContext>
                </ImageList>
              </DndContext>

              {showOnlyForEdit && (
                <>
                  <Divider sx={{ my: 4 }} />
                  <Box>
                    <Typography variant="h5">Removed images</Typography>

                    {deletedImages.length ? (
                      <ImageList
                        sx={{ maxWidth: 1000, mx: 'auto' }}
                        cols={4}
                        gap={10}
                      >
                        {deletedImages.map((image, index) => (
                          <Box key={image.id} sx={{ position: 'relative' }}>
                            <ImageListItem sx={{ opacity: 0.6 }}>
                              <img
                                src={image.url!}
                                alt={String(image.order)}
                                loading="eager"
                              />
                            </ImageListItem>
                            <Box
                              sx={{
                                position: 'absolute',
                                top: '0.5rem',
                                right: '0.5rem',
                                borderRadius: '50%',
                                opacity: 0.5,
                                backgroundColor: 'white',
                                '&:hover': {
                                  opacity: 1,
                                },
                              }}
                            >
                              <Tooltip title="Restore image">
                                <IconButton
                                  onClick={() => {
                                    setImages(prev => {
                                      const copy = [...prev];
                                      copy.push(image);
                                      return copy;
                                    });
                                    setDeletedImages(prev => {
                                      const copy = [...prev];
                                      copy.splice(index, 1);
                                      return copy;
                                    });
                                  }}
                                >
                                  <RestoreFromTrash />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </Box>
                        ))}
                      </ImageList>
                    ) : (
                      <Typography variant="body2">
                        There are no images marked as deleted
                      </Typography>
                    )}
                  </Box>
                </>
              )}
            </CardContent>
            {showOnlyForEdit && (
              <CardActions>
                <Stack
                  flex={1}
                  gap={2}
                  direction="row"
                  justifyContent="flex-end"
                >
                  {sendButton}
                </Stack>
              </CardActions>
            )}
          </Card>
        </Box>
      )}
    </div>
  );
}
