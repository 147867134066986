import IInstallment from '@/interfaces/payments/IInstallment';
import ISplitInstallmentDTO from '@/interfaces/payments/ISplitInstallmentDTO';
import { http } from '@/services/http';

class InstallmentsService {
  static BASE_PATH = 'installments';

  async rescheduleInstallment(
    installmentId: string,
    newDate: Date
  ): Promise<IInstallment> {
    const { data } = await http.patch(
      `/${InstallmentsService.BASE_PATH}/${installmentId}/reschedule`,
      {
        newDate,
      }
    );
    return data;
  }

  async editInstallment(
    installmentId: string,
    newDate: Date
  ): Promise<IInstallment> {
    const { data } = await http.patch(
      `/${InstallmentsService.BASE_PATH}/${installmentId}`,
      {
        newDate,
      }
    );
    return data;
  }

  async splitInstallment(
    installmentId: string,
    values: ISplitInstallmentDTO
  ): Promise<IInstallment> {
    const { data } = await http.post(
      `/${InstallmentsService.BASE_PATH}/${installmentId}/split`,
      values
    );
    return data;
  }
}

const installmentsService = new InstallmentsService();

export default installmentsService;
