import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { ExpandMore } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';

import { PageLink } from '@/components/AppContainer/types';
import { useAuthorization } from '@/hooks/auth';

type Props = {
  pagesLinks: PageLink[];
};

export default function MobileDrawer({ pagesLinks }: Props) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [openIndex, setOpenIndex] = useState(-1);
  const navigate = useNavigate();
  const { checkUserHasRole } = useAuthorization();

  const toggleDrawer = () => {
    setDrawerOpen(prev => !prev);
  };

  return (
    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={() => toggleDrawer()}
        color="inherit"
      >
        <MenuIcon />
      </IconButton>
      <Drawer anchor="left" open={drawerOpen} onClose={() => toggleDrawer()}>
        <List>
          {pagesLinks.filter(p => checkUserHasRole(p.roles || [])).map((page, index) => (
            <React.Fragment key={page.path}>
              <Accordion disableGutters expanded={openIndex === index}>
                <AccordionSummary
                  expandIcon={
                    <IconButton
                      onClick={() => {
                        setOpenIndex(prevIndex =>
                          prevIndex === index ? -1 : index
                        );
                      }}
                    >
                      <ExpandMore />
                    </IconButton>
                  }
                  aria-controls="panel1a-content"
                >
                  {page.icon && (
                    <ListItemIcon sx={{ minWidth: 'auto', mr: 2 }}>
                      <page.icon />
                    </ListItemIcon>
                  )}
                  <Typography
                    onClick={() => {
                      setDrawerOpen(false);
                      setOpenIndex(-1);
                    }}
                    component={Link}
                    to={page.mainPagePath || page.path}
                    sx={{ textDecoration: 'none', flex: 1 }}
                    color="textPrimary"
                    fontWeight="bold"
                  >
                    {page.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ pl: 2, py: 0, my: 0 }}>
                  <List sx={{ p: 0, mb: 2 }}>
                    {page.menuItems.map(({ path, icon: Icon, title, roles }) => {
                      if (roles && !checkUserHasRole(roles)) {
                        return null;
                      }
                        return (
                          <ListItem key={path} onClick={() => navigate(path)}>
                            <ListItemIcon sx={{ minWidth: 0, mr: 1 }}>
                              <Icon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>{title}</ListItemText>
                          </ListItem>
                        )
                      }
                    )}
                  </List>
                </AccordionDetails>
              </Accordion>
            </React.Fragment>
          ))}
        </List>
      </Drawer>
    </Box>
  );
}
