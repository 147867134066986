import { useNavigate } from 'react-router-dom';

import { Box, Button, Typography } from '@mui/material';

interface Props {
  maintenanceId: string;
  vehicleVin: string;
  description?: string;
}

export default function MaintenanceSchedulerDetails({
  maintenanceId,
  vehicleVin,
  description,
}: Props) {
  const detailsToDisplay = [
    {
      label: 'Vehicle Vin: ',
      value: vehicleVin,
    },
    {
      label: 'Description:',
      value: description,
      optional: true,
    },
  ];

  const navigate = useNavigate();

  const redirectToMaintenancePage = () => {
    navigate(`/maintenance/list/${maintenanceId}`);
  };

  return (
    <Box pt={2} pb={2}>
      <Box mb={2}>
        {detailsToDisplay.map((detail, index) =>
          detail.optional && !detail.value ? null : (
            <Typography
              key={`maintenance-scheduler-detail-${detail.label}`}
              variant="body1"
              fontWeight="medium"
              mt={index > 0 ? 0.5 : 0}
            >
              {detail.label}
              <Typography variant="body1">{detail.value}</Typography>
            </Typography>
          )
        )}
      </Box>

      <Button
        color="primary"
        variant="contained"
        fullWidth
        onClick={redirectToMaintenancePage}
      >
        View maintenance page
      </Button>
    </Box>
  );
}
