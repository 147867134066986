import ISubscriptionType from '@/interfaces/subscriptions/ISubscriptionType';
import IVehicle from '@/interfaces/vehicles/IVehicle';

export function emptyStringToNull(
  value: unknown,
  originalValue: unknown
): null | unknown {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null;
  }
  return value;
}

export function vehicleAndSubTypeSelectedErrorString(
  vehicle?: IVehicle,
  subscriptionType?: ISubscriptionType,
  messages = {
    prefix: 'Select',
    suffix: 'to see default value',
    vehicleMissing: 'a vehicle',
    subscriptionTypeMissing: 'a subscription type',
    both: 'a vehicle and a subscription type',
  }
): string {
  const { prefix, suffix, both, subscriptionTypeMissing, vehicleMissing } =
    messages;

  if (vehicle && subscriptionType) {
    return '';
  }

  if (!vehicle && !subscriptionType) {
    return `${prefix} ${both} ${suffix}`;
  }

  if (!vehicle) {
    return `${prefix} ${vehicleMissing} ${suffix}`;
  }

  return `${prefix} ${subscriptionTypeMissing} ${suffix}`;
}
