import { Fragment, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import {
  Box,
  Button,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
} from '@mui/material';

import { PageLink } from '@/components/AppContainer/types';
import { useAuthorization } from '@/hooks/auth';
import { UserRoles } from '@/interfaces/system-users/UserRoles';

type Props = {
  pagesLinks: PageLink[];
};

export default function AppBarContent({ pagesLinks }: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [openIndex, setOpenIndex] = useState(-1);
  const navigate = useNavigate();
  const { checkUserHasRole } = useAuthorization();

  return (
    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
      {pagesLinks
        .filter(p =>
          checkUserHasRole(p.roles || [UserRoles.ADMIN, UserRoles.SALES])
        )
        .map((page, index) => (
          <Fragment key={`app-bar-item-${page.path}`}>
            <Button
              component={Link}
              to={page.mainPagePath || page.path}
              sx={{ my: 2, color: 'white' }}
              aria-controls={openIndex === index ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openIndex === index ? 'true' : undefined}
              endIcon={
                openIndex === index ? (
                  <ArrowDropUp />
                ) : (
                  <ArrowDropDown
                    onMouseEnter={event => {
                      setOpenIndex(index);
                      setAnchorEl(
                        event!.currentTarget!.parentElement!
                          .parentElement! as HTMLButtonElement
                      );
                    }}
                  />
                )
              }
            >
              {page.title}
            </Button>
            <Menu
              id="basic-menu"
              sx={{ display: { xs: 'none', md: 'block' } }}
              anchorEl={anchorEl}
              open={openIndex === index}
              onClose={() => {
                setAnchorEl(null);
                setOpenIndex(-1);
              }}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
                onMouseLeave: () => {
                  setAnchorEl(null);
                  setOpenIndex(-1);
                },
              }}
            >
              <ListSubheader>{page.title}</ListSubheader>
              {page.menuItems.map(({ path, title, icon: Icon, roles }) => {
                if (roles && !checkUserHasRole(roles)) {
                  return null;
                }
                return (
                  <MenuItem
                    key={path}
                    onClick={() => {
                      navigate(path);
                      setAnchorEl(null);
                      setOpenIndex(-1);
                    }}
                  >
                    <ListItemIcon>
                      <Icon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{title}</ListItemText>
                  </MenuItem>
                );
              })}
            </Menu>
          </Fragment>
        ))}
    </Box>
  );
}
