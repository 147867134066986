export const currencyMask = (customProps: Record<string, any> = {}) => ({
  mask: '$ num',
  maskOptions: {
    lazy: false,
    blocks: {
      num: {
        mask: Number,
        scale: 2,
        thousandsSeparator: ',',
        radix: '.',
        min: 0,
        ...customProps,
      },
    },
  },
});

export const numericMask = (numberOfDecimals = 2) => ({
  mask: Number,
  maskOptions: {
    scale: numberOfDecimals,
    thousandsSeparator: ',',
    radix: '.',
    min: 0,
  },
});
