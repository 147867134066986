import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid } from '@mui/material';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import * as Yup from 'yup';

import { useConnectedFormComponents } from '@/components/form';
import { currencyMask, numericMask } from '@/constants/mask';
import { formatCurrency } from '@/helpers/currency';
import { formatAmount, formatDecimalAmount } from '@/helpers/number';
import AppError from '@/interfaces/AppError';
import subscriptionsService from '@/services/subscriptions';

const schema = yup.object().shape({
  displayName: yup.string().required('Display Name is required'),
  descriptiveName: yup.string().required('Descriptive Name is required'),
  activationBaseValue: Yup.object()
    .shape({
      masked: Yup.string().required(),
      unmasked: Yup.number()
        .transform((_, val) => (Number(val) ? Number(val) : null))
        .min(0, 'Activation base value must be greater than 0')
        .nullable(),
    })
    .nullable(),
  activationMultiplierValue: Yup.object()
    .typeError('This field is required')
    .shape({
      masked: Yup.string().required(),
      unmasked: Yup.number()
        .typeError('This field is required')
        .min(0.00001, 'Activation multiplier value must be at least 0.00001')
        .required(),
    })
    .required('This field is required'),
  monthlyBaseValue: Yup.object()
    .shape({
      masked: Yup.string().required(),
      unmasked: Yup.number()
        .transform((_, val) => (Number(val) ? Number(val) : null))
        .min(0, 'Monthly base value must be greater than 0')
        .nullable(),
    })
    .nullable(),
  monthlyMultiplierValue: Yup.object()
    .typeError('This field is required')
    .shape({
      masked: Yup.string().required(),
      unmasked: Yup.number()
        .typeError('This field is required')
        .min(0.00001, 'Monthly multiplier value must be at least 0.00001')
        .required(),
    })
    .nullable(),
  durationInMonths: Yup.object()
    .typeError('This field is required')
    .shape({
      masked: Yup.string().required(),
      unmasked: Yup.number()
        .typeError('This field is required')
        .min(1, 'Duration must be greater than or equal to 1')
        .required(),
    })
    .required('This field is required'),
  mileageMonthlyLimit: Yup.object()
    .typeError('This field is required')
    .shape({
      masked: Yup.string().required(),
      unmasked: Yup.number()
        .typeError('This field is required')
        .min(0, 'Mileage limit must be greater than 0')
        .required(),
    })
    .required('This field is required'),
  renewDiscountPercentage: Yup.number()
    .notRequired()
    .min(0)
    .max(100)
    .typeError('Renew Discount must be a number'),
});

type Form = {
  displayName: string;
  descriptiveName: string;
  renewDiscountPercentage: number;
  activationBaseValue: {
    masked: string;
    unmasked: string;
  };
  activationMultiplierValue: {
    masked: string;
    unmasked: number;
  };
  monthlyBaseValue: {
    masked: string;
    unmasked: string;
  };
  monthlyMultiplierValue: {
    masked: string;
    unmasked: number;
  };
  durationInMonths: {
    masked: string;
    unmasked: number;
  };
  mileageMonthlyLimit: {
    masked: string;
    unmasked: number;
  };
};

export default function SubscriptionTypeForm() {
  const { handleSubmit, setValue, control } = useForm<Form>({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const { TextInput } = useConnectedFormComponents({
    control,
  });
  const { enqueueSnackbar } = useSnackbar();
  const [submitLoading, setSubmitLoading] = useState(false);
  const { id } = useParams();
  const isEditing = useMemo(() => !!id, [id]);

  const fetchSubscriptionType = useCallback(async () => {
    if (id) {
      const subscriptionType = await subscriptionsService.getSubscriptionType(
        id
      );
      setValue('displayName', subscriptionType.displayName);
      setValue('descriptiveName', subscriptionType.descriptiveName);

      setValue('activationBaseValue', {
        masked: formatCurrency(subscriptionType.activationBaseValue),
        unmasked: subscriptionType.activationBaseValue.toString(),
      });
      setValue('activationMultiplierValue', {
        masked: formatDecimalAmount(
          subscriptionType.activationMultiplierValue,
          5
        ),
        unmasked: subscriptionType.activationMultiplierValue,
      });
      setValue('monthlyBaseValue', {
        masked: formatCurrency(subscriptionType.monthlyBaseValue),
        unmasked: subscriptionType.monthlyBaseValue.toString(),
      });
      setValue('monthlyMultiplierValue', {
        masked: formatDecimalAmount(subscriptionType.monthlyMultiplierValue, 5),
        unmasked: subscriptionType.monthlyMultiplierValue,
      });
      setValue('durationInMonths', {
        masked: formatAmount(subscriptionType.durationInMonths),
        unmasked: subscriptionType.durationInMonths,
      });
      setValue('mileageMonthlyLimit', {
        masked: formatAmount(subscriptionType.mileageMonthlyLimit),
        unmasked: subscriptionType.mileageMonthlyLimit,
      });
      setValue('renewDiscountPercentage', subscriptionType.renewDiscountPercentage * 100 ?? 0)
    }
  }, [id]);

  useEffect(() => {
    fetchSubscriptionType();
  }, [fetchSubscriptionType]);

  const onSubmit = async (data: Form) => {
    try {
      setSubmitLoading(true);
      const dataToSubmit = {
        ...data,
        durationInMonths: data.durationInMonths.unmasked,
        mileageMonthlyLimit: data.mileageMonthlyLimit.unmasked,
        activationBaseValue: Number(data.activationBaseValue?.unmasked) || 0,
        activationMultiplierValue:
          data.activationMultiplierValue.unmasked || undefined,
        monthlyBaseValue: Number(data.monthlyBaseValue?.unmasked) || 0,
        monthlyMultiplierValue:
          data.monthlyMultiplierValue.unmasked || undefined,
        renewDiscountPercentage: (data.renewDiscountPercentage / 100) ?? 0
      };
      if (isEditing && id) {
        await subscriptionsService.updateSubscriptionType(
          id,
          JSON.parse(
            JSON.stringify({
              ...dataToSubmit,
              durationInMonths: undefined,
            })
          )
        );
      } else {
        await subscriptionsService.createSubscriptionType(dataToSubmit);
      }
      navigate('/subscriptions/types');
    } catch (error) {
      console.error(error);
      enqueueSnackbar(
        error instanceof AppError
          ? error?.message
          : `Error on ${
              isEditing ? 'editing' : 'creating'
            } subscription type. Please, try again later`,
        { variant: 'error' }
      );
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      sx={{ mt: 1 }}
    >
      <Grid
        container
        direction={{ xs: 'column', md: 'row' }}
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={3}>
          <TextInput
            margin="normal"
            required
            label="Descriptive Name"
            fullWidth
            fieldName="descriptiveName"
          />
        </Grid>
        <Grid item xs={3}>
          <TextInput
            margin="normal"
            required
            label="Display Name"
            fullWidth
            fieldName="displayName"
          />
        </Grid>
        <Grid item xs={3}>
          <TextInput
            margin="normal"
            required
            disabled={isEditing}
            label="Duration in Months"
            fullWidth
            fieldName="durationInMonths"
            maskProps={numericMask(0)}
          />
        </Grid>
        <Grid item xs={3}>
          <TextInput
            margin="normal"
            required
            label="Mileage Monthly Limit"
            fullWidth
            fieldName="mileageMonthlyLimit"
            maskProps={numericMask(0)}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction={{ xs: 'column', md: 'row' }}
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={3}>
          <TextInput
            margin="normal"
            required={false}
            label="Activation Base Value"
            fullWidth
            fieldName="activationBaseValue"
            maskProps={currencyMask()}
          />
        </Grid>
        <Grid item xs={3}>
          <TextInput
            margin="normal"
            required
            label="Activation Multiplier Value"
            fullWidth
            fieldName="activationMultiplierValue"
            maskProps={numericMask(5)}
          />
        </Grid>
        <Grid item xs={3}>
          <TextInput
            margin="normal"
            required={false}
            label="Monthly Base Value"
            fullWidth
            fieldName="monthlyBaseValue"
            maskProps={currencyMask()}
          />
        </Grid>
        <Grid item xs={3}>
          <TextInput
            margin="normal"
            required
            label="Monthly Multiplier Value"
            fullWidth
            fieldName="monthlyMultiplierValue"
            maskProps={numericMask(5)}
          />
        </Grid>
        <Grid item xs={3}>
          <TextInput
            margin="normal"
            label="Renew Discount (%)"
            fullWidth
            fieldName="renewDiscountPercentage"
          />
        </Grid>
      </Grid>

      <Grid>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={submitLoading}
          onClick={handleSubmit(onSubmit)}
        >
          {submitLoading ? 'Loading...' : 'Submit'}
        </Button>
      </Grid>
    </Box>
  );
}
