import ICreateVehicleRequestDTO from '@/interfaces/vehicles/ICreateVehicleDTO';
import ICreateVehicleExpenseCategoryDTO from '@/interfaces/vehicles/ICreateVehicleExpenseCategoryDTO';
import ICreateVehicleExpenseDTO from '@/interfaces/vehicles/ICreateVehicleExpenseDTO';
import ICreateVehicleGPSDTO from '@/interfaces/vehicles/ICreateVehicleGPSDTO';
import IEditVehicleExpenseDTO from '@/interfaces/vehicles/IEditVehicleExpenseDTO';
import IUpdateVehicleDTO from '@/interfaces/vehicles/IUpdateVehicleDTO';
import IVehicle from '@/interfaces/vehicles/IVehicle';
import { IVehicleExpense } from '@/interfaces/vehicles/IVehicleExpense';
import { IVehicleExpenseCategory } from '@/interfaces/vehicles/IVehicleExpenseCategory';
import { IVehicleGPS } from '@/interfaces/vehicles/IVehicleGPS';
import { IVehicleImage } from '@/interfaces/vehicles/IVehicleImage';
import { http } from '@/services/http';

type ListVehicleParams = {
  available?: boolean;
};

class VehiclesService {
  static BASE_PATH = 'vehicles';

  async getSpecifications(vin: string) {
    const { data } = await http.get(
      `/${VehiclesService.BASE_PATH}/${vin}/specifications`
    );
    return {
      vin,
      year: data.year,
      make: data.make,
      model: data.model,
      trim: data.trim,
      engine: data.engine,
      style: data.style,
      interiorColors: data.interior_trim,
      exteriorColors: data.exterior_color,
    };
  }

  async listGps(available?: boolean): Promise<IVehicleGPS[]> {
    const { data } = await http.get(`/vehicles/gps`, {
      params: {
        available,
      },
    });
    return data;
  }

  async listVehicles({ available }: ListVehicleParams = {}): Promise<
    IVehicle[]
  > {
    const params: ListVehicleParams = {};
    if (available != null) {
      params.available = available;
    }
    const { data } = await http.get(`/${VehiclesService.BASE_PATH}`, {
      params,
    });
    return data;
  }

  async getVehicle(vin: string): Promise<IVehicle> {
    const { data } = await http.get(`/${VehiclesService.BASE_PATH}/${vin}`);
    return data;
  }

  async createVehicle(
    vehicleData: ICreateVehicleRequestDTO
  ): Promise<IVehicle> {
    const { data } = await http.post(
      `/${VehiclesService.BASE_PATH}`,
      vehicleData
    );
    return data;
  }

  async updateVehicle(
    vin: string,
    vehicleData: IUpdateVehicleDTO
  ): Promise<IVehicle> {
    const { data } = await http.put(
      `/${VehiclesService.BASE_PATH}/${vin}`,
      vehicleData
    );
    return data;
  }

  async createVehicleGPS(
    vehicleGPSData: ICreateVehicleGPSDTO
  ): Promise<IVehicleGPS> {
    const { data } = await http.post(
      `/${VehiclesService.BASE_PATH}/gps`,
      vehicleGPSData
    );
    return data;
  }

  async uploadVehicleImages(vin: string, images: File[]): Promise<void> {
    const formData = new FormData();
    images.forEach(image => {
      formData.append('vehicleImages', image);
    });
    await http.post(
      `/${VehiclesService.BASE_PATH}/${vin}/vehicle-images`,
      formData
    );
  }

  async listVehicleImages(vin: string): Promise<IVehicleImage[]> {
    const { data } = await http.get(
      `/${VehiclesService.BASE_PATH}/${vin}/vehicle-images`
    );
    return data;
  }

  async changeVehicleImages(
    vin: string,
    imagesIdsAndOrders: Record<string, number | null>
  ): Promise<IVehicleImage[]> {
    const { data } = await http.put(
      `/${VehiclesService.BASE_PATH}/${vin}/vehicle-images`,
      { imagesIdsAndOrders }
    );
    return data;
  }

  async createVehicleExpense(
    expenseData: ICreateVehicleExpenseDTO
  ): Promise<IVehicleExpense> {
    const { data } = await http.post(
      `/${VehiclesService.BASE_PATH}/expenses`,
      expenseData
    );
    return data;
  }

  async listVehicleExpenseCategories(): Promise<IVehicleExpenseCategory[]> {
    const { data } = await http.get(`/vehicles/expenses/categories`);
    return data;
  }

  async editVehicleExpense({
    expenseId,
    ...expenseData
  }: IEditVehicleExpenseDTO) {
    const { data } = await http.patch(
      `/${VehiclesService.BASE_PATH}/expenses/${expenseId}`,
      expenseData
    );
    return data;
  }

  async deleteVehicleExpense(expenseId: string) {
    await http.delete(
      `/${VehiclesService.BASE_PATH}/expenses/${expenseId}`,
    );
  }

  async createVehicleExpenseCategory(expenseData: ICreateVehicleExpenseCategoryDTO): Promise<IVehicleExpenseCategory> {
    const { data } = await http.post(
      `/${VehiclesService.BASE_PATH}/expenses/categories`,
      expenseData
    );
    return data;
  }
}

const vehiclesService = new VehiclesService();

export default vehiclesService;
