import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { useConnectedFormComponents } from '@/components/form';
import { IMaintenanceType } from '@/interfaces/maintenance/IMaintenanceType';
import DurationSelect from '@/pages/Maintenance/components/DurationSelect';
import maintenancesService from '@/services/maintenances';

export type MaintenanceTypeFormFields = {
  maintenanceType: IMaintenanceType;
  duration: number;
};

interface Props {
  duration?: number;
}

export default function MaintenanceTypeFields({ duration }: Props) {
  const [maintenanceTypes, setMaintenanceTypes] = useState<IMaintenanceType[]>(
    []
  );

  const { control, setValue, watch } =
    useFormContext<MaintenanceTypeFormFields>();

  const { AutoComplete } =
    useConnectedFormComponents<MaintenanceTypeFormFields>({
      control,
    });

  const maintenanceTypeSelected = watch('maintenanceType');

  useEffect(() => {
    setValue('duration', duration || maintenanceTypeSelected?.duration);
  }, [duration, maintenanceTypeSelected, setValue]);

  useEffect(() => {
    (async () => {
      try {
        const maintenanceTypesResponse =
          await maintenancesService.listMaintenanceTypes();
        setMaintenanceTypes(maintenanceTypesResponse);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  return (
    <>
      <AutoComplete
        getOptionLabel={type => typeof type === 'string' ? type : type.displayName}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        options={maintenanceTypes}
        fieldName="maintenanceType"
        label="Type"
      />

      <DurationSelect
        onChange={value => setValue('duration', value)}
        defaultValue={duration || maintenanceTypeSelected?.duration}
      />
    </>
  );
}
