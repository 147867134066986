import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import {
  DndContext,
  DragOverlay,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useSnackbar } from 'notistack';
import { useDebounce } from 'use-debounce';
import * as Yup from 'yup';

import { Draggable } from '@/components/drag-n-drop/Draggable';
import { Droppable } from '@/components/drag-n-drop/Droppable';
import { useConnectedFormComponents } from '@/components/form';
import { currencyMask, numericMask } from '@/constants/mask';
import { formatCurrency } from '@/helpers/currency';
import { formatAmount } from '@/helpers/number';
import { vehicleDisplayName } from '@/helpers/vehicles';
import { useMeta } from '@/hooks/meta';
import AppError from '@/interfaces/AppError';
import { IMaskInput } from '@/interfaces/IMaskInput';
import { IInstallmentStatus } from '@/interfaces/payments/IInstallment';
import IInvoice, {
  ChargeType,
  IInvoiceStatus,
} from '@/interfaces/payments/IInvoice';
import IGetSubscriptionPriceResult from '@/interfaces/subscriptions/IGetSubscriptionPriceResult';
import ISubscription from '@/interfaces/subscriptions/ISubscription';
import IVehicle from '@/interfaces/vehicles/IVehicle';
import invoicesService from '@/services/invoices';
import subscriptionsService from '@/services/subscriptions';
import vehiclesService from '@/services/vehicles';

type Form = {
  vehicle: IVehicle;
  currentVehicleMileage: number;
  activationValue?: number;
  monthlyValue?: number;
  dealerFee?: number;
};

const schema = Yup.object().shape({
  vehicle: Yup.object().required('Vehicle is required').nullable(),
  currentVehicleMileage: Yup.number()
    .required('Current mileage is required')
    .nullable()
    .transform((_, val) => (val ? Number(val.unmasked) : undefined)),
  activationValue: Yup.number()
    .nullable()
    .transform((_, val) => (val ? Number(val.unmasked) : undefined)),
  monthlyValue: Yup.number()
    .nullable()
    .transform((_, val) => (val ? Number(val.unmasked) : undefined)),
  dealerFee: Yup.number()
    .nullable()
    .transform((_, val) => (val ? Number(val.unmasked) : undefined)),
});

function DragInvoiceItem({
  label,
  opacity = 1,
  even,
}: {
  label: string;
  opacity?: number;
  even?: boolean;
}) {
  return (
    <Box
      p={1}
      sx={{
        backgroundColor: even ? grey[50] : grey[200],
        opacity,
      }}
    >
      {label}
    </Box>
  );
}

const sortedInvoices = (arr: Array<IInvoice>) =>
  arr.sort((a, b) => a.name.localeCompare(b.name));

export default function ChangeVehicle() {
  const { id } = useParams();
  const [subscription, setSubscription] = useState<ISubscription | null>(null);
  const [loading, setLoading] = useState(false);
  const [invoicesLoading, setInvoicesLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [vehiclesList, setVehiclesList] = useState<IVehicle[]>([]);

  const { handleSubmit, control, watch } = useForm<Form>({
    resolver: yupResolver(schema),
  });
  const { TextInput, AutoComplete } = useConnectedFormComponents<Form>({
    control,
  });
  const { enqueueSnackbar } = useSnackbar();
  const vehicleSelected = watch('vehicle');
  const customActivation = watch('activationValue');
  const dealerFee = watch('dealerFee');
  const [customActivationDebounced] = useDebounce(customActivation, 500);
  const customMonthly = watch('monthlyValue');
  const [customMonthlyDebounced] = useDebounce(customMonthly, 500);
  const navigate = useNavigate();
  const { setPageTitle } = useMeta();
  const sensors = useSensors(useSensor(PointerSensor), useSensor(TouchSensor));
  const [invoices, setInvoices] = useState<IInvoice[]>([]);
  const openMonthlyInvoicesToChangeToNewVehicle = useMemo(
    () =>
      invoices.filter(
        invoice =>
          invoice.subscriptionVehicle.id === subscription?.currentVehicle.id &&
          invoice.type === ChargeType.MONTHLY &&
          invoice.status !== IInvoiceStatus.PAID &&
          !invoice.installments.some(
            installment => installment.status === IInstallmentStatus.PAID
          )
      ),
    [invoices]
  );

  const [invoicesToChange, setInvoicesToChange] = useState<IInvoice[]>([]);
  const [invoicesToKeep, setInvoicesToKeep] = useState<IInvoice[]>([]);
  const [activeInvoice, setActiveInvoice] = useState<IInvoice | null>(null);
  const [simulationResult, setSimulationResult] =
    useState<IGetSubscriptionPriceResult | null>(null);

  useEffect(() => {
    setInvoicesToChange(openMonthlyInvoicesToChangeToNewVehicle);
    setInvoicesToKeep([]);
  }, [openMonthlyInvoicesToChangeToNewVehicle]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        if (!id) {
          navigate('/subscriptions/list');
          return;
        }
        const sub = await subscriptionsService.getSubscription(Number(id));
        if (!sub) {
          navigate(`/subscriptions/list/${id}`);
          return;
        }
        setSubscription(sub);
        setPageTitle(`Change Vehicle of #${sub.id}`);
      } catch (error) {
        console.error(error);
        enqueueSnackbar(
          error instanceof AppError
            ? error?.message
            : 'Error on loading subscription. Please, try again later',
          { variant: 'error' }
        );
        navigate('/subscriptions/list');
      } finally {
        setLoading(false);
      }
    })();
  }, [id]);

  useEffect(() => {
    (async () => {
      setInvoicesLoading(true);
      try {
        if (!subscription) {
          return;
        }
        const loadedInvoices = await invoicesService.listInvoices(
          subscription.id
        );
        if (!loadedInvoices.length) {
          return;
        }
        setInvoices(loadedInvoices);
      } catch (error) {
        console.error(error);
        enqueueSnackbar(
          error instanceof AppError
            ? error?.message
            : 'Error on loading invoices. Please, try again later',
          { variant: 'error' }
        );
      } finally {
        setTimeout(() => {
          setInvoicesLoading(false);
        }, 1000);
      }
    })();
  }, [subscription]);

  useEffect(() => {
    (async () => {
      if (subscription && vehicleSelected) {
        const result = await subscriptionsService.getPrice({
          subscriptionTypeId: subscription.type.id,
          vin: vehicleSelected.vin,
          activationValue: (customActivationDebounced as any)?.unmasked
            ? Number((customActivationDebounced as any)?.unmasked)
            : undefined,
          monthlyValue: (customMonthlyDebounced as any)?.unmasked
            ? Number((customMonthlyDebounced as any)?.unmasked)
            : undefined,
          dealerFee,
        });
        setSimulationResult(result);
      } else {
        setSimulationResult(null);
      }
    })();
  }, [
    vehicleSelected,
    subscription,
    customActivationDebounced,
    customMonthlyDebounced,
    dealerFee,
  ]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const vehicles = await vehiclesService.listVehicles({
          available: true,
        });

        setVehiclesList(vehicles);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const onSubmit = async (data: Form) => {
    try {
      setSubmitLoading(true);
      await subscriptionsService.changeSubscriptionVehicle(subscription!.id, {
        vehicleVin: data.vehicle.vin,
        currentVehicleMileage: data.currentVehicleMileage,
        activationValue: data.activationValue,
        monthlyValue: data.monthlyValue,
        invoicesIdsToChangeVehicle: invoicesToChange.map(invoice => invoice.id),
        dealerFee: data.dealerFee,
      });
      navigate(`/subscriptions/list/${subscription?.id}`);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(
        error instanceof AppError
          ? error?.message
          : 'Error on changing subscription vehicle. Please, try again later',
        { variant: 'error' }
      );
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {loading || !subscription || invoicesLoading ? (
        <CircularProgress />
      ) : (
        <Box mx="auto" maxWidth={1200}>
          <Stack direction="row" spacing={2}>
            <Box>
              <Typography variant="body1">Current vehicle:</Typography>
              <Typography fontWeight="bold">
                {vehicleDisplayName(subscription?.currentVehicle?.vehicle)}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body1">Last mileage:</Typography>
              <Typography fontWeight="bold">
                {formatAmount(subscription?.currentVehicle?.vehicle.mileage)}
              </Typography>
            </Box>
          </Stack>
          <Stack spacing={2} direction="row">
            <Box>
              <Typography variant="body1">Current Activation:</Typography>
              <Typography fontWeight="bold">
                {formatCurrency(subscription?.currentVehicle?.activation)}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body1">Current Monthly:</Typography>
              <Typography fontWeight="bold">
                {formatCurrency(subscription?.currentVehicle?.monthly)}
              </Typography>
            </Box>
          </Stack>
          <TextInput
            margin="normal"
            label="Current Vehicle Mileage"
            fullWidth
            required
            fieldName="currentVehicleMileage"
            maskProps={numericMask(0)}
          />
          <Typography sx={{ mt: 2 }}>New vehicle details</Typography>
          <AutoComplete
            options={vehiclesList}
            getOptionLabel={vehicle => `${vehicleDisplayName(vehicle)}`}
            isOptionEqualToValue={(option, value) => option.vin === value.vin}
            fieldName="vehicle"
            label="New Vehicle"
          />
          <Stack spacing={2} direction="row">
            <Box flex={1}>
              <TextInput
                margin="normal"
                label="Activation Value"
                fullWidth
                fieldName="activationValue"
                maskProps={currencyMask()}
                helperText={
                  subscription?.type && vehicleSelected && simulationResult
                    ? `Default would be: ${formatCurrency(
                        simulationResult.totalActivation
                      )}`
                    : undefined
                }
              />
            </Box>
            <Box flex={1}>
              <TextInput
                margin="normal"
                label="Monthly Value"
                fullWidth
                fieldName="monthlyValue"
                maskProps={currencyMask()}
                helperText={
                  subscription?.type && vehicleSelected && simulationResult
                    ? `Default would be: ${formatCurrency(
                        simulationResult.totalMonthly
                      )}`
                    : undefined
                }
              />
            </Box>

            <Box flex={1}>
              <TextInput
                margin="normal"
                label="Dealer Fee"
                fullWidth
                fieldName="dealerFee"
                maskProps={currencyMask()}
                helperText={`Default would be: ${formatCurrency(200)}`}
              />
            </Box>
          </Stack>

          <Typography sx={{ mt: 2 }}>Invoices details</Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            You can drag and drop the invoices, or use the arrow buttons, to
            change which invoices you want to keep on current vehicle, and which
            ones to change to the new vehicle
          </Typography>

          <DndContext
            onDragStart={event => {
              setActiveInvoice(
                openMonthlyInvoicesToChangeToNewVehicle.find(
                  inv => inv.id === event.active.id
                ) ?? null
              );
            }}
            onDragEnd={event => {
              const invoiceIsOnKeep = invoicesToKeep.find(
                inv => inv.id === event.active.id
              );
              const invoiceIsOnChange = invoicesToChange.find(
                inv => inv.id === event.active.id
              );
              if (
                invoiceIsOnKeep &&
                event.over &&
                event.over.id.toString().includes('change')
              ) {
                setInvoicesToKeep(
                  sortedInvoices(
                    invoicesToKeep.filter(inv => inv.id !== event.active.id)
                  )
                );
                setInvoicesToChange(
                  sortedInvoices([...invoicesToChange, invoiceIsOnKeep])
                );
              } else if (
                invoiceIsOnChange &&
                event.over &&
                event.over.id.toString().includes('keep')
              ) {
                setInvoicesToChange(
                  sortedInvoices(
                    invoicesToChange.filter(inv => inv.id !== event.active.id)
                  )
                );
                setInvoicesToKeep(
                  sortedInvoices([...invoicesToKeep, invoiceIsOnChange])
                );
              }
            }}
            sensors={sensors}
          >
            <Stack direction="row" spacing={2}>
              <Droppable
                flex={1}
                borderColor="gray"
                border={1}
                borderRadius={2}
                height={300}
                overflow="scroll"
                p={2}
                droppableId="droppable-invoices-keep"
              >
                <Typography variant="body1" fontWeight="bold" mb={2}>
                  Invoices to keep on current vehicle
                </Typography>
                {!invoicesToKeep.length && (
                  <Typography variant="body2">
                    No invoices to keep on current vehicle
                  </Typography>
                )}
                {invoicesToKeep.map((invoice, index) => (
                  <Stack
                    alignItems="center"
                    flex={1}
                    direction="row"
                    spacing={1}
                    key={invoice.id}
                  >
                    <Draggable flex={1} draggableId={invoice.id.toString()}>
                      <DragInvoiceItem
                        even={index % 2 === 0}
                        label={`${invoice.name} - ${formatCurrency(
                          invoice.totalAmount
                        )}`}
                      />
                    </Draggable>
                    <Tooltip title="Change invoice to new vehicle">
                      <IconButton
                        onClick={() => {
                          setInvoicesToKeep(
                            sortedInvoices(
                              invoicesToKeep.filter(
                                inv => inv.id !== invoice.id
                              )
                            )
                          );
                          setInvoicesToChange(
                            sortedInvoices([...invoicesToChange, invoice])
                          );
                        }}
                      >
                        <ArrowForward />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                ))}
              </Droppable>
              <Droppable
                flex={1}
                borderColor="gray"
                border={1}
                borderRadius={2}
                height={300}
                overflow="scroll"
                p={2}
                droppableId="droppable-invoices-change"
              >
                <Typography variant="body1" fontWeight="bold" mb={2}>
                  Invoices to change to new vehicle
                </Typography>
                {!invoicesToChange.length && (
                  <Typography variant="body2">
                    No invoices to change to new vehicle
                  </Typography>
                )}
                {invoicesToChange.map((invoice, index) => {
                  let amount = invoice.totalAmount;

                  if (simulationResult) {
                    if (invoice.type === ChargeType.ACTIVATION) {
                      amount = simulationResult.totalActivation;
                    } else if (invoice.type === ChargeType.MONTHLY) {
                      amount = simulationResult.totalMonthly;
                    }
                  }

                  return (
                    <Stack
                      alignItems="center"
                      flex={1}
                      direction="row"
                      spacing={1}
                      key={invoice.id}
                    >
                      <Tooltip title="Keep invoice on current vehicle">
                        <IconButton
                          onClick={() => {
                            setInvoicesToChange(
                              sortedInvoices(
                                invoicesToChange.filter(
                                  inv => inv.id !== invoice.id
                                )
                              )
                            );
                            setInvoicesToKeep(
                              sortedInvoices([...invoicesToKeep, invoice])
                            );
                          }}
                        >
                          <ArrowBack />
                        </IconButton>
                      </Tooltip>
                      <Draggable flex={1} draggableId={invoice.id.toString()}>
                        <DragInvoiceItem
                          even={index % 2 === 0}
                          label={`${invoice.name} - ${formatCurrency(amount)}`}
                        />
                      </Draggable>
                    </Stack>
                  );
                })}
              </Droppable>
            </Stack>
            <DragOverlay>
              {activeInvoice ? (
                <DragInvoiceItem opacity={0.6} label={activeInvoice.name} />
              ) : null}
            </DragOverlay>
          </DndContext>

          <Stack
            spacing={2}
            mt={2}
            mb={5}
            direction="row"
            justifyContent="flex-end"
          >
            <Button
              color="secondary"
              onClick={() => navigate(`/subscriptions/list/${id}`)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={submitLoading || loading}
              onClick={handleSubmit(onSubmit)}
            >
              {submitLoading ? 'Loading...' : 'Change vehicle'}
            </Button>
          </Stack>
        </Box>
      )}
    </form>
  );
}
