import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { useNavigate, useParams } from 'react-router-dom';

import { ArrowBack, ArrowForward, Note, Send } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { grey, yellow } from '@mui/material/colors';
import { useSnackbar } from 'notistack';

import MaskedInput from '@/components/form/MaskedInput';
import { currencyMask } from '@/constants/mask';
import { formatCurrency } from '@/helpers/currency';
import { formatDate } from '@/helpers/date';
import AppError from '@/interfaces/AppError';
import IInstallment, {
  IInstallmentStatus,
} from '@/interfaces/payments/IInstallment';
import { ChargeType } from '@/interfaces/payments/IInvoice';
import { PaymentType } from '@/interfaces/payments/IPayment';
import invoicesService from '@/services/invoices';
import paymentsService from '@/services/payments';

type InstallmentWithMeta = IInstallment & {
  order: number;
};

type InstallmentAmount = Record<string, { value: number; formatted: string }>;

const move = (
  source: InstallmentWithMeta[],
  destination: InstallmentWithMeta[],
  droppableSource: Required<DropResult>['source'],
  droppableDestination: Required<DropResult>['destination']
) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result: Record<string, InstallmentWithMeta[]> = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const sortInvoices = (a: InstallmentWithMeta, b: InstallmentWithMeta) => {
  if (a.invoice.type === ChargeType.ACTIVATION) {
    return -1;
  }
  if (b.invoice.type === ChargeType.ACTIVATION) {
    return 1;
  }
  if (a.invoice.type === ChargeType.FEE) {
    return -1;
  }
  if (b.invoice.type === ChargeType.FEE) {
    return 1;
  }
  if (
    a.invoice.type === ChargeType.MONTHLY &&
    b.invoice.type === ChargeType.OTHER
  ) {
    return -1;
  }
  if (
    a.invoice.type === ChargeType.OTHER &&
    b.invoice.type === ChargeType.MONTHLY
  ) {
    return 1;
  }
  return a.invoice.name.localeCompare(b.invoice.name);
};

export default function PaymentForm() {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [showAddNotes, setShowAddNotes] = useState(false);
  const { subscriptionId } = useParams();
  const navigate = useNavigate();

  const [installmentAmounts, setInstallmentAmounts] =
    useState<InstallmentAmount>({});

  const [installments, setInstallments] = useState<{
    list: InstallmentWithMeta[];
    pay: InstallmentWithMeta[];
  }>({
    list: [],
    pay: [],
  });

  const [paymentType, setPaymentType] = useState<PaymentType | ''>('');
  const [cardEnd, setCardEnd] = useState<string>('');
  const [notes, setNotes] = useState<string>('');

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    // dropped outside the lists
    if (!destination) {
      return;
    }
    const sInd = source.droppableId as keyof typeof installments;
    const dInd = destination.droppableId as keyof typeof installments;

    if (sInd === dInd) {
      return;
    }

    const res = move(
      installments[sInd],
      installments[dInd],
      source,
      destination
    );
    const newState = { ...installments };

    res[sInd].sort(sortInvoices);
    res[dInd].sort(sortInvoices);
    newState[sInd] = res[sInd];
    newState[dInd] = res[dInd];
    setInstallments(newState);
  };

  const moveManual = (index: number, onwards = true) => {
    const sInd = !onwards ? 'list' : 'pay';
    const dInd = !onwards ? 'pay' : 'list';

    // const invoice = installments[sInd][index];

    const res = move(
      installments[sInd],
      installments[dInd],
      { index, droppableId: sInd },
      { index, droppableId: dInd }
    );
    const newState = { ...installments };

    res[sInd].sort(sortInvoices);
    res[dInd].sort(sortInvoices);
    newState[sInd] = res[sInd];
    newState[dInd] = res[dInd];

    setInstallments(newState);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        if (!subscriptionId) {
          return;
        }
        const loadedInvoices = await invoicesService.listInvoices(
          parseInt(subscriptionId, 10)
        );
        if (!loadedInvoices.length) {
          return;
        }
        setInstallments({
          list: loadedInvoices.reduce<InstallmentWithMeta[]>((acc, invoice) => {
            acc.push(
              ...invoice.installments
                .filter(i => !i.paidAt)
                .map((i, index) => ({
                  ...i,
                  invoice,
                  order: index,
                }))
            );
            return acc;
          }, []),
          pay: [],
        });
      } catch (error) {
        enqueueSnackbar(
          error instanceof AppError
            ? error?.message
            : 'Error on loading invoices. Please, try again later',
          { variant: 'error' }
        );
      } finally {
        setLoading(false);
      }
    })();
  }, [enqueueSnackbar, subscriptionId]);

  useEffect(() => {
    setInstallmentAmounts(prev => {
      const newState: Record<string, { value: number; formatted: string }> = {};
      installments.pay.forEach(i => {
        const amount = prev[i.id];
        if (!amount || !amount.value) {
          newState[i.id] = {
            value: i.totalAmount,
            formatted: formatCurrency(i.totalAmount),
          };
        } else {
          newState[i.id] = { value: amount.value, formatted: amount.formatted };
        }
      });

      return newState;
    });
  }, [installments]);

  const renderDraggables = useCallback(
    (
      droppableId: string,
      content: InstallmentWithMeta[],
      showInput = false
    ) => {
      if (!content.length) {
        return (
          <Box p={1} ml={1}>
            <Typography variant="body1">No installments</Typography>
          </Box>
        );
      }
      return content.map((item, index) => {
        let remainingValue: number;
        const payValue = installmentAmounts[item.id] || {
          value: 0,
          formatted: '$ 0',
        };

        if (showInput) {
          remainingValue = item.totalAmount - payValue.value;
        }

        let dueDateColor: string | undefined;
        if (item.status === IInstallmentStatus.PAST_DUE) {
          dueDateColor = 'error';
        } else if (item.status === IInstallmentStatus.RESCHEDULED) {
          // eslint-disable-next-line prefer-destructuring
          dueDateColor = yellow[900];
        }

        return (
          <Draggable
            key={`${droppableId}-${item.id}`}
            draggableId={`${droppableId}-drag-${item.id}`}
            index={index}
          >
            {(prov, snps) => (
              <Box
                ref={prov.innerRef}
                p={1}
                ml={1}
                sx={{
                  backgroundColor: index % 2 ? grey[50] : grey[200],
                  opacity: snps.isDragging ? 0.7 : 1,
                }}
                {...prov.draggableProps}
                {...prov.dragHandleProps}
              >
                <Stack direction="row" alignItems="center">
                  {showInput && (
                    <IconButton onClick={() => moveManual(index)}>
                      <ArrowBack />
                    </IconButton>
                  )}
                  <Box flex={1}>
                    <Typography>
                      {item.invoice.name} - Installment #{item.order + 1}
                    </Typography>
                    <Typography variant="caption" color={dueDateColor}>
                      {item.status === IInstallmentStatus.RESCHEDULED
                        ? `Reschedule date: ${formatDate(item.rescheduleDate!)}`
                        : `Due date: ${formatDate(item.dueDate)}`}
                    </Typography>
                  </Box>
                  <Box>{formatCurrency(item.totalAmount)}</Box>
                  {!showInput && (
                    <IconButton onClick={() => moveManual(index, false)}>
                      <ArrowForward />
                    </IconButton>
                  )}
                </Stack>
                {showInput && (
                  <Stack direction="row" mt={1} gap={1} alignItems="center">
                    {remainingValue ? (
                      <Box flex={1}>
                        <Typography variant="body2">
                          Remaining: {formatCurrency(remainingValue)}
                        </Typography>
                      </Box>
                    ) : (
                      <Box flex={1} />
                    )}
                    <Box>
                      <TextField
                        size="small"
                        label="Amount to pay"
                        InputProps={{
                          inputComponent: MaskedInput as any,
                          inputProps: {
                            ...currencyMask({
                              max: item.totalAmount,
                              autofix: true,
                            }),
                          },
                        }}
                        value={payValue.formatted}
                        onChange={ev => {
                          setInstallmentAmounts(prevState => ({
                            ...prevState,
                            [item.id]: {
                              value:
                                parseFloat((ev.target as any).unmasked) || 0,
                              formatted: ev.target.value,
                            },
                          }));
                        }}
                      />
                    </Box>
                  </Stack>
                )}
              </Box>
            )}
          </Draggable>
        );
      });
    },
    [installmentAmounts, moveManual]
  );

  const renderDroppable = useCallback(
    (
      id: string,
      title: string,
      content: InstallmentWithMeta[],
      withInputs = false
    ) => (
      <>
        <Typography variant="h6" mb={2}>
          {title}
        </Typography>
        <Droppable droppableId={id}>
          {provided => (
            <Box
              ref={provided.innerRef}
              style={{ width: '100%', height: '100%' }}
              {...provided.droppableProps}
            >
              {renderDraggables(id, content, withInputs)}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </>
    ),
    [renderDraggables]
  );

  const total = useMemo(
    () =>
      Object.values(installmentAmounts).reduce(
        (acc, value) => acc + value.value,
        0
      ),
    [installmentAmounts]
  );

  if (loading) {
    return <>Loading...</>;
  }

  return (
    <Card elevation={3}>
      <CardContent>
        <DragDropContext onDragEnd={onDragEnd}>
          <Stack direction="row" gap={2}>
            <Box border={1} borderRadius={2} p={2} flex={1}>
              {renderDroppable(
                'list',
                'Available installments',
                installments.list
              )}
            </Box>
            <Box border={1} borderRadius={2} p={2} flex={1}>
              {renderDroppable(
                'pay',
                'Installments to pay',
                installments.pay,
                true
              )}
            </Box>
          </Stack>
        </DragDropContext>

        <Stack direction="row" justifyContent="flex-end" gap={2} my={2}>
          <Box flex={1} p={2} />
          <Box border={1} borderRadius={1} p={2} flex={1}>
            <Typography variant="body1" align="right">
              Total to pay
            </Typography>
            <Typography variant="h6" align="right">
              {formatCurrency(total)}
            </Typography>
          </Box>
        </Stack>
        <Stack direction="row" gap={2}>
          <Box flex={1}>
            <FormControl fullWidth>
              <InputLabel id="payment-type">Payment Type</InputLabel>
              <Select
                labelId="payment-type"
                value={paymentType}
                label="Payment Type"
                onChange={ev => setPaymentType(ev.target.value as PaymentType)}
              >
                {Object.entries(PaymentType).map(([key, value]) => (
                  <MenuItem key={key} value={value}>
                    {key}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box flex={1}>
            {paymentType === PaymentType.CARD && (
              <TextField
                fullWidth
                label="Card End"
                type="string"
                value={cardEnd}
                onChange={ev => setCardEnd(ev.target.value)}
                InputProps={{
                  inputComponent: MaskedInput as any,
                  inputProps: {
                    mask: '0000',
                  },
                }}
              />
            )}
          </Box>
        </Stack>
        <Box mt={2} width="50%" pr={1}>
          {!showAddNotes && paymentType !== PaymentType.OTHER && (
            <Button
              variant="outlined"
              color="info"
              startIcon={<Note />}
              disabled={loading}
              onClick={() => setShowAddNotes(true)}
            >
              Add Notes
            </Button>
          )}
          {(paymentType === PaymentType.OTHER || showAddNotes) && (
            <TextField
              label="Notes"
              multiline
              rows={3}
              fullWidth
              type="string"
              value={notes}
              onChange={ev => setNotes(ev.target.value)}
            />
          )}
        </Box>
        <Stack direction="row" gap={2} justifyContent="flex-end" mt={2}>
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<ArrowBack />}
            disabled={loading}
            onClick={() => {
              navigate(`/subscriptions/list/${subscriptionId}`);
            }}
          >
            Subscription Details
          </Button>
          <Button
            variant="contained"
            color="primary"
            endIcon={<Send />}
            disabled={
              !total ||
              !paymentType ||
              loading ||
              (paymentType === PaymentType.CARD &&
                (!cardEnd || cardEnd.length !== 4))
            }
            onClick={async () => {
              try {
                setLoading(true)
                await paymentsService.createPayment({
                  amount: total,
                  type: paymentType as PaymentType,
                  notes: notes || undefined,
                  installmentsAndAmounts: Object.entries(
                    installmentAmounts
                  ).reduce(
                    (acc, [key, value]) => ({ ...acc, [key]: value.value }),
                    {}
                  ),
                  cardEnd:
                    paymentType === PaymentType.CARD ? cardEnd : undefined,
                });
                navigate(`/subscriptions/list/${subscriptionId}`);
              } catch (error) {
                enqueueSnackbar(
                  error instanceof AppError
                    ? error?.message
                    : 'Error on registering payment. Please, try again later',
                  {
                    variant: 'error',
                  }
                );
              } finally{
                setLoading(false)
              }
            }}
          >
            Register Payment
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
}
