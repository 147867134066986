// eslint-disable-next-line import/no-cycle
import IInstallment from '@/interfaces/payments/IInstallment';

export enum PaymentGateway {
  MANUAL = 'manual',
}

export enum PaymentType {
  CARD = 'CARD',
  CASH = 'CASH',
  CHECK = 'CHECK',
  WIRE = 'WIRE',
  OTHER = 'OTHER',
}

export default interface IPayment {
  id: string;
  amount: number;
  type: PaymentType;
  cardEnd?: string;
  gatewayExternalPaymentId: string;
  gateway: PaymentGateway;
  installments: IInstallment[];
  readonly refundPaymentId: string;
  createdAt: Date;
  updatedAt: Date;
  notes?: string;
}


export interface IPaymentReport extends IPayment {
  subscriptionId: string;
}
