import ICreatePaymentDTO from '@/interfaces/payments/ICreatePaymentDTO';
import IPayment, { IPaymentReport } from '@/interfaces/payments/IPayment';
import ITaxes from '@/interfaces/payments/ITaxes';
import { http } from '@/services/http';

class PaymentsService {
  static BASE_PATH = 'payments';

  async listPaymentsReport(startDate: Date, endDate: Date | null): Promise<IPaymentReport[]> {
    if(!startDate) {
      return [];
    }
    let url = `/reports/${PaymentsService.BASE_PATH}/list-payments?startDate=${startDate.toISOString()}`;
    if(endDate) {
      url += `&endDate=${endDate.toISOString()}`;
    }
    const { data } = await http.get(url, {timeout: 50 * 1000});
    return data;
  }

  async listTaxesReport(startDate: Date, endDate: Date | null): Promise<ITaxes[]> {
    if(!startDate) {
      return [];
    }
    let url = `/reports/${PaymentsService.BASE_PATH}/list-taxes?startDate=${startDate.toISOString()}`;
    if(endDate) {
      url += `&endDate=${endDate.toISOString()}`;
    }
    const { data } = await http.get(url, {timeout: 50 * 1000});
    return data;
  }

  async createPayment(payment: ICreatePaymentDTO): Promise<IPayment> {
    const { data } = await http.post(`/${PaymentsService.BASE_PATH}`, payment);
    return data;
  }

  async refundPayment(paymentId: string): Promise<void> {
    await http.post(`/${PaymentsService.BASE_PATH}/${paymentId}/refund`, {});
  }
}

const paymentsService = new PaymentsService();

export default paymentsService;
