import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Box,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';

import CommentsContainer from '@/components/CommentsContainer';
import { useMeta } from '@/hooks/meta';
import AppError from '@/interfaces/AppError';
import {
  IMaintenance,
  IMaintenanceStatus,
} from '@/interfaces/maintenance/IMaintenance';
import maintenancesService from '@/services/maintenances';

import MaintenanceActions from './MaintenanceActions';
import MaintenanceDetails from './MaintenanceDetails';
import MaintenanceExpenses from './MaintenanceExpenses';
import MaintenanceTasks from './MaintenanceTasks';
import VehicleMaintenanceHistory from './VehicleMaintenanceHistory';

export default function MaintenanceView() {
  const { id } = useParams();
  const { setPageTitle } = useMeta();

  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const [maintenance, setMaintenance] = useState<IMaintenance>();

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        if (!id) {
          navigate('/maintenance/list');
          return;
        }
        const maintenanceFound = await maintenancesService.getMaintenance(id);
        if (!maintenanceFound) {
          navigate('/maintenance/list');
          return;
        }
        setMaintenance(maintenanceFound);
        setPageTitle(`Maintenance details: #${maintenanceFound.id}`);
      } catch (error) {
        console.error(error);
        enqueueSnackbar(
          error instanceof AppError
            ? error?.message
            : 'Error on loading maintenance. Please, try again later',
          { variant: 'error' }
        );
        navigate('/maintenance/list');
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <Box sx={{ mt: 1 }}>
      {loading ? (
        <Box>
          <Grid container direction="row" alignItems="center" spacing={4}>
            <Grid item>{loading && <CircularProgress size={32} />}</Grid>
          </Grid>
        </Box>
      ) : (
        maintenance && (
          <Grid container spacing={2} mb={1}>
            <Grid xs={16} sm={12} md={3} item>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h6">Details:</Typography>
                  <MaintenanceDetails maintenance={maintenance} />
                </CardContent>

                <CardActions>
                  <MaintenanceActions
                    id={maintenance.id}
                    status={maintenance.status}
                    onUpdate={updatedMaintenance =>
                      setMaintenance(updatedMaintenance)
                    }
                  />
                </CardActions>
              </Card>

              <Box sx={{ marginTop: 2 }}>
                <MaintenanceExpenses
                  maintenanceId={maintenance.id}
                  vehicleId={maintenance.vehicle.vin}
                  readOnly={maintenance.status !== IMaintenanceStatus.ACTIVE}
                />
              </Box>
            </Grid>
            <Grid xs sm md item>
              <Card variant="outlined">
                <CardContent>
                  <MaintenanceTasks
                    maintenanceId={maintenance.id}
                    readOnly={maintenance.status !== IMaintenanceStatus.ACTIVE}
                    canAddNewTask={
                      maintenance.status === IMaintenanceStatus.ACTIVE ||
                      maintenance.status === IMaintenanceStatus.PENDING
                    }
                  />
                  <CommentsContainer
                    moduleName="maintenances"
                    entityId={maintenance.id}
                    defaultExpanded
                  />
                  <VehicleMaintenanceHistory
                    vehicleId={maintenance.vehicle.vin}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )
      )}
    </Box>
  );
}
