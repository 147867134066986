import { useState } from 'react';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Delete, DragIndicator } from '@mui/icons-material';
import { Box, IconButton, ImageListItem, Stack, Tooltip } from '@mui/material';

type Props = {
  id: string;
  index: number;
  onClickDelete: () => void | Promise<void>;
  path: string;
  order: number;
  disableHover?: boolean;
};

export default function SortableImage({
  id,
  index,
  onClickDelete,
  path,
  order,
  disableHover = false,
}: Props) {
  const [isHovered, setIsHovered] = useState(false);
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <ImageListItem
      ref={setNodeRef}
      style={style}
      sx={{
        position: 'relative',
      }}
      {...attributes}
      cols={index === 0 ? 2 : 1}
      rows={index === 0 ? 2 : 1}
      onMouseOver={() => (disableHover ? null : setIsHovered(true))}
      onMouseLeave={() => (disableHover ? null : setIsHovered(false))}
    >
      <img src={path} alt={String(order)} loading="eager" />
      {isHovered && (
        <Stack
          sx={{
            position: 'absolute',
            top: '0.5rem',
            right: '0.5rem',
            left: '0.5rem',
          }}
          flexDirection="row"
          justifyContent="space-between"
        >
          <Stack
            {...listeners}
            sx={{
              borderRadius: '50%',
              opacity: 0.5,
              backgroundColor: 'white',
              px: 1,
              '&:hover': {
                cursor: 'grab',
                opacity: 1,
              },
            }}
            alignItems="center"
            justifyContent="center"
          >
            <Tooltip title="Reorder">
              <DragIndicator />
            </Tooltip>
          </Stack>
          <Box
            sx={{
              borderRadius: '50%',
              opacity: 0.5,
              backgroundColor: 'white',
              '&:hover': {
                opacity: 1,
              },
            }}
          >
            <Tooltip title="Delete image">
              <IconButton onClick={onClickDelete}>
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        </Stack>
      )}
    </ImageListItem>
  );
}
