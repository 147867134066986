import React, { useCallback, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  KnockFeedProvider,
  NotificationFeedPopover,
  NotificationIconButton,
} from "@knocklabs/react-notification-feed";

import "@knocklabs/react-notification-feed/dist/index.css";
import { AccountCircle, Logout } from '@mui/icons-material';
import {
  AppBar,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
  Toolbar,
  Tooltip,
  Typography
} from '@mui/material';

import AppBarContent from '@/components/AppContainer/AppBarContent';
import MobileDrawer from '@/components/AppContainer/MobileDrawer';
import { PageLink } from '@/components/AppContainer/types';
import Breadcrumbs from '@/components/Breadcrumbs';
import { MyAccount } from '@/components/MyAccount';
import { RequireAuth, useAuth } from '@/hooks/auth';

const isProd = import.meta.env.PROD;

type Props = React.PropsWithChildren<{
  pagesLinks: PageLink[];
  onClickLogout: () => void;
}>;

export default function AppContainer({
  pagesLinks,
  children,
  onClickLogout,
}: Props) {
  const [openLogoutDialog, setOpenLogoutDialog] = useState(false);
  const [openAboutDialog, setOpenAboutDialog] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(false);
  const notifButtonRef = useRef(null);
  const { user } = useAuth();

  const handleCloseLogoutDialog = useCallback(() => {
    setOpenLogoutDialog(false);
  }, []);

  if(!user) return <RequireAuth />;

  return (
  <KnockFeedProvider
    apiKey={isProd ? "pk_RZNTB4da00rWLOzjJ2fu0B6dFuBYEbnhLWleJzx7xIc" : "pk_test_5mUtXTjgiWuNCElF8S-eIAG5_5JL51OfAfk4z5XlDDQ"}
    feedId="1c2f38e1-274a-4782-97e7-058dfb8ccd6b"
    userId={user.id}>
    <div>
      <Grid container direction={{ xs: 'column' }} width="100%" height="100%">
        <Grid item xs>
          <AppBar position="static">
            <Container maxWidth={false}>
              <Toolbar disableGutters>
                <MobileDrawer pagesLinks={pagesLinks} />

                <Typography
                  variant="h6"
                  noWrap
                  component={Link}
                  sx={{
                    mr: 2,
                    display: { xs: 'none', md: 'flex' },
                    color: 'white',
                    textDecoration: 'none',
                  }}
                  to="/"
                >
                  DriveOn
                </Typography>

                <Typography
                  variant="h6"
                  noWrap
                  component={Link}
                  sx={{
                    flexGrow: 1,
                    display: { xs: 'flex', md: 'none' },
                    color: 'white',
                    textDecoration: 'none',
                  }}
                  to="/"
                >
                  DriveOn
                </Typography>

                <AppBarContent pagesLinks={pagesLinks} />

                <NotificationIconButton
                  ref={notifButtonRef}
                  onClick={() => setOpenNotifications(!openNotifications)}
                />

                <Tooltip title="My Account">
                  <IconButton
                    onClick={() => setOpenAboutDialog(true)}
                    sx={{ color: 'white' }}
                  >
                    <AccountCircle />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Logout">
                  <IconButton
                    onClick={() => setOpenLogoutDialog(true)}
                    sx={{ color: 'white' }}
                  >
                    <Logout />
                  </IconButton>
                </Tooltip>
              </Toolbar>
            </Container>
          </AppBar>
        </Grid>
        <Grid
          item
          xs={10}
          sx={{ pt: '2rem' }}
          component={Container}
          maxWidth="xl"
        >
          <Breadcrumbs sx={{ mb: '1rem' }} />
          {children}
        </Grid>
      </Grid>

      <NotificationFeedPopover
          buttonRef={notifButtonRef}
          isVisible={openNotifications}
          onClose={() => setOpenNotifications(false)}
        />
      <MyAccount
        open={openAboutDialog}
        onClose={() => {
          setOpenAboutDialog(false);
        }}
      />

      <Dialog
        open={openLogoutDialog}
        onClose={handleCloseLogoutDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to logout?
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseLogoutDialog} autoFocus>
            No, keep me here
          </Button>
          <Button onClick={onClickLogout}>Yes, logout</Button>
        </DialogActions>
      </Dialog>
    </div>
  </KnockFeedProvider>
  );
}
