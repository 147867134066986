import { useState } from 'react';

import { Box, Button, List, Stack, Typography } from '@mui/material';

import { Task } from '@/interfaces/maintenance/IMaintenanceTypeTask';

import TaskForm from '../TaskForm';
import TaskItem from '../TaskItem';


interface Props {
  tasks: Task[];
  errorMessage?: string;
  addTask: (task: Task) => void;
  removeTask: (taskId: string) => void;
  editTask: (task: Task) => void;
}

export default function TaskList(props: Props) {
  const { tasks, errorMessage, editTask, addTask, removeTask } = props;

  const [editingTaskId, setEditingTaskId] = useState<string>();

  const editingTask =
    typeof editingTaskId !== 'undefined'
      ? tasks.find(task => task.id === editingTaskId)
      : undefined;

  return (
    <>
      <TaskForm
        initialData={
          editingTask ? { description: editingTask.description } : undefined
        }
        open={typeof editingTaskId === 'string'}
        onClose={() => setEditingTaskId(undefined)}
        onSubmit={data => {
            if (editingTask) {
              editTask({ id: editingTask.id, ...data });
            } else {
              addTask({
                id: `dummy-${Math.random()}`,
                description: data.description,
              });
            }
        }}
      />

      <Box sx={{ marginTop: 2 }}>
        <Stack direction="row">
          <Typography variant="h6">Tasks</Typography>
          <Button
            sx={{ marginLeft: 'auto' }}
            variant="contained"
            onClick={() => setEditingTaskId('')}
          >
            New task
          </Button>
        </Stack>

        {errorMessage && (
          <Typography sx={{ color: theme => theme.palette.error.main }}>
            {errorMessage}
          </Typography>
        )}

        {tasks.length === 0 ? (
          <Typography>No tasks were found.</Typography>
        ) : (
          <List
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              width: '100%',
              bgcolor: 'background.paper',
            }}
          >
            {tasks.map(task => (
              <TaskItem
                key={`task-${task.id}`}
                description={task.description}
                onClickEdit={() => setEditingTaskId(task.id)}
                onClickRemove={() => removeTask(task.id)}
              />
            ))}
          </List>
        )}
      </Box>
    </>
  );
}
