import { IComment } from '@/interfaces/comments/IComment';
import ICreateCommentDTO from '@/interfaces/comments/ICreateCommentDTO';
import { http } from '@/services/http';

class CommentsService {
  static BASE_PATH = 'comments';

  async listComments(
    moduleName: string,
    entityId: string
  ): Promise<IComment[]> {
    const { data } = await http.get(
      `/${CommentsService.BASE_PATH}/${moduleName}/${entityId}`
    );
    return data;
  }

  async createComment({
    relatedEntityId,
    relatedModuleName,
    content,
  }: ICreateCommentDTO): Promise<IComment> {
    const { data } = await http.post(
      `/${CommentsService.BASE_PATH}/${relatedModuleName}/${relatedEntityId}`,
      { content }
    );
    return data;
  }

  async deleteComment(commentId: string): Promise<void> {
    await http.delete(`/${CommentsService.BASE_PATH}/${commentId}`);
  }
}

const commentsService = new CommentsService();

export default commentsService;
