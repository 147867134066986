import { IUser } from '@/interfaces/IUser';
import { http } from '@/services/http';

type AuthHandlers = {
  onLogin?: (user: IUser | null) => void;
  onLogout?: () => void;
};

class AuthService {
  user: IUser | null = null;

  handlers: AuthHandlers = {};

  static LOCAL_STORAGE_KEY = 'user';

  init(handlers?: AuthHandlers) {
    this.user = JSON.parse(
      localStorage.getItem(AuthService.LOCAL_STORAGE_KEY) || 'null'
    );
    if (handlers) {
      this.handlers = handlers;
    }
    if (this.user) {
      this.addInterceptors();
    }
  }

  private saveUser() {
    localStorage.setItem(
      AuthService.LOCAL_STORAGE_KEY,
      JSON.stringify(this.user)
    );
  }

  private addInterceptors() {
    http.interceptors.request.use(
      config => {
        if (!config.url?.endsWith('/sessions/refresh') && this.user) {
          if (!config.headers) {
            // eslint-disable-next-line no-param-reassign
            config.headers = {};
          }
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `Bearer ${this.user.auth.token}`;
        }
        return config;
      },
      error => Promise.reject(error)
    );
    http.interceptors.response.use(
      response => response,
      error => {
        if (this.user && error?.response?.status === 401) {
          const requestConfig = error.config;
          return (async () => {
            try {
              const response = await http.post('/sessions/refresh', {
                token: this.user?.auth.refreshToken,
              });
              if (this.user) {
                this.user.auth.token = response.data.token;
              }
              this.saveUser();
              return http(requestConfig);
            } catch (err) {
              console.error('failed to refresh token, logging out...');
              this.signOut();
              return Promise.reject(err);
            }
          })();
        }
        return Promise.reject(error);
      }
    );
  }

  async signIn(email: string, password: string) {
    const response = await http.post('/sessions', { email, password });
    this.user = {
      ...response.data.user,
      auth: {
        token: response.data.token,
        refreshToken: response.data.refreshToken,
      },
    };
    this.addInterceptors();
    this.saveUser();
    if (this.handlers.onLogin) {
      this.handlers.onLogin(this.user);
    }
    return response.data;
  }

  signOut() {
    this.user = null;
    localStorage.removeItem(AuthService.LOCAL_STORAGE_KEY);
    if (this.handlers.onLogout) {
      this.handlers.onLogout();
    }
  }
}

const authService = new AuthService();

export default authService;
