import ISubscriptionVehicle from "../subscriptions/ISubscriptionVehicle";
import IVehicle from "../vehicles/IVehicle";
import { IMaintenanceType } from "./IMaintenanceType";

export enum IMaintenanceStatus {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  FINISHED = 'FINISHED',
  CANCELED = 'CANCELED',
}

export interface IMaintenance {
  id: string;
  readonly status: IMaintenanceStatus;
  type: IMaintenanceType;
  vehicle: IVehicle;
  startVehicleMileage?: number;
  subscriptionVehicle?: ISubscriptionVehicle;
  finishVehicleMileage?: number;
  description?: string;
  startDate?: string;
  endDate?: string;
  expectedStartDate: string;
  expectedEndDate: string;
  canceledAt?: string;
  createdAt: string;
  updatedAt: string;
}
