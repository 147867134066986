import { useState } from 'react';

import { Button } from '@mui/material';

import ISubscription from '@/interfaces/subscriptions/ISubscription';

import RenewSubscriptionModal from '../RenewSubscriptionModal';

interface Props {
  subscription: ISubscription;
}

export default function RenewSubscription(props: Props) {
  const { subscription } = props;
  const [isOpen, setOpen] = useState<boolean>(false);

  return (
    <>
      {isOpen && (
        <RenewSubscriptionModal
          subscription={subscription}
          onClose={() => setOpen(false)}
        />
      )}
      <Button variant="outlined" onClick={() => setOpen(true)}>
        Renew Subscription
      </Button>
    </>
  );
}
