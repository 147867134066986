import { HealthAndSafety, Note, PictureAsPdf } from '@mui/icons-material';

import { isUUID } from '@/helpers/uuid';
import { IPageDetails } from '@/interfaces/IPageDetails';

export const SUBSCRIPTION_PAGES: IPageDetails = {
  title: 'Subscriptions',
  path: '/subscriptions',
  mainPagePath: '/subscriptions/list',
  icon: Note,
  menuItems: [
    {
      title: 'List',
      icon: Note,
      path: '/subscriptions/list',
    },
    {
      title: 'New Subscription',
      path: '/subscriptions/new',
      icon: () => null,
      showOnNavMenus: false,
    },
    {
      title: 'Simulate Subscription',
      path: '/subscriptions/simulate',
      icon: PictureAsPdf,
    },
    {
      title: 'Types',
      icon: HealthAndSafety,
      path: '/subscriptions/types',
    },
    {
      title: 'New Subscription Type',
      icon: () => null,
      showOnNavMenus: false,
      path: '/subscriptions/types/new',
    },
    {
      title: path => `Subscription Type: ${path}`,
      path: '/subscriptions/types/:id',
      icon: () => null,
      showOnNavMenus: false,
      match: (pathname: string) => {
        const paths = pathname.split('/').filter(p => !!p);
        const isSubscriptionTypeDetails =
          paths.length === 3 &&
          paths[0] === 'subscriptions' &&
          paths[1] === 'types' &&
          isUUID(paths[2]);

        return isSubscriptionTypeDetails;
      },
    },
    {
      title: path => `#${path}`,
      path: '/subscriptions/list/:id',
      icon: () => null,
      showOnNavMenus: false,
      match: (pathname: string) => {
        const paths = pathname.split('/').filter(p => !!p);
        const isSubscriptionDetails =
          paths.length === 3 &&
          paths[0] === 'subscriptions' &&
          paths[1] === 'list' &&
          !!paths[2] &&
          !!Number(paths[2]);

        return isSubscriptionDetails;
      },
    },
    {
      title: `Change Vehicle`,
      path: '/subscriptions/change-vehicle',
      icon: () => null,
      showOnNavMenus: false,
    },
    {
      title: 'Change vehicle',
      path: '/subscriptions/list/:id/change-vehicle',
      icon: () => null,
      showOnNavMenus: false,
      match: (pathname: string) => {
        const paths = pathname.split('/').filter(p => !!p);
        const isChangeVehicle =
          paths.length === 4 &&
          paths[0] === 'subscriptions' &&
          paths[1] === 'list' &&
          paths[3] === 'change-vehicle' &&
          !!paths[2] &&
          !!Number(paths[2]);

        return isChangeVehicle;
      },
    },
    {
      title: 'Invoices',
      path: '/subscriptions/list/:id/invoices',
      icon: () => null,
      showOnNavMenus: false,
      match: (pathname: string) => {
        const paths = pathname.split('/').filter(p => !!p);
        const isSubscriptionInvoices =
          paths.length === 4 &&
          paths[0] === 'subscriptions' &&
          paths[1] === 'list' &&
          !!paths[2] &&
          paths[3] === 'invoices';

        return isSubscriptionInvoices;
      },
    },
    // {
    //   title: 'Payments',
    //   icon: Payments,
    //   path: '/subscriptions/payments',
    // },
  ],
};
