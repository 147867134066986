import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Add, Edit } from '@mui/icons-material';
import { Button, CircularProgress, IconButton } from '@mui/material';
import MUIDataTable, { MUIDataTableColumn } from 'mui-datatables';

import { formatCurrency } from '@/helpers/currency';
import { formatDecimalAmount } from '@/helpers/number';
import { useAuthorization } from '@/hooks/auth';
import ISubscriptionType from '@/interfaces/subscriptions/ISubscriptionType';
import { UserRoles } from '@/interfaces/system-users/UserRoles';
import subscriptionsService from '@/services/subscriptions';

function EditButton({ subscriptionTypeId }: { subscriptionTypeId: number }) {
  const navigate = useNavigate();
  const handleClick = useCallback(() => {
    navigate(`/subscriptions/types/${subscriptionTypeId}`);
  }, [navigate, subscriptionTypeId]);

  const { checkUserHasRole } = useAuthorization();

  return (
    <IconButton
      onClick={handleClick}
      disabled={!checkUserHasRole([UserRoles.ADMIN])}
    >
      <Edit />
    </IconButton>
  );
}

const columns: MUIDataTableColumn[] = [
  {
    name: 'id',
    label: 'ID',
    options: {
      filter: false,
      sort: false,
      display: false,
      print: false,
      download: false,
      viewColumns: false,
    },
  },
  {
    name: 'displayName',
    label: 'Display Name',
    options: {
      filter: true,
      filterType: 'textField',
      sort: true,
    },
  },
  {
    name: 'descriptiveName',
    label: 'Descriptive Name',
    options: {
      filter: true,
      filterType: 'textField',
      sort: true,
    },
  },
  {
    name: 'activationBaseValue',
    label: 'Activation Base',
    options: {
      filter: true,
      filterType: 'textField',
      sort: true,
      customBodyRender: (value: number) => formatCurrency(value),
    },
  },
  {
    name: 'activationMultiplierValue',
    label: 'Activation Multiplier',
    options: {
      filter: true,
      filterType: 'textField',
      sort: true,
      customBodyRender: (value: number) => `${formatDecimalAmount(value, 5)}x`,
    },
  },
  {
    name: 'monthlyBaseValue',
    label: 'Monthly Base',
    options: {
      filter: true,
      filterType: 'textField',
      sort: true,
      customBodyRender: (value: number) => formatCurrency(value),
    },
  },
  {
    name: 'monthlyMultiplierValue',
    label: 'Monthly Multiplier',
    options: {
      filter: true,
      filterType: 'textField',
      sort: true,
      customBodyRender: (value: number) => `${formatDecimalAmount(value, 5)}x`,
    },
  },
  {
    name: 'renewDiscountPercentage',
    label: 'Renew Discount',
    options: {
      filter: true,
      filterType: 'textField',
      sort: true,
      customBodyRender: (value: number) => `${value * 100}%`,
    },
  },
  {
    name: 'Actions',
    options: {
      filter: false,
      sort: false,
      print: false,
      download: false,
      empty: true,
      customBodyRender: (value, tableMeta) => (
        <EditButton subscriptionTypeId={tableMeta.rowData[0]} />
      ),
    },
  },
];

export default function ListSubscriptionsTypes() {
  const navigate = useNavigate();
  const { checkUserHasRole } = useAuthorization();

  const [subscriptionsTypesList, setSubscriptionsTypesList] = useState<
    ISubscriptionType[]
  >([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const subscriptionsTypes =
          await subscriptionsService.listSubscriptionTypes();
        setSubscriptionsTypesList(subscriptionsTypes);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const addButton = useCallback(
    () => (
      <Button
        onClick={() => {
          navigate('/subscriptions/types/new');
        }}
        variant="outlined"
        disabled={!checkUserHasRole([UserRoles.ADMIN])}
        startIcon={<Add />}
      >
        New subscription type
      </Button>
    ),
    [checkUserHasRole]
  );

  return (
    <MUIDataTable
      title="Subscriptions Types"
      data={subscriptionsTypesList}
      columns={columns}
      options={{
        rowHover: true,
        selectableRows: 'none',
        customToolbar: addButton,
        textLabels: {
          body: {
            noMatch: loading ? (
              <CircularProgress />
            ) : (
              'Sorry, no matching records found'
            ),
          },
        },
      }}
    />
  );
}
