import { PAGES } from '@/constants/pages';
import {
  IMenuItemDetails,
  IPageDetails,
  IRouteDetail,
} from '@/interfaces/IPageDetails';

function getRouteMatchFunction(page: IRouteDetail) {
  if (page.match) {
    return page.match;
  }

  return (pathname: string) => pathname === page.path;
}

export function getPageTitle(pathname: string, fallback = '') {
  const page = PAGES.reduce<IPageDetails | IMenuItemDetails | null>(
    (acc, pageIt) => {
      // to be optimized
      if (acc) return acc;

      const checkIfPathnameMatches = getRouteMatchFunction(pageIt);

      if (checkIfPathnameMatches(pathname)) {
        return pageIt;
      }

      return (
        pageIt.menuItems.find(menuItem =>
          getRouteMatchFunction(menuItem)(pathname)
        ) || null
      );
    },
    null
  );

  if (page) {
    return typeof page.title === 'function' ? page.title(fallback) : page.title;
  }
  return fallback;
}

export function filterRoutesToShow(routes: IPageDetails[]): IPageDetails[] {
  return routes
    .map(route => ({
      ...route,
      menuItems: route.menuItems.filter(
        menuItem =>
          menuItem.showOnNavMenus === undefined || menuItem.showOnNavMenus
      ),
    }))
    .filter(route => route.menuItems.length > 0);
}
