const Formatter = new Intl.DateTimeFormat('en-US');
const WithTimeFormatter = new Intl.DateTimeFormat('en-US', {
  dateStyle: 'short',
  timeStyle: 'short',
});

export function formatDate(date: Date): string {
  return Formatter.format(new Date(date));
}

export function formatDateWithTime(date: Date): string {
  return WithTimeFormatter.format(new Date(date));
}
