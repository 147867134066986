import { useNavigate } from 'react-router-dom';

import { Grid } from '@mui/material';

import PageCard from '@/components/PageCard';
import { PAGES } from '@/constants/pages';
import { useAuthorization } from '@/hooks/auth';

export default function Home() {
  const { checkUserHasRole } = useAuthorization();
  const navigate = useNavigate();

  return (
    <Grid
      container
      spacing={2}
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      {PAGES.map(({ icon: Icon, path, title, roles }) => {
          if (
            (roles && !checkUserHasRole(roles))
          ) {
            return null;
          }
        return (
          <Grid key={path} item onClick={() => navigate(path)}>
            <PageCard path={path} title={title} CardIcon={Icon} />
          </Grid>
        )
      })}
    </Grid>
  );
}
