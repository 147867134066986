import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Add, Edit, Visibility } from '@mui/icons-material';
import { Button, CircularProgress, IconButton } from '@mui/material';
import MUIDataTable, { MUIDataTableColumn } from 'mui-datatables';

import { ICustomer } from '@/interfaces/customers/ICustomer';
import customersService from '@/services/customers';

function EditButton({ customerId }: { customerId: string }) {
  const navigate = useNavigate();
  const handleClick = useCallback(() => {
    navigate(`/customers/list/${customerId}?edit=true`);
  }, [navigate, customerId]);

  return (
    <IconButton onClick={handleClick}>
      <Edit />
    </IconButton>
  );
}

function ViewButton({ customerId }: { customerId: string }) {
  const navigate = useNavigate();
  const handleClick = useCallback(() => {
    navigate(`/customers/list/${customerId}`);
  }, [navigate, customerId]);

  return (
    <IconButton onClick={handleClick}>
      <Visibility />
    </IconButton>
  );
}

export default function ListCustomers() {
  const navigate = useNavigate();

  const [customersList, setCustomersList] = useState<ICustomer[]>([]);
  const [loading, setLoading] = useState(true);

  const columns: MUIDataTableColumn[] = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        sort: false,
        display: false,
        print: false,
        download: false,
        viewColumns: false,
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        filterType: 'textField',
        sort: true,
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: true,
        filterType: 'textField',
        sort: true,
      },
    },
    {
      name: 'phoneNumber',
      label: 'Phone Number',
      options: {
        filter: true,
        filterType: 'textField',
      },
    },
    {
      name: 'Actions',
      options: {
        filter: false,
        sort: false,
        print: false,
        viewColumns: false,
        download: false,
        // eslint-disable-next-line react/no-unstable-nested-components
        customBodyRenderLite: (index: number) => (
          <>
            <ViewButton customerId={customersList[index].id} />
            <EditButton customerId={customersList[index].id} />
          </>
        ),
      },
    },
  ];

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const customers = await customersService.listCustomers();
        setCustomersList(customers);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const addButton = useCallback(
    () => (
      <Button
        onClick={() => {
          navigate('/customers/new');
        }}
        variant="outlined"
        startIcon={<Add />}
      >
        New customer
      </Button>
    ),
    []
  );

  return (
    <MUIDataTable
      title="Customers"
      data={customersList}
      columns={columns}
      options={{
        searchAlwaysOpen: true,
        rowHover: true,
        selectableRows: 'none',
        customToolbar: addButton,
        textLabels: {
          body: {
            noMatch: loading ? (
              <CircularProgress />
            ) : (
              'Sorry, no matching records found'
            ),
          },
        },
      }}
    />
  );
}
