import { IMaintenanceStatus } from '@/interfaces/maintenance/IMaintenance';

export const MAINTENANCE_STATUS: {
  [key in IMaintenanceStatus]: { color: string };
} = {
  ACTIVE: {
    color: '#757DE8',
  },
  PENDING: {
    color: '#A9A9A9',
  },
  FINISHED: {
    color: '#729913',
  },
  CANCELED: {
    color: '#d32f2f',
  },
};
