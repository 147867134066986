import { useCallback, useRef } from 'react';

import { Scheduler } from '@aldabil/react-scheduler';
import { ProcessedEvent, SchedulerRef } from '@aldabil/react-scheduler/types';
import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';

import { convertMaintenanceToProcessedEvent } from '@/helpers/maintenances';
import AppError from '@/interfaces/AppError';
import { IMaintenance, IMaintenanceStatus } from '@/interfaces/maintenance/IMaintenance';
import maintenancesService from '@/services/maintenances';

import MaintenanceForm from '../Form';
import MaintenanceSchedulerDetails from '../MaintenanceSchedulerDetails';

export default function MaintenanceScheduler() {
  const schedulerRef = useRef<SchedulerRef>(null);

  const { enqueueSnackbar } = useSnackbar();

  const getRemoteEvents = async (): Promise<ProcessedEvent[]> => {
    const maintenanceListResponse =
      await maintenancesService.listMaintenances();

    return maintenanceListResponse.map(maintenance =>
      convertMaintenanceToProcessedEvent(maintenance)
    );
  };

  const updateMaintenanceOnDrop = useCallback(
    async (
      droppedOn: Date,
      updatedEvent: ProcessedEvent,
      originalEvent: ProcessedEvent
    ) => {
      const maintenance = originalEvent?.maintenance as
        | IMaintenance
        | undefined;

      if (!maintenance || maintenance.status !== IMaintenanceStatus.PENDING) {
        return undefined;
      }

      try {
        const appointmentDurationInSeconds =
          (new Date(maintenance.expectedEndDate).getTime() -
            new Date(maintenance.expectedStartDate).getTime()) /
          1000;

        const updatedMaintenance = await maintenancesService.editMaintenance({
          maintenanceId: maintenance.id,
          maintenanceTypeId: maintenance.type.id,
          startDate: droppedOn,
          vehicleId: maintenance.vehicle.vin,
          duration: appointmentDurationInSeconds,
        });

        return {
          ...updatedEvent,
          maintenance: updatedMaintenance,
        };
      } catch (error) {
        enqueueSnackbar(
          error instanceof AppError
            ? error?.message
            : `Error updating maintenance. Please, try again later`,
          { variant: 'error' }
        );
        return undefined;
      }
    },
    [enqueueSnackbar]
  );

  return (
    <Scheduler
      ref={schedulerRef}
      view="week"
      customEditor={scheduler => <MaintenanceForm scheduler={scheduler} />}
      day={{
        startHour: 7,
        endHour: 20,
        step: 60,
      }}
      onEventDrop={updateMaintenanceOnDrop}
      month={{
        weekDays: [0, 1, 2, 3, 4, 5],
        weekStartOn: 1,
        startHour: 7,
        endHour: 20,
        navigation: true,
        disableGoToDay: false,
        cellRenderer: ({ ...rest }) => (
          <Button
            {...rest}
            onClick={() => {
              const { current } = schedulerRef;

              if (current) {
                current.scheduler.handleState('day', 'view');
              }
            }}
          />
        ),
      }}
      week={{
        weekDays: [0, 1, 2, 3, 4, 5],
        weekStartOn: 1,
        startHour: 7,
        endHour: 20,
        step: 60,
        navigation: true,
        disableGoToDay: false,
      }}
      viewerExtraComponent={(_, event) => {
        const maintenance = event.maintenance as IMaintenance;

        return (
          <MaintenanceSchedulerDetails
            maintenanceId={maintenance.id}
            description={maintenance.description}
            vehicleVin={maintenance.vehicle.vin}
          />
        );
      }}
      getRemoteEvents={getRemoteEvents}
    />
  );
}
