import IChangeSubscriptionVehicleDTO from '@/interfaces/subscriptions/IChangeSubscriptionVehicleDTO';
import { IChangeSubscriptionWarningsDTO } from '@/interfaces/subscriptions/IChangeSubscriptionWarningsDTO';
import { ICreateSubscriptionContractDTO } from '@/interfaces/subscriptions/ICreateSubscriptionContractDTO';
import ICreateSubscriptionDTO from '@/interfaces/subscriptions/ICreateSubscriptionDTO';
import ICreateSubscriptionTypeDTO from '@/interfaces/subscriptions/ICreateSubscriptionTypeDTO';
import IEditSubscriptionTypeDTO from '@/interfaces/subscriptions/IEditSubscriptionTypeDTO';
import IFinishSubscriptionDTO from '@/interfaces/subscriptions/IFinishSubscriptionDTO';
import IGetSubscriptionPriceDTO from '@/interfaces/subscriptions/IGetSubscriptionPriceDTO';
import IGetSubscriptionPriceResult from '@/interfaces/subscriptions/IGetSubscriptionPriceResult';
import IGetSubscriptionSimulationDTO from '@/interfaces/subscriptions/IGetSubscriptionSimulationDTO';
import IListNextDueInstallmentResponseDTO from '@/interfaces/subscriptions/IListNextDueInstallmentResponseDTO';
import { IRenewSubscriptionDTO } from '@/interfaces/subscriptions/IRenewSubscriptionDTO';
import ISubscription from '@/interfaces/subscriptions/ISubscription';
import ISubscriptionFile from '@/interfaces/subscriptions/ISubscriptionFile';
import ISubscriptionType from '@/interfaces/subscriptions/ISubscriptionType';
import { http } from '@/services/http';

class SubscriptionsService {
  static BASE_PATH = 'subscriptions';

  async listSubscriptions(): Promise<ISubscription[]> {
    const { data } = await http.get(`/${SubscriptionsService.BASE_PATH}`);
    return data;
  }

  async listNextDueInstallments(): Promise<IListNextDueInstallmentResponseDTO> {
    const { data } = await http.get(
      `/${SubscriptionsService.BASE_PATH}/next-due`
    );
    return data;
  }

  async listSubscriptionTypes(): Promise<ISubscriptionType[]> {
    const { data } = await http.get(`/${SubscriptionsService.BASE_PATH}/types`);
    return data;
  }

  async listSubscriptionFiles(
    subscriptionId: number
  ): Promise<ISubscriptionFile[]> {
    const { data } = await http.get(
      `/${SubscriptionsService.BASE_PATH}/${subscriptionId}/files`
    );
    return data;
  }

  async uploadSubscriptionFiles(
    subscriptionId: number,
    files: File[]
  ): Promise<void> {
    const formData = new FormData();
    files.forEach(file => {
      formData.append('subscriptionFiles', file);
    });
    await http.post(
      `/${SubscriptionsService.BASE_PATH}/${subscriptionId}/files`,
      formData
    );
  }

  async deleteSubscriptionFile(
    subscriptionId: number,
    fileId: string
  ): Promise<void> {
    await http.delete(
      `/${SubscriptionsService.BASE_PATH}/${subscriptionId}/files/${fileId}`
    );
  }

  async createSubscription(
    subscriptionData: ICreateSubscriptionDTO
  ): Promise<ISubscription> {
    const { data } = await http.post(
      `/${SubscriptionsService.BASE_PATH}`,
      subscriptionData
    );

    console.log(JSON.stringify(data));
    return data;
  }

  async createSubscriptionType(
    subscriptionTypeData: ICreateSubscriptionTypeDTO
  ): Promise<ISubscriptionType> {
    const { data } = await http.post(
      `/${SubscriptionsService.BASE_PATH}/types`,
      subscriptionTypeData
    );
    return data;
  }

  async updateSubscriptionType(
    subscriptionTypeId: string,
    subscriptionTypeData: IEditSubscriptionTypeDTO
  ): Promise<ISubscriptionType> {
    const { data } = await http.put(
      `/${SubscriptionsService.BASE_PATH}/types/${subscriptionTypeId}`,
      subscriptionTypeData
    );
    return data;
  }

  async getSubscription(id: number): Promise<ISubscription> {
    const { data } = await http.get(`/${SubscriptionsService.BASE_PATH}/${id}`);
    return data;
  }

  async getSubscriptionType(id: string): Promise<ISubscriptionType> {
    const { data } = await http.get(
      `/${SubscriptionsService.BASE_PATH}/types/${id}`
    );
    return data;
  }

  async changeSubscriptionVehicle(
    id: number,
    subscriptionData: IChangeSubscriptionVehicleDTO
  ): Promise<ISubscription> {
    const { data } = await http.post(
      `/${SubscriptionsService.BASE_PATH}/${id}/change-vehicle`,
      subscriptionData
    );
    return data;
  }

  async finishSubscription(
    id: number,
    subscriptionData: IFinishSubscriptionDTO
  ): Promise<ISubscription> {
    const { data } = await http.post(
      `/${SubscriptionsService.BASE_PATH}/${id}/finish`,
      subscriptionData
    );
    return data;
  }

  async reopenSubscription(id: number): Promise<ISubscription> {
    const { data } = await http.put(
      `/${SubscriptionsService.BASE_PATH}/${id}/reopen`
    );
    return data;
  }

  async generateSimulation(
    info: IGetSubscriptionSimulationDTO
  ): Promise<string> {
    const { data } = await http.post(
      `/${SubscriptionsService.BASE_PATH}/simulation/`,
      info,
      {
        responseType: 'arraybuffer',
      }
    );
    return data;
  }

  async generateContract({
    subscriptionId,
    ...info
  }: ICreateSubscriptionContractDTO): Promise<string> {
    const { data } = await http.post(
      `/${SubscriptionsService.BASE_PATH}/${subscriptionId}/contract`,
      info,
      {
        responseType: 'arraybuffer',
      }
    );
    return data;
  }

  async getPrice(
    info: IGetSubscriptionPriceDTO
  ): Promise<IGetSubscriptionPriceResult> {
    const { data } = await http.get(
      `/${SubscriptionsService.BASE_PATH}/price/`,
      {
        params: info,
      }
    );
    return data;
  }

  async changeSubscriptionWarnings({
    subscriptionId,
    warnings,
  }: IChangeSubscriptionWarningsDTO): Promise<void> {
    await http.patch(
      `/${SubscriptionsService.BASE_PATH}/${subscriptionId}/warnings`,
      {
        warnings,
      }
    );
  }

  async renewSubscription(params: IRenewSubscriptionDTO): Promise<ISubscription> {
    const { subscriptionId, ...rest }  = params;
    const { data } = await http.post(`/${SubscriptionsService.BASE_PATH}/${subscriptionId}/renew`, rest);
    return data
  }
}

const subscriptionsService = new SubscriptionsService();

export default subscriptionsService;
