import * as React from 'react';

import TextField from '@mui/material/TextField';
import {
  DatePicker as MUIDatePicker,
  DatePickerProps,
} from '@mui/x-date-pickers/DatePicker';

export default function DatePicker(
  props: Pick<DatePickerProps<Date>, 'onChange' | 'value'> &
    Partial<DatePickerProps<Date>>
) {
  return (
    <MUIDatePicker
      allowSameDateSelection
      disableFuture
      openTo="month"
      views={['year', 'month', 'day']}
      {...props}
      renderInput={params => <TextField {...params} />}
    />
  );
}
