import { CircularProgress, Grid, Typography } from '@mui/material';

export default function FullPageLoading() {
  return (
    <Grid
      container
      direction="column"
      sx={{
        w: '100vw',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress sx={{ mb: '1rem' }} />
      <Typography variant="h6">Loading...</Typography>
    </Grid>
  );
}
