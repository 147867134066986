import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

import { useConnectedFormComponents } from '@/components/form';
import { numericMask } from '@/constants/mask';
import AppError from '@/interfaces/AppError';
import ISubscription, {
  EndSubscriptionReason,
} from '@/interfaces/subscriptions/ISubscription';
import subscriptionsService from '@/services/subscriptions';

type Form = {
  endReason: EndSubscriptionReason;
  endReasonDescription: string;
  endDate?: string;
  vehicleMileage: {
    unmasked: string;
  };
};

const schema = Yup.object().shape({
  endReason: Yup.string(),
  endReasonDescription: Yup.string(),
  endDate: Yup.date(),
  vehicleMileage: Yup.object()
    .shape({
      unmasked: Yup.number().typeError('Vehicle mileage is required'),
    })
    .nullable()
    .required('Vehicle mileage is required')
    .typeError('Vehicle mileage is required'),
});

type Props = {
  open: boolean;
  onClose: () => void;
  onSubmitSuccess: () => void;
  subscription: ISubscription;
};

export default function FinishSubscription({
  subscription,
  open,
  onClose,
  onSubmitSuccess,
}: Props) {
  const theme = useTheme();
  const fullScreenDialog = useMediaQuery(theme.breakpoints.down('md'));
  const [submitLoading, setSubmitLoading] = useState(false);

  const { handleSubmit, control } = useForm<Form>({
    resolver: yupResolver(schema),
  });
  const { TextInput, AutoComplete, DatePicker } =
    useConnectedFormComponents<Form>({
      control,
    });
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async (data: Form) => {
    try {
      setSubmitLoading(true);
      await subscriptionsService.finishSubscription(subscription.id, {
        endReason: data.endReason || undefined,
        endReasonDescription: data.endReasonDescription || undefined,
        endDate: data.endDate ? new Date(data.endDate) : undefined,
        vehicleMileage: Number(data.vehicleMileage.unmasked),
      });
      onSubmitSuccess();
    } catch (error) {
      console.error(error);
      enqueueSnackbar(
        error instanceof AppError
          ? error?.message
          : 'Error on finishing subscription. Please, try again later',
        { variant: 'error' }
      );
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Dialog open={open} onClose={onClose} fullScreen={fullScreenDialog}
        PaperProps={{
          style: {
            height: '100%',
          },
        }}
      >
        <DialogTitle>Finish Subscription</DialogTitle>
        <DialogContent>
          <AutoComplete
            options={Object.values(EndSubscriptionReason)}
            fieldName="endReason"
            label="End reason"
            textFieldProps={{
              required: false,
            }}
          />
          <AutoComplete
          freeSolo
            options={
              ["VOLUNTARY",
              "NO PAYMENT",
              "CHANGE CITY",
              "BUY A CAR",
              "PRICE",
              "LOST JOB",
              "RENEW",
              "ACCIDENT",
              "TOTAL LOSS",
              "REPO",
              "INSURANCE",
              "MILES",
              "NO COMUNICATION",
              ]
            }
            label="End reason description"
            fullWidth
            fieldName="endReasonDescription"

          />
          <DatePicker
            fieldName="endDate"
            label="End Date"
            textFieldProps={{
              required: false,
              fullWidth: true,
            }}
            minDate={new Date(subscription.startDate)}
            maxDate={new Date()}
          />
          <TextInput
            margin="normal"
            required
            label="Vehicle mileage"
            fullWidth
            fieldName="vehicleMileage"
            maskProps={numericMask(0)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button disabled={submitLoading} onClick={handleSubmit(onSubmit)}>
            {submitLoading ? 'Loading...' : 'Finish subscription'}
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
}
