import { RouteProps } from 'react-router-dom';

import { IUser } from '@/interfaces/IUser';
import { ISystemUser } from '@/interfaces/system-users/ISystemUser';
import { UserRoles } from '@/interfaces/system-users/UserRoles';

export function userHasRole(
  user: IUser | ISystemUser,
  roles: UserRoles[]
): boolean {
  return user.roles.some(role => roles.indexOf(role) >= 0);
}

export type RouteWithRoles = RouteProps & { roles?: UserRoles[] };

export function filterRoutesByRole(
  routes: RouteWithRoles[],
  roles: UserRoles[]
): RouteWithRoles[] {
  return routes.filter(
    route =>
      !route.roles ||
      route.roles.some(routeRole => roles.indexOf(routeRole) >= 0)
  );
}
