import {
  Checkbox,
  FormControl,
  FormControlLabel,
} from '@mui/material';

interface Props {
  description: string;
  checked?: boolean;
  readOnly?: boolean;
  onChange: (checked: boolean) => void;
}

export default function MaintenanceTask({
  description,
  checked,
  readOnly,
  onChange,
}: Props) {
  return (
    <FormControl>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={(_, isChecked) => {
              if (readOnly) {
                return;
              }

              onChange(isChecked);
            }}
          />
        }
        label={description}
      />
    </FormControl>
  );
}
