import { useNavigate } from 'react-router-dom';

import { Delete, Edit, Engineering } from '@mui/icons-material';
import {
  IconButton,
  ListItem,
  ListItemText,
  Stack,
  Tooltip,
} from '@mui/material';

import { formatCurrency } from '@/helpers/currency';

interface Props {
  amount: number;
  categoryName: string;
  categoryDescription?: string;
  createdDate: string;
  maintenanceId?: string;
  onClickEdit?: () => void;
  onClickRemove?: () => void;
}

export default function ExpenseItem({
  amount,
  categoryName,
  categoryDescription,
  createdDate,
  maintenanceId,
  onClickEdit,
  onClickRemove,
}: Props) {
  const hasAnyAction = !!onClickEdit || !!onClickRemove;
  const navigate = useNavigate();

  return (
    <ListItem
      sx={{
        borderRadius: 1,
        border: '1px solid #e0e0e0',

        '&:hover': {
          backgroundColor: '#f5f5f5',
        },
      }}
      secondaryAction={
        hasAnyAction ? (
          <Stack direction="row" gap={1}>
            {maintenanceId && (
              <Tooltip title="Maintenance">
                <IconButton
                  edge="end"
                  onClick={() => navigate(`/maintenance/list/${maintenanceId}`)}
                >
                  <Engineering />
                </IconButton>
              </Tooltip>
            )}
            {onClickEdit && (
              <Tooltip title="Edit expense">
                <IconButton edge="end" onClick={onClickEdit}>
                  <Edit />
                </IconButton>
              </Tooltip>
            )}
            {onClickRemove && (
              <Tooltip title="Delete expense" onClick={onClickRemove}>
                <IconButton edge="end">
                  <Delete />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        ) : undefined
      }
    >
      <ListItemText
        primary={`${categoryName} (${formatCurrency(amount)})`}
        secondary={categoryDescription}
      />
      <ListItemText
        primary={createdDate}
        secondary={maintenanceId && 'From maintenance'}
      />
    </ListItem>
  );
}
