import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import * as Sentry from "@sentry/react";

import AppRoutes from './app-routes';
import AuthRoutes from './auth-routes';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

function MainRoutes() {
  return (
    <BrowserRouter>
      <SentryRoutes>
        {/* need to call nested routes as functions because RRD does not accept different components as children */}
        {AuthRoutes()}
        {AppRoutes()}
        <Route path="*" element={<Navigate to="/" />} />
      </SentryRoutes>
    </BrowserRouter>
  );
}

export default MainRoutes;
