import { useCallback, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { Add } from '@mui/icons-material';
import { Button, CircularProgress, Link } from '@mui/material';
import MUIDataTable, { MUIDataTableColumn } from 'mui-datatables';

import { useAuthorization } from '@/hooks/auth';
import { UserRoles } from '@/interfaces/system-users/UserRoles';
import { IVehicleGPS } from '@/interfaces/vehicles/IVehicleGPS';
import vehiclesService from '@/services/vehicles';

const columns: MUIDataTableColumn[] = [
  {
    name: 'id',
    label: 'ID',
    options: {
      filter: false,
      sort: false,
      display: false,
      print: false,
      download: false,
      viewColumns: false,
    },
  },
  {
    name: 'number',
    label: 'GPS Number',
    options: {
      filter: true,
      filterType: 'textField',
      sort: true,
    },
  },
  {
    name: 'vehicle',
    label: 'Vehicle',
    options: {
      filter: true,
      filterType: 'checkbox',
      sort: true,
      customBodyRender: value =>
        value ? (
          <Link component={RouterLink} to={`/vehicles/inventory/${value.vin}`}>
            {value.vin}
          </Link>
        ) : (
          'None'
        ),
    },
  },
];

export default function ListVehicleGPS() {
  const navigate = useNavigate();
  const { checkUserHasRole } = useAuthorization();

  const [gpsList, setGpsList] = useState<IVehicleGPS[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const gps = await vehiclesService.listGps();
        setGpsList(gps);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const addButton = useCallback(
    () => (
      <Button
        onClick={() => {
          navigate('/vehicles/gps/new');
        }}
        disabled={!checkUserHasRole([UserRoles.ADMIN])}
        variant="outlined"
        startIcon={<Add />}
      >
        New GPS
      </Button>
    ),
    []
  );

  return (
    <MUIDataTable
      title="Vehicles GPS"
      data={gpsList}
      columns={columns}
      options={{
        rowHover: true,
        selectableRows: 'none',
        customToolbar: addButton,
        textLabels: {
          body: {
            noMatch: loading ? (
              <CircularProgress />
            ) : (
              'Sorry, no matching records found'
            ),
          },
        },
      }}
    />
  );
}
