import ICreateInvoiceDTO from '@/interfaces/payments/ICreateInvoiceDTO';
import IEditInvoiceDTO from '@/interfaces/payments/IEditInvoiceDTO';
import IInvoice from '@/interfaces/payments/IInvoice';
import { http } from '@/services/http';

class InvoicesService {
  static BASE_PATH = 'invoices';

  async listInvoices(subscriptionId: number): Promise<IInvoice[]> {
    const { data } = await http.get(
      `/${InvoicesService.BASE_PATH}/${subscriptionId}`
    );
    return data;
  }

  async listInvoicesReport(startDate: Date, endDate: Date | null): Promise<IInvoice[]> {
    if(!startDate) {
      return [];
    }
    let url = `/reports/${InvoicesService.BASE_PATH}/list-invoices?startDate=${startDate.toISOString()}`;
    if(endDate) {
      url += `&endDate=${endDate.toISOString()}`;
    }
    const { data } = await http.get(url, {timeout: 50 * 1000});
    return data;
  }

  async createInvoice(
    subscriptionId: number,
    invoiceData: ICreateInvoiceDTO
  ): Promise<IInvoice> {
    const { data } = await http.post(
      `/${InvoicesService.BASE_PATH}/${subscriptionId}`,
      invoiceData
    );
    return data;
  }

  async cancelInvoice(invoiceId: string): Promise<void> {
    await http.delete(`/${InvoicesService.BASE_PATH}/cancel/${invoiceId}`);
  }

  async editInvoice(
    invoiceId: string,
    invoiceData: IEditInvoiceDTO
  ): Promise<void> {
    await http.put(
      `/${InvoicesService.BASE_PATH}/edit/${invoiceId}`,
      invoiceData
    );
  }
}

const invoicesService = new InvoicesService();

export default invoicesService;
