import React from 'react';


import MUIDataTable, { MUIDataTableProps } from "mui-datatables";

import IDataTableColumn from '@/interfaces/IDataTableColumn';

interface Props extends MUIDataTableProps {
  columns: IDataTableColumn[];
}

export default function DownloadbleDataTable(props: Props) {
  const {options} = props
  return <MUIDataTable {...props} options={{...options, onDownload: (buildHead, buildBody, cols, data) => {
    const finalCols = cols.map((col: any) => {
      if(col.csvLabel){
        return col.csvLabel;
      }
      return col.label;
    })
    const finalData = data.map((row: any, rowIndex: number) => ({
        ...row,
        index: row.index || rowIndex,
        data: row.data.map((itemData: any, colIndex: any) => {
          if(cols[colIndex].customCSVBodyRender){
            return cols[colIndex].customCSVBodyRender(itemData, {rowData: row.data, rowIndex, colIndex});
          }
          return itemData;
        })
      }))
    return buildHead(finalCols) + buildBody(finalData);
  }}}/>
}

