import axios from 'axios';

import AppError from '@/interfaces/AppError';

const isProd = import.meta.env.PROD;

export const API_URL =
  isProd || false
    ? 'https://api2.driveonsubscriptions.com'
    : 'http://localhost:3333';

export const http = axios.create({
  baseURL: API_URL,
});

if (!isProd) {
  // simulate loading
  http.interceptors.request.use(async config => {
    await new Promise(resolve => {
      setTimeout(resolve, 500);
    });

    return config;
  });
}

http.interceptors.response.use(
  response => response,
  error => {
    if (
      error.response &&
      error.response.status !== 401 &&
      error.response.data &&
      error.response.data.message
    ) {
      return Promise.reject(
        new AppError(error.response.data.message, error.response.data.status)
      );
    }
    if (error.request) {
      console.error(error.request);
    }
    return Promise.reject(error);
  }
);
