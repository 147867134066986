import { ContactMail, PersonAdd } from '@mui/icons-material';

import { IPageDetails } from '@/interfaces/IPageDetails';

export const CUSTOMER_PAGES: IPageDetails = {
  title: 'Customers',
  path: '/customers',
  mainPagePath: '/customers/list',
  icon: ContactMail,
  menuItems: [
    {
      title: 'List',
      icon: ContactMail,
      path: '/customers/list',
    },
    {
      title: 'New Customer',
      path: '/customers/new',
      icon: PersonAdd,
    },
  ],
};
