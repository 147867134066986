import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeOptions, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { SnackbarProvider } from 'notistack';
import './index.css';

import Dialog, { setDialogRef } from '@/components/Dialog';
import MainRoutes from '@/routes';

import { AuthProvider } from './hooks/auth';
import { MetaProvider } from './hooks/meta';

export const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: '#729913',
    },
    secondary: {
      main: '#f50057',
    },
  },
};

const theme = createTheme(themeOptions);

function App() {
  return (
    <>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SnackbarProvider autoHideDuration={5000}>
          <AuthProvider>
            <ThemeProvider theme={theme}>
              <MetaProvider>
                <MainRoutes />
                <Dialog ref={setDialogRef} />
              </MetaProvider>
            </ThemeProvider>
          </AuthProvider>
        </SnackbarProvider>
      </LocalizationProvider>
    </>
  );
}

export default App;
