import { ReactNode } from 'react';

import { Button, ButtonProps } from '@mui/material';

import {
  closeDialog,
  openDialog,
  updateOpenedDialog,
} from '@/components/Dialog';

type Params = {
  title?: string;
  content?: string;
  onConfirm: () => Promise<void> | void;
  cancelButtonProps?: ButtonProps;
  confirmButtonProps?: ButtonProps;
  cancelButtonText?: string | ReactNode;
  confirmButtonText?: string | ReactNode;
};

export default function openConfirmDialog({
  title,
  content,
  onConfirm,
  cancelButtonProps = {},
  confirmButtonProps = {},
  cancelButtonText,
  confirmButtonText,
}: Params) {
  openDialog({
    title: title || 'Are you sure?',
    content: content || 'This action is not reversible.',
    actions: (
      <>
        <Button {...cancelButtonProps} onClick={() => closeDialog()}>
          {cancelButtonText || 'No'}
        </Button>
        <Button {...confirmButtonProps} onClick={onConfirm}>
          {confirmButtonText || 'Yes'}
        </Button>
      </>
    ),
  });
}

export function updateOpenedConfirmDialog({
  title,
  content,
  onConfirm,
  cancelButtonProps,
  confirmButtonProps,
  cancelButtonText,
  confirmButtonText,
}: Partial<Params>) {
  const updateParams: Parameters<typeof updateOpenedDialog>[0] = {};
  if (title) {
    updateParams.title = title;
  }
  if (content) {
    updateParams.content = content;
  }
  if (
    onConfirm ||
    cancelButtonProps ||
    confirmButtonProps ||
    cancelButtonText ||
    confirmButtonText
  ) {
    updateParams.actions = (
      <>
        <Button {...(cancelButtonProps || {})} onClick={() => closeDialog()}>
          {cancelButtonText || 'No'}
        </Button>
        <Button {...(confirmButtonProps || {})} onClick={onConfirm}>
          {confirmButtonText || 'Yes'}
        </Button>
      </>
    );
  }
  updateOpenedDialog(updateParams);
}
