import * as React from 'react';
import { useCallback } from 'react';
import { Controller, Path, UseControllerProps } from 'react-hook-form';

import {
  Checkbox as MuiCheckbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormControlProps,
  FormHelperText,
  FormHelperTextProps,
} from '@mui/material';

type Props<FormType> = CheckboxProps &
  Omit<UseControllerProps<FormType>, 'name'> & {
    fieldName: Path<FormType>;
    label: string;
    formControlLabelProps?: Partial<
      Omit<FormControlLabelProps, 'label' | 'control' | 'error'>
    >;
    formControlProps?: Partial<Omit<FormControlProps, 'error'>>;
    formHelperTextProps?: Partial<FormHelperTextProps>;
  };

export type CheckboxPropsWithoutConnectionProps<FormType> = Omit<
  Props<FormType>,
  'control'
>;

export default function Checkbox<FormType>({
  fieldName,
  control,
  label,
  formControlLabelProps,
  formControlProps,
  formHelperTextProps,
  defaultValue,
  ...props
}: Props<FormType>) {
  const renderField = useCallback(
    ({ field, fieldState: { error } }) => (
      <FormControl error={!!error} {...formControlProps}>
        <FormControlLabel
          {...(formControlLabelProps || {})}
          control={
            <MuiCheckbox
              {...props}
              checked={field.value}
              onChange={
                props.readOnly
                  ? undefined
                  : e => {
                      field.onChange(e.target.checked);
                      if (props.onChange) {
                        props.onChange(e, e.target.checked);
                      }
                    }
              }
            />
          }
          label={label}
        />
        <FormHelperText error={!!error} {...formHelperTextProps}>
          {error?.message}
        </FormHelperText>
      </FormControl>
    ),
    [formControlLabelProps, formControlProps, formHelperTextProps, label, props]
  );

  return (
    <Controller
      render={renderField}
      control={control}
      name={fieldName}
      defaultValue={defaultValue != null ? defaultValue : (false as any)} // todo: fix typing
    />
  );
}
