import IVehicle from '@/interfaces/vehicles/IVehicle';

export function vehicleDisplayName(
  vehicleInfo: Pick<IVehicle, 'brand' | 'model' | 'year'> &
    Partial<Pick<IVehicle, 'vin'>>,
  hideVin = false
): string {
  let vinPart = '';
  if (vehicleInfo.vin && !hideVin) {
    vinPart = ` (${vehicleInfo.vin})`;
  }
  return `${vehicleInfo.brand.name} ${vehicleInfo.model} ${vehicleInfo.year}${vinPart}`;
}
