import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import {
  Button,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText,
  Stack,
} from '@mui/material';

import { MAINTENANCE_STATUS } from '@/constants/maintenance-status';
import { formatDateWithTime } from '@/helpers/date';
import {
  parseDurationInSecondsToText,
  parseMaintenanceDatesISOToDuration,
} from '@/helpers/maintenances';
import { formatAmount } from '@/helpers/number';
import { vehicleDisplayName } from '@/helpers/vehicles';
import { IMaintenance } from '@/interfaces/maintenance/IMaintenance';

function InfoListItem({
  label,
  value,
  to,
}: {
  label: string;
  value: ReactNode;
  to?: string;
}) {
  return (
    <ListItem style={{ width: 'auto' }}>
      <ListItemText
        primary={label}
        primaryTypographyProps={{
          variant: 'body2',
          color: 'text.secondary',
        }}
        secondary={value}
        secondaryTypographyProps={{
          variant: 'body1',
          color: 'text.primary',
          fontWeight: 'bold',
          component: to ? Link : undefined,
          to: to || '',
        }}
      />
    </ListItem>
  );
}

interface Props {
  maintenance: IMaintenance;
  showDivider?: boolean;
  hideVehicleDetails?: boolean;
}

export default function MaintenanceDetails({
  maintenance,
  showDivider,
  hideVehicleDetails,
}: Props) {
  return (
    <List disablePadding>
      <InfoListItem
        label="Maintenance Status"
        value={
          <Chip
            sx={{
              color: 'white',
              backgroundColor: MAINTENANCE_STATUS[maintenance.status].color,
              marginTop: 0.5,
            }}
            label={maintenance.status}
            component="span"
          />
        }
      />

      {!hideVehicleDetails && (
        <InfoListItem
          label="Vehicle"
          value={vehicleDisplayName(maintenance.vehicle)}
          to={`/vehicles/inventory/${maintenance.vehicle.vin}`}
        />
      )}

      {maintenance.description && (
        <InfoListItem label="Description" value={maintenance.description} />
      )}

      <Stack flexDirection={{ xs: 'column', md: 'row' }}>
        <InfoListItem
          label="Maintenance Type"
          value={maintenance.type.displayName}
        />
        <InfoListItem
          label="Duration"
          value={`${parseDurationInSecondsToText(
            parseMaintenanceDatesISOToDuration(
              maintenance.expectedStartDate,
              maintenance.expectedEndDate
            )
          )}`}
        />
      </Stack>

      {maintenance.canceledAt ? (
        <InfoListItem
          label="Canceled at"
          value={formatDateWithTime(new Date(maintenance.canceledAt))}
        />
      ) : (
        <>
          <Stack flex={1} flexDirection={{ xs: 'column', md: 'row' }}>
            {maintenance.startVehicleMileage && (
              <InfoListItem
                label="Start mileage"
                value={formatAmount(maintenance.startVehicleMileage)}
              />
            )}
            {maintenance.finishVehicleMileage && (
              <InfoListItem
                label="Finish mileage"
                value={formatAmount(maintenance.finishVehicleMileage)}
              />
            )}
          </Stack>

          <Stack flexDirection={{ xs: 'column', md: 'row' }}>
            {maintenance.startDate ? (
              <>
                <InfoListItem
                  label="Start date"
                  value={formatDateWithTime(new Date(maintenance.startDate))}
                />
                {maintenance.endDate && (
                  <InfoListItem
                    label="Finish date"
                    value={formatDateWithTime(new Date(maintenance.endDate))}
                  />
                )}
              </>
            ) : (
              <>
                <InfoListItem
                  label="Expected start date"
                  value={formatDateWithTime(
                    new Date(maintenance.expectedStartDate)
                  )}
                />
                <InfoListItem
                  label="Expected end date"
                  value={formatDateWithTime(
                    new Date(maintenance.expectedEndDate)
                  )}
                />
              </>
            )}
          </Stack>
        </>
      )}

      {hideVehicleDetails && (
        <ListItem sx={{ width: 'auto', marginTop: 0.75, marginBottom: 0.75 }}>
          <Button
            href={`/maintenance/list/${maintenance.id}`}
            variant="contained"
          >
            View details
          </Button>
        </ListItem>
      )}

      {showDivider && <Divider />}
    </List>
  );
}
