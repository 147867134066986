import { useCallback, useEffect, useMemo, useState } from 'react';

import { Chat, Delete, ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

import { closeDialog, openDialog } from '@/components/Dialog';
import { ConnectedFormComponents } from '@/components/form';
import openConfirmDialog from '@/components/openConfirmDialog';
import { formatDateWithTime } from '@/helpers/date';
import AppError from '@/interfaces/AppError';
import { IComment } from '@/interfaces/comments/IComment';
import commentsService from '@/services/comments';

const commentSchema = yup.object().shape({
  content: yup
    .string()
    .trim()
    .required('The content is required'),
});

type Props = {
  moduleName: string;
  entityId: string;
  defaultExpanded?: boolean;
};

export default function CommentsContainer({ moduleName, entityId, defaultExpanded = false }: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [comments, setComments] = useState<IComment[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  const fetchComments = useCallback(async () => {
    setIsLoading(true);
    try {
      const fetchedComments = await commentsService.listComments(
        moduleName,
        entityId
      );
      setComments(fetchedComments);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(
        error instanceof AppError
          ? error?.message
          : 'Error on loading comments. Please, try again later',
        { variant: 'error' }
      );
    }
    setIsLoading(false);
  }, [entityId, moduleName]);

  useEffect(() => {
    fetchComments();
  }, [fetchComments]);

  const commentsList = useMemo(
    () => (
      <>
        <Stack direction="row" gap={1} justifyContent="flex-end">
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            onClick={() => {
              openDialog({
                title: 'New comment',
                customDialogProps: {
                  dialogContentProps: {
                    sx: {
                      minWidth: 500,
                    },
                  },
                },
                content: {
                  description: 'Please provide the content of the comment',
                  schema: commentSchema,
                  inputs: [
                    {
                      key: 'content',
                      type: ConnectedFormComponents.TEXT_INPUT,
                      props: {
                        fieldName: 'content',
                        multiline: true,
                        rows: 4,
                        placeholder: 'Customer contacted us about...',
                        label: 'Content',
                      },
                    },
                  ],
                  onSubmit: async (
                    values: yup.InferType<typeof commentSchema>
                  ) => {
                    setIsLoading(true);
                    try {
                      await commentsService.createComment({
                        relatedModuleName: moduleName,
                        relatedEntityId: entityId,
                        content: values.content,
                      });
                      closeDialog();
                      fetchComments();
                      enqueueSnackbar('Comment created successfully', {
                        variant: 'success',
                      });
                    } catch (error) {
                      console.error(error);
                      enqueueSnackbar(
                        error instanceof AppError
                          ? error?.message
                          : 'Error on creating comment. Please, try again later',
                        { variant: 'error' }
                      );
                    }
                    setIsLoading(false);
                  },
                },
              });
            }}
          >
            Add new comment
          </Button>
        </Stack>

        <List dense>
          {comments.length === 0 && (
            <Typography variant="body1">No comments yet</Typography>
          )}
          {comments.map((comment, index) => (
            <ListItem
              key={comment.id}
              sx={{
                borderRadius: 1,
                border: '1px solid #e0e0e0',
                mb: index !== comments.length - 1 ? 1 : 0,

                '&:hover': {
                  backgroundColor: '#f5f5f5',
                },
              }}
              secondaryAction={
                <Tooltip title="Delete comment">
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      openConfirmDialog({
                        onConfirm: async () => {
                          setIsLoading(true);
                          try {
                            await commentsService.deleteComment(comment.id);
                            closeDialog();
                            fetchComments();
                            enqueueSnackbar('Comment deleted successfully', {
                              variant: 'success',
                            });
                          } catch (error) {
                            console.error(error);
                            enqueueSnackbar(
                              error instanceof AppError
                                ? error?.message
                                : 'Error on deleting comment. Please, try again later',
                              { variant: 'error' }
                            );
                          }
                          setIsLoading(false);
                        },
                      });
                    }}
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              }
            >
              <ListItemText
                primaryTypographyProps={{
                  variant: 'body1',
                }}
                primary={comment.content}
                secondaryTypographyProps={{
                  variant: 'caption',
                }}
                secondary={
                  <>
                    <Typography
                      display="inline"
                      variant="caption"
                      fontWeight="bold"
                    >
                      {comment.author.name}
                    </Typography>{' '}
                    - {formatDateWithTime(new Date(comment.createdAt))}
                  </>
                }
              />
            </ListItem>
          ))}
        </List>
      </>
    ),
    [comments, enqueueSnackbar, entityId, fetchComments, moduleName]
  );

  return (
    <Accordion sx={{ marginBottom: 2 }} defaultExpanded={defaultExpanded}>
      <AccordionSummary
        expandIcon={
          isLoading ? (
            <CircularProgress size={18} color="primary" />
          ) : (
            <ExpandMore />
          )
        }
        disabled={isLoading}
      >
        <Stack gap={1} direction="row" alignItems="center">
          <Chat />
          <Typography variant="h6">Comments ({comments.length})</Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>{commentsList}</AccordionDetails>
    </Accordion>
  );
}
