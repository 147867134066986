import {
  Engineering,
  CreateOutlined,
  HealthAndSafety,
} from '@mui/icons-material';

import { isUUID } from '@/helpers/uuid';
import { IPageDetails } from '@/interfaces/IPageDetails';

export const MAINTENANCE_PAGES: IPageDetails = {
  title: 'Maintenance',
  path: '/maintenance',
  mainPagePath: '/maintenance',
  icon: Engineering,
  menuItems: [
    {
      title: 'New appointment',
      icon: CreateOutlined,
      path: '/maintenance/new',
    },
    {
      title: 'Types',
      icon: HealthAndSafety,
      path: '/maintenance/types',
    },
    {
      title: 'New Maintenance Type',
      icon: () => null,
      showOnNavMenus: false,
      path: '/maintenance/types/new',
    },
    {
      title: path => `Maintenance Type: ${path}`,
      path: '/maintenance/types/:id',
      icon: () => null,
      showOnNavMenus: false,
      match: (pathname: string) => {
        const paths = pathname.split('/').filter(p => !!p);
        const isMaintenanceTypeDetails =
          paths.length === 3 &&
          paths[0] === 'maintenance' &&
          paths[1] === 'types' &&
          isUUID(paths[2]);

        return isMaintenanceTypeDetails;
      },
    },
  ],
};
