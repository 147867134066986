import { AddLocation, DirectionsCar, LocationOn } from '@mui/icons-material';

import { IPageDetails } from '@/interfaces/IPageDetails';

export const VEHICLE_PAGES: IPageDetails = {
  title: 'Vehicles',
  path: '/vehicles',
  icon: DirectionsCar,
  mainPagePath: '/vehicles/inventory',
  menuItems: [
    {
      title: 'Inventory',
      path: '/vehicles/inventory',
      icon: DirectionsCar,
    },
    {
      title: 'Vehicle Details',
      path: '/vehicles/inventory/:id',
      icon: () => null,
      showOnNavMenus: false,
      match: (pathname: string) => {
        const isVehiclesSubRoute = pathname.startsWith('/vehicles/');
        const paths = pathname.split('/');
        const isVehicleDetails =
          paths.length >= 3 &&
          paths[1] === 'inventory' &&
          paths[2] &&
          paths[2].length === 17;

        return !!(isVehiclesSubRoute && isVehicleDetails);
      },
    },
    {
      title: 'Vehicle Images',
      path: '/vehicles/inventory/:id/images',
      icon: () => null,
      showOnNavMenus: false,
      match: (pathname: string) => {
        const isVehiclesSubRoute = pathname.startsWith('/vehicles/');
        const paths = pathname.split('/').filter(v => !!v);
        const isVehicleImageDetails =
          paths.length >= 4 &&
          paths[1] === 'inventory' &&
          paths[2] &&
          paths[2].length === 17 &&
          paths[3] === 'images';

        return !!(isVehiclesSubRoute && isVehicleImageDetails);
      },
    },
    {
      title: 'New Vehicle',
      path: '/vehicles/new',
      icon: () => null,
      showOnNavMenus: false,
    },
    {
      title: 'GPS',
      path: '/vehicles/gps',
      icon: LocationOn,
    },
    {
      title: 'New GPS',
      path: '/vehicles/gps/new',
      icon: AddLocation,
      showOnNavMenus: false,
    },
  ],
};
