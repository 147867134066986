import IInvoice from '@/interfaces/payments/IInvoice';
import IPayment from '@/interfaces/payments/IPayment';

export enum IInstallmentStatus {
  OPEN = 'OPEN',
  PAID = 'PAID',
  PAST_DUE = 'PAST_DUE',
  RESCHEDULED = 'RESCHEDULED',
}

export default interface IInstallment {
  id: string;
  dueDate: Date;
  rescheduleDate?: Date;
  amount: number;
  taxRate: number;
  totalAmount: number;
  readonly status: IInstallmentStatus;
  invoice: IInvoice;
  paidAt?: Date | null;
  createdAt: Date;
  updatedAt: Date;
  readonly payment?: IPayment | null;
  paymentsHistory: IPayment[];
}
