import { Route } from 'react-router-dom';

import { RequireNotAuth } from '@/hooks/auth';
import SignIn from '@/pages/SignIn';

export default function AuthRoutes() {
  return (
    <Route element={<RequireNotAuth />}>
      <Route key="login-page" path="/login" element={<SignIn />} />
      <Route
        key="forgot-password-page"
        path="/forgot-password"
        element={<div>to be implemented</div>}
      />
    </Route>
  );
}
