import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

import { useConnectedFormComponents } from '@/components/form';
import { currencyMask } from '@/constants/mask';
import AppError from '@/interfaces/AppError';
import { IMaskInput } from '@/interfaces/IMaskInput';
import { IVehicleExpense } from '@/interfaces/vehicles/IVehicleExpense';
import vehiclesService from '@/services/vehicles';

import ExpenseCategorySelect from '../ExpenseCategorySelect';

type Form = {
  expenseCategory: {
    id: string;
    name: string;
  } | null;
  amount: IMaskInput<number>;
  description: string;
};

interface Props {
  vehicleId: string;
  maintenanceId?: string;
  initialData?: Partial<Omit<Form, 'amount'> & { amount: number }> & {
    expenseId: string;
  };
  open: boolean;
  onClose: () => void;
  onSuccess: (expense: IVehicleExpense) => void;
}

const schema = Yup.object().shape({
  amount: Yup.object()
    .shape({
      masked: Yup.string().required(),
      unmasked: Yup.number().typeError('Amount is required'),
    })
    .nullable()
    .required('Amount is required')
    .typeError('Amount is required'),
  expenseCategory: Yup.object()
    .shape({
      id: Yup.string().required('Expense category is required'),
      name: Yup.string().required('Expense category is required'),
    })
    .required('Expense Category is required'),
  description: Yup.string().required('Description is required'),
});

export default function ExpenseForm(props: Props) {
  const { vehicleId, maintenanceId, initialData, open, onClose, onSuccess } =
    props;

  const [submitting, setSubmitting] = useState<boolean>(false);

  const formHook = useForm<Form>({
    resolver: yupResolver(schema),
  });

  const { handleSubmit, control, reset } = formHook;

  const { TextInput } = useConnectedFormComponents<Form>({
    control,
  });

  const { enqueueSnackbar } = useSnackbar();

  const handleClose = useCallback(() => {
    reset({
      expenseCategory: null,
      amount: {
        masked: '0',
        unmasked: 0,
      },
      description: '',
    });
    onClose();
  }, [onClose, reset]);

  const handleFormSubmit = useCallback(
    async (data: Form) => {
      try {
        setSubmitting(true);
        if (initialData) {
          // Edit
          const expenseResponse = await vehiclesService.editVehicleExpense({
            expenseId: initialData.expenseId,
            amount: data.amount.unmasked,
            expenseCategoryId: data.expenseCategory?.id || '',
            description: data.description,
          });
          onSuccess(expenseResponse);
        } else {
          // Create
          const expenseResponse = await vehiclesService.createVehicleExpense({
            amount: data.amount.unmasked,
            expenseCategoryId: data.expenseCategory?.id || '',
            vehicleId,
            maintenanceId,
            description: data.description,
          });
          onSuccess(expenseResponse);
        }

        handleClose();
      } catch (error) {
        console.error(error);
        enqueueSnackbar(
          error instanceof AppError
            ? error?.message
            : 'Error on creating expense. Please, try again later',
          { variant: 'error' }
        );
      } finally {
        setSubmitting(false);
      }
    },
    [
      enqueueSnackbar,
      initialData,
      vehicleId,
      maintenanceId,
      onSuccess,
      handleClose,
    ]
  );

  useEffect(() => {
    if (!initialData) {
      return;
    }

    const { amount, ...formData } = initialData;

    reset({
      ...formData,
      expenseCategory: {
        id: formData.expenseCategory?.id,
        name: formData.expenseCategory?.name,
      },
      amount: {
        masked: `${amount}`,
        unmasked: amount,
      },
    });
  }, [initialData, reset]);

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClose}>
      <DialogTitle>{!initialData ? 'New expense' : 'Edit expense'}</DialogTitle>

      <form>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
        >
          <FormProvider {...formHook}>
            <ExpenseCategorySelect control={control} />
          </FormProvider>

          <TextInput
            margin="none"
            label="Amount"
            fullWidth
            fieldName="amount"
            maskProps={currencyMask()}
          />
          <TextInput
            margin="none"
            label="Description"
            fullWidth
            fieldName="description"
            multiline
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={submitting} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={submitting}
            onClick={handleSubmit(handleFormSubmit)}
          >
            {!initialData ? 'Create' : 'Save'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
