import { Group, GroupAdd, SupervisedUserCircle } from '@mui/icons-material';

import { UserRoles } from '@/interfaces/system-users/UserRoles';

import { IPageDetails } from '../interfaces/IPageDetails';

export const SYSTEM_USER_PAGES: IPageDetails = {
  title: 'System Users',
  path: '/system-users',
  mainPagePath: '/system-users/list',
  icon: SupervisedUserCircle,
  menuItems: [
    {
      title: 'List',
      icon: Group,
      path: '/system-users/list',
    },
    {
      title: 'New User',
      path: '/system-users/new',
      icon: GroupAdd,
      roles: [UserRoles.ADMIN],
    },
  ],
};
