import IEntityFile from '@/interfaces/files/IEntityFile';

import { http } from './http';

class EntityFilesService {
  static BASE_PATH = 'entity-files';

  async listEntityFiles(params: {
    moduleName: string;
    entityId: string;
  }): Promise<IEntityFile[]> {
    const { data } = await http.get(
      `/${EntityFilesService.BASE_PATH}/${params.moduleName}/${params.entityId}`
    );
    return data;
  }

  async uploadEntityFiles(params: {
    entityId: string;
    moduleName: string;
    files: File[];
    customPath?: string;
  }): Promise<void> {
    const formData = new FormData();
    params.files.forEach(file => {
      formData.append('files', file);
    });
    if (params.customPath) {
      formData.append('customPath', params.customPath);
    }
    await http.post(
      `/${EntityFilesService.BASE_PATH}/${params.moduleName}/${params.entityId}`,
      formData
    );
  }

  async deleteEntityFile(fileId: string): Promise<void> {
    await http.delete(`/${EntityFilesService.BASE_PATH}/${fileId}`);
  }
}

const entityFilesService = new EntityFilesService();

export default entityFilesService;
