import { IInstallmentStatus } from '@/interfaces/payments/IInstallment';
import { IInvoiceStatus } from '@/interfaces/payments/IInvoice';

const invoiceStatusMapping = {
  [IInvoiceStatus.OPEN]: 'Open',
  [IInvoiceStatus.PAID]: 'Paid',
  [IInvoiceStatus.RESCHEDULED]: 'Rescheduled',
  [IInvoiceStatus.PAST_DUE]: 'Past Due',
};

export function formatInvoiceStatus(status: IInvoiceStatus): string {
  return invoiceStatusMapping[status];
}

const installmentStatusMapping = {
  [IInstallmentStatus.OPEN]: 'Open',
  [IInstallmentStatus.PAID]: 'Paid',
  [IInstallmentStatus.RESCHEDULED]: 'Rescheduled',
  [IInstallmentStatus.PAST_DUE]: 'Past Due',
};

export function formatInstallmentStatus(status: IInstallmentStatus): string {
  return installmentStatusMapping[status];
}

export function calcTotalAmount(amount: number, tax: number): number {
  const total = amount * (1 + Number(tax));
  return Number(total.toFixed(2));
}

export function calcAmount(totalAmount: number, tax: number): number {
  const amount = totalAmount / (1 + Number(tax));
  return Number(amount.toFixed(2));
}
