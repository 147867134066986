import { Route } from 'react-router-dom';

import AppContainer from '@/components/AppContainer';
import { PAGES } from '@/constants/pages';
import { filterRoutesToShow } from '@/helpers/page';
import { RouteWithRoles } from '@/helpers/roles';
import { RequireAuth, RequireRole, useAuth } from '@/hooks/auth';
import { UserRoles } from '@/interfaces/system-users/UserRoles';
import Customers from '@/pages/Customers';
import CustomerForm from '@/pages/Customers/Form';
import ListCustomers from '@/pages/Customers/List';
import Home from '@/pages/Home';
import Maintenance from '@/pages/Maintenance';
import NewMaintenance from '@/pages/Maintenance/Create';
import MaintenanceTypeForm from '@/pages/Maintenance/MaintenancesTypes/Form';
import ListMaintenancesTypes from '@/pages/Maintenance/MaintenancesTypes/List';
import MaintenanceView from '@/pages/Maintenance/View';
import Reports from '@/pages/Reports';
import PaymentsReport from '@/pages/Reports/PaymentsReport';
import TaxesReport from '@/pages/Reports/TaxesReport';
import Subscriptions from '@/pages/Subscriptions';
import ChangeVehicle from '@/pages/Subscriptions/ChangeVehicle';
import SubscriptionForm from '@/pages/Subscriptions/Form';
import ListSubscriptions from '@/pages/Subscriptions/List';
import PaymentForm from '@/pages/Subscriptions/Payments/Form';
import SimulateSubscription from '@/pages/Subscriptions/Simulate';
import SubscriptionTypeForm from '@/pages/Subscriptions/SubscriptionsTypes/Form';
import ListSubscriptionsTypes from '@/pages/Subscriptions/SubscriptionsTypes/List';
import SubscriptionDetails from '@/pages/Subscriptions/View';
import SystemUsers from '@/pages/SystemUsers';
import SystemUserForm from '@/pages/SystemUsers/Form';
import ListSystemUsers from '@/pages/SystemUsers/List';
import Vehicles from '@/pages/Vehicles';
import FormVehicle from '@/pages/Vehicles/Form';
import VehicleGPSForm from '@/pages/Vehicles/GPS/Form';
import ListVehicleGPS from '@/pages/Vehicles/GPS/List';
import ListVehicles from '@/pages/Vehicles/List';
import VehicleImages from '@/pages/Vehicles/VehicleImages';

const routes: RouteWithRoles[] = [
  { path: '/vehicles', element: <Vehicles /> },
  { path: '/vehicles/inventory', element: <ListVehicles /> },
  { path: '/vehicles/inventory/:vin', element: <FormVehicle /> },
  { path: '/vehicles/new', element: <FormVehicle />, roles: [UserRoles.ADMIN] },
  { path: '/vehicles/gps', element: <ListVehicleGPS /> },
  {
    path: '/vehicles/gps/new',
    element: <VehicleGPSForm />,
    roles: [UserRoles.ADMIN],
  },
  { path: '/vehicles/inventory/:vin/images', element: <VehicleImages /> },
  { path: '/subscriptions', element: <Subscriptions /> },
  { path: '/subscriptions/list', element: <ListSubscriptions /> },
  { path: '/subscriptions/new', element: <SubscriptionForm /> },
  { path: '/subscriptions/list/:id', element: <SubscriptionDetails /> },
  {
    path: '/subscriptions/list/:id/change-vehicle',
    element: <ChangeVehicle />,
    roles: [UserRoles.ADMIN],
  },
  { path: '/subscriptions/types', element: <ListSubscriptionsTypes /> },
  {
    path: '/subscriptions/types/new',
    element: <SubscriptionTypeForm />,
    roles: [UserRoles.ADMIN],
  },
  {
    path: '/subscriptions/types/:id',
    element: <SubscriptionTypeForm />,
    roles: [UserRoles.ADMIN],
  },
  { path: '/subscriptions/simulate', element: <SimulateSubscription /> },
  { path: '/payments/pay/:subscriptionId', element: <PaymentForm />, roles: [UserRoles.ADMIN] },
  { path: '/customers', element: <Customers /> },
  { path: '/customers/list', element: <ListCustomers /> },
  { path: '/customers/list/:id', element: <CustomerForm /> },
  { path: '/customers/new', element: <CustomerForm /> },
  { path: '/system-users', element: <SystemUsers /> },
  { path: '/system-users/list', element: <ListSystemUsers /> },
  {
    path: '/system-users/new',
    element: <SystemUserForm />,
    roles: [UserRoles.ADMIN],
  },
  {
    path: '/system-users/:id',
    element: <SystemUserForm />,
    roles: [UserRoles.ADMIN],
  },
  { path: '/reports', element: <Reports />, roles: [UserRoles.ADMIN] },
  {
    path: '/reports/payments',
    element: <PaymentsReport />,
    roles: [UserRoles.ADMIN],
  },
  {
    path: '/reports/taxes',
    element: <TaxesReport />,
    roles: [UserRoles.ADMIN],
  },
  {
    path: '/maintenance',
    element: <Maintenance />,
  },
  {
    path: '/maintenance/new',
    element: <NewMaintenance />,
  },
  {
    path: '/maintenance/types',
    element: <ListMaintenancesTypes />,
  },
  {
    path: '/maintenance/types/new',
    element: <MaintenanceTypeForm />,
    roles: [UserRoles.ADMIN],
  },
  {
    path: '/maintenance/types/:id',
    element: <MaintenanceTypeForm />,
    roles: [UserRoles.ADMIN],
  },
  { path: '/maintenance/list/:id', element: <MaintenanceView /> },
];

export default function AppRoutes() {
  const {signOut } = useAuth();

  return (
    <Route
      element={
        <AppContainer
          pagesLinks={filterRoutesToShow(PAGES)}
          onClickLogout={signOut}
        >
          <RequireAuth />
          {/* <NotificationToaster /> */}
        </AppContainer>
      }
    >
      <Route path="/" element={<Home />} />

      {routes.map(({ roles, element, ...route }) => (
        <Route
          key={route.path}
          {...route}
          element={<RequireRole roles={roles || [UserRoles.SALES, UserRoles.ADMIN]}>{element}</RequireRole>}
        />
      ))}
    </Route>
  );
}
