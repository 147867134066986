/* eslint-disable no-param-reassign */
import * as React from 'react';

import { FormGroup, FormLabel } from '@mui/material';
import TextField from '@mui/material/TextField';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker/DatePicker';
import {
  addSeconds,
  endOfDay,
  isAfter,
  isBefore,
  isValid as isValidDate,
  startOfDay,
  subSeconds,
} from 'date-fns';
import { MUIDataTableColumnOptions } from 'mui-datatables';

import { formatDate } from '@/helpers/date';

const getLimitDate = (dateLimit: Date, isMin: boolean) => {
  if (isMin) {
    return subSeconds(startOfDay(dateLimit), 1);
  }
  return addSeconds(endOfDay(dateLimit), 1);
};

export const dateRangeFilterDatatablesOptions = ({
  label,
  customValueGetter,
}: {
  label: string;
  customValueGetter?: (subId: number, tableValue: string) => string | null;
}) =>
  ({
    filterType: 'custom',
    customFilterListOptions: {
      render: v => {
        const [min, max] = v;
        const minDate = min ? formatDate(new Date(min)) : '';
        const maxDate = max ? formatDate(new Date(max)) : '';
        if (min && max) {
          return `${label} - Min: ${minDate}, ${label} - Max: ${maxDate}`;
        }
        if (min) {
          return `${label} - Min: ${minDate}`;
        }
        if (max) {
          return `${label} - Max: ${maxDate}`;
        }
        return [];
      },
      update: (filterList, filterPos, index) => {
        if (filterPos === 0) {
          filterList[index].splice(filterPos, 1, '');
        } else if (filterPos === 1) {
          filterList[index].splice(filterPos, 1);
        } else if (filterPos === -1) {
          filterList[index] = [];
        }

        return filterList;
      },
    },
    filterOptions: {
      logic(tableValue, filters, row) {
        const value = customValueGetter
          ? customValueGetter(row![0], tableValue)
          : tableValue;

        if (!value) {
          return false;
        }

        const [min, max] = filters;
        const minDate = getLimitDate(new Date(min), true);
        const maxDate = getLimitDate(new Date(max), false);
        const dateValue = new Date(value);
        if (min && max) {
          return isBefore(dateValue, minDate) || isAfter(dateValue, maxDate);
        }
        if (min) {
          return isBefore(dateValue, minDate);
        }
        if (max) {
          return isAfter(dateValue, maxDate);
        }
        return false;
      },
      // eslint-disable-next-line react/no-unstable-nested-components
      display: (filterList, onChange, index, column) => {
        const [min, max] = filterList[index];
        return (
          <div>
            <FormLabel>{column.label}</FormLabel>
            <FormGroup row>
              <MuiDatePicker
                allowSameDateSelection
                onChange={date => {
                  filterList[index][0] =
                    date && isValidDate(date) ? date.toISOString() : '';
                  onChange(filterList[index], index, column);
                }}
                label="Min"
                value={min ? new Date(min) : null}
                renderInput={props => (
                  <TextField
                    variant="standard"
                    sx={{ width: '45%', mr: '5%' }}
                    {...props}
                  />
                )}
              />

              <MuiDatePicker
                allowSameDateSelection
                onChange={date => {
                  filterList[index][1] = date ? date.toISOString() : '';
                  onChange(filterList[index], index, column);
                }}
                label="Max"
                value={max ? new Date(max) : null}
                renderInput={props => (
                  <TextField
                    variant="standard"
                    sx={{ width: '45%' }}
                    {...props}
                  />
                )}
              />
            </FormGroup>
          </div>
        );
      },
    },
  } as Partial<MUIDataTableColumnOptions>);
