class AppError {
  public readonly message: string;

  public readonly status: number | string;

  constructor(message: string, status: number | string = 400) {
    this.message = message;
    this.status = status;
  }
}

export default AppError;
