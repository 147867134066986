import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Add, Notes } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';

import DownloadbleDataTable from '@/components/DownloadbleDataTable';
import { dateRangeFilterDatatablesOptions } from '@/helpers/datatables';
import { formatDate } from '@/helpers/date';
import { vehicleDisplayName } from '@/helpers/vehicles';
import IDataTableColumn from '@/interfaces/IDataTableColumn';
import IInstallment, {
  IInstallmentStatus,
} from '@/interfaces/payments/IInstallment';
import IListNextDueInstallmentResponseDTO from '@/interfaces/subscriptions/IListNextDueInstallmentResponseDTO';
import ISubscription from '@/interfaces/subscriptions/ISubscription';
import ISubscriptionType from '@/interfaces/subscriptions/ISubscriptionType';
import IVehicle from '@/interfaces/vehicles/IVehicle';
import subscriptionsService from '@/services/subscriptions';

function ViewButton({ subscriptionId }: { subscriptionId: number }) {
  const handleClick = useCallback(() => {
    window.open(`/subscriptions/list/${subscriptionId}`, '_blank');
  }, [subscriptionId]);

  return (
    <Tooltip title="Details">
      <IconButton onClick={handleClick}>
        <Notes />
      </IconButton>
    </Tooltip>
  );
}

const invoiceStatus = (installment?: IInstallment) => {
  if (installment && !installment.id) {
    return <Chip label="Paid" color="success" />;
  }
  if (
    installment &&
    installment.id &&
    installment.status === IInstallmentStatus.PAST_DUE
  ) {
    return (
      <Chip
        label={`Past due ${formatDate(
          installment.rescheduleDate || installment.dueDate
        )}`}
        color="error"
      />
    );
  }
  if (installment && installment.status === IInstallmentStatus.RESCHEDULED) {
    return (
      <Chip
        label={`Rescheduled ${formatDate(
          installment.rescheduleDate || installment.dueDate
        )}`}
        color="warning"
      />
    );
  }
  return <Chip label="Up to date" color="info" />;
};

export default function ListSubscriptions() {
  const navigate = useNavigate();

  const [subscriptionsList, setSubscriptionsList] = useState<ISubscription[]>(
    []
  );
  const [subscriptionsCount, setSubscriptionsCount] = useState(0);
  const [nextDueInstallments, setNextDueInstallments] =
    useState<IListNextDueInstallmentResponseDTO>({});
  const [subscriptionsTypesList, setSubscriptionsTypesList] = useState<
    ISubscriptionType[]
  >([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const [subscriptions, nextDueInstallmentsResponse, subscriptionsTypes] =
          await Promise.all([
            subscriptionsService.listSubscriptions(),
            subscriptionsService.listNextDueInstallments(),
            subscriptionsService.listSubscriptionTypes(),
          ]);
        setSubscriptionsList(subscriptions);
        setNextDueInstallments(nextDueInstallmentsResponse);
        setSubscriptionsTypesList(subscriptionsTypes);
        // setSubscriptionsCount(subscriptions.length);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const addButton = useCallback(
    () => (
      <>
        <Button
          onClick={() => {
            navigate('/subscriptions/new');
          }}
          variant="outlined"
          startIcon={<Add />}
          sx={{ mt: 1 }}
        >
          New subscription
        </Button>
        <Box mt={1}>
          <Typography variant="body2">
            Subscriptions count: {subscriptionsCount}
          </Typography>
        </Box>
      </>
    ),
    [subscriptionsCount]
  );

  const columns: IDataTableColumn[] = useMemo<IDataTableColumn[]>(
    () => [
      {
        name: 'id',
        label: 'ID',
        options: {
          filter: true,
          sort: true,
          filterType: 'textField',
        },
      },
      {
        name: 'currentVehicle.vehicle',
        label: 'Vehicle',
        options: {
          filter: true,
          filterType: 'textField',
          sort: true,
          customBodyRender: (vehicle: IVehicle) => vehicleDisplayName(vehicle),
        },
      },
      {
        name: 'customer.name',
        label: 'Customer',
        options: {
          filter: true,
          filterType: 'textField',
          sort: true,
        },
      },
      {
        name: 'type.displayName',
        label: 'Type',
        options: {
          sort: true,
          filter: true,
          filterOptions: {
            names: subscriptionsTypesList.map(type => type.displayName),
            logic: (prop, filterValue, row) =>
              subscriptionsTypesList.some(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                // todo: fix type
                type => row && type.displayName === row[prop]
              ),
          },
        },
      },
      {
        name: 'startDate',
        label: 'Start Date',
        options: {
          sort: true,
          customBodyRender: (startDate: Date) => formatDate(startDate),
          ...dateRangeFilterDatatablesOptions({ label: 'Start Date' }),
        },
      },
      {
        name: 'endDate',
        label: 'End Date',
        options: {
          sort: true,
          customBodyRender: (endDate: Date, tableMeta: any) => {
            if (endDate) {
              return formatDate(endDate || 'Not Found');
            }
            const subscription = subscriptionsList.find(
              sub => sub.id === tableMeta.rowData[0]
            );
            if (subscription)
              return `Expected: ${formatDate(subscription.expectedEndDate)}`;
            return 'Not Found';
          },
          ...dateRangeFilterDatatablesOptions({
            label: 'End Date',
            customValueGetter: (subId, tableValue) => {
              if (tableValue) {
                return tableValue;
              }
              const subscription = subscriptionsList.find(
                sub => sub.id === subId
              );
              if (subscription)
                return subscription.expectedEndDate.toISOString();

              return null;
            },
          }),
        },
      },
      {
        name: 'endDate',
        label: 'Status',
        options: {
          sort: true,
          filter: true,
          customBodyRender: (endDate: string) =>
            endDate ? 'Finished' : 'Active',
          // TODO: add status when is past expected End Date but not finished
        },
      },
      {
        name: 'id',
        label: 'Invoices',
        options: {
          sort: true,
          filter: true,
          download: false,
          filterType: 'multiselect',
          customBodyRender: (id: string) =>
            invoiceStatus(nextDueInstallments[id]),
          filterOptions: {
            names: ['Up to date', 'Paid', 'Past due', 'Rescheduled'],
            logic: (prop, filterValue) => {
              const arrayMapped = filterValue.map((value: string) => {
                if (value === 'Past due') {
                  return IInstallmentStatus.PAST_DUE.toString();
                }
                if (value === 'Rescheduled') {
                  return IInstallmentStatus.RESCHEDULED.toString();
                }
                if (value === 'Paid') {
                  return IInstallmentStatus.PAID.toString();
                }
                return IInstallmentStatus.OPEN.toString();
              });
              if (!nextDueInstallments[prop] || !nextDueInstallments[prop].id) {
                return !arrayMapped.includes(
                  IInstallmentStatus.PAID.toString()
                );
              }
              return !arrayMapped.includes(nextDueInstallments[prop].status);
            },
          },
        },
      },
      {
        name: '',
        label: 'Actions',
        options: {
          filter: false,
          sort: false,
          print: false,
          download: false,
          searchable: false,
          empty: true,
          viewColumns: false,
          // eslint-disable-next-line react/no-unstable-nested-components
          customBodyRenderLite: (index: number) => (
            <ViewButton subscriptionId={subscriptionsList[index].id} />
          ),
        },
      },
    ],
    [subscriptionsTypesList, subscriptionsList]
  );

  return (
    <DownloadbleDataTable
      title="Subscriptions"
      data={subscriptionsList}
      columns={columns}
      options={{
        storageKey: '@driveon:subscriptions:list',
        rowsPerPage: 100,
        rowsPerPageOptions: [100, 200, 500, 1000],
        sortOrder: {
          name: 'id',
          direction: 'desc',
        },
        onTableChange: (action, tableState) => {
          if (action === 'filterChange' || action === 'propsUpdate') {
            setSubscriptionsCount(tableState.displayData.length);
          }
        },
        print: false,
        enableNestedDataAccess: '.',
        rowHover: true,
        selectableRows: 'none',
        customToolbar: addButton,
        downloadOptions: {
          filterOptions: {
            useDisplayedColumnsOnly: true,
            useDisplayedRowsOnly: true,
          },
        },
        textLabels: {
          body: {
            noMatch: loading ? (
              <CircularProgress />
            ) : (
              'Sorry, no matching records found'
            ),
          },
        },
      }}
    />
  );
}
