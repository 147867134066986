import { Button, DialogActions } from '@mui/material';

interface Props {
  isEdit?: boolean;
  onClose: () => void;
}

export default function MaintenanceFormActions({ isEdit, onClose }: Props) {
  return (
    <DialogActions>
      <Button onClick={onClose}>Cancel</Button>
      <Button type="submit">{!isEdit ? 'Create' : 'Save'}</Button>
    </DialogActions>
  );
}
