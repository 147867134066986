import { ReactNode } from 'react';

import {
  DialogActionsProps,
  DialogContentProps,
  DialogTitleProps,
} from '@mui/material';
import { DialogProps as MUIDialogProps } from '@mui/material/Dialog/Dialog';
import { AnyObjectSchema } from 'yup';

import {
  ConnectedFormComponents,
  ConnectedFormComponentsMap,
} from '@/components/form';

export type CustomDialogProps = {
  dialogProps?: Omit<MUIDialogProps, 'open' | 'onClose'>;
  dialogContentProps?: DialogContentProps;
  dialogActionsProps?: DialogActionsProps;
  dialogTitleProps?: DialogTitleProps;
};

type MappedInput =
  | {
      type: ConnectedFormComponents.TEXT_INPUT;
      props: ConnectedFormComponentsMap[ConnectedFormComponents.TEXT_INPUT];
    }
  | {
      type: ConnectedFormComponents.CHECKBOX;
      props: ConnectedFormComponentsMap[ConnectedFormComponents.CHECKBOX];
    }
  | {
      type: ConnectedFormComponents.AUTOCOMPLETE;
      props: ConnectedFormComponentsMap[ConnectedFormComponents.AUTOCOMPLETE];
    }
  | {
      type: ConnectedFormComponents.DATE_PICKER;
      props: ConnectedFormComponentsMap[ConnectedFormComponents.DATE_PICKER];
    };

export type DialogContentInput = {
  description: string | ReactNode;
  inputs:
    | (MappedInput & { key: string })[]
    | (MappedInput & { key: string })[][];
  onSubmit: (values: Record<string, unknown>) => void | Promise<void>;
  schema?: AnyObjectSchema;
};

export function isDialogContentInput(
  content: DialogContentInput | ReactNode
): content is DialogContentInput {
  return !!content && typeof content === 'object' && 'inputs' in content;
}

export type DialogProps = {
  content: ReactNode | DialogContentInput;
  title: string;
  actions?: ReactNode;
  customDialogProps?: CustomDialogProps;
};

export type DialogRef = {
  openDialog: (props: DialogProps) => void;
  updateOpenedDialog: (props: Partial<DialogProps>) => void;
  closeDialog: () => void;
};
