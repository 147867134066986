import { useMemo } from 'react';
import { Control } from 'react-hook-form';

import AutoComplete, {
  AutoCompletePropsWithoutConnectionProps,
} from './AutoComplete';
import Checkbox, { CheckboxPropsWithoutConnectionProps } from './Checkbox';
import DatePicker, {
  DatePickerPropsWithoutConnectionProps,
} from './DatePicker';
import TextInput, { TextInputPropsWithoutConnectionProps } from './TextInput';

export type { TextInputPropsWithoutConnectionProps } from './TextInput';
export type { DatePickerPropsWithoutConnectionProps } from './DatePicker';
export type { CheckboxPropsWithoutConnectionProps } from './Checkbox';
export type { AutoCompletePropsWithoutConnectionProps } from './AutoComplete';

export enum ConnectedFormComponents {
  TEXT_INPUT = 'TextInput',
  DATE_PICKER = 'DatePicker',
  CHECKBOX = 'Checkbox',
  AUTOCOMPLETE = 'AutoComplete',
}

export type ConnectedFormComponentsMap = {
  [ConnectedFormComponents.TEXT_INPUT]: TextInputPropsWithoutConnectionProps<unknown>;
  [ConnectedFormComponents.DATE_PICKER]: DatePickerPropsWithoutConnectionProps<unknown>;
  [ConnectedFormComponents.CHECKBOX]: CheckboxPropsWithoutConnectionProps<unknown>;
  [ConnectedFormComponents.AUTOCOMPLETE]: AutoCompletePropsWithoutConnectionProps<
    unknown,
    unknown,
    undefined,
    undefined,
    undefined
  >;
};

export { default as AutoComplete } from './AutoComplete';

export function useConnectedFormComponents<FormType>({
  control,
}: {
  control: Control<FormType>;
}) {
  const value = useMemo(
    () => ({
      TextInput: (props: TextInputPropsWithoutConnectionProps<FormType>) => (
        <TextInput {...props} control={control} />
      ),
      Checkbox: (props: CheckboxPropsWithoutConnectionProps<FormType>) => (
        <Checkbox {...props} control={control} />
      ),
      AutoComplete<
        T,
        Multiple extends boolean | undefined,
        DisableClearable extends boolean | undefined,
        FreeSolo extends boolean | undefined
      >(
        props: AutoCompletePropsWithoutConnectionProps<
          FormType,
          T,
          Multiple,
          DisableClearable,
          FreeSolo
        >
      ) {
        return <AutoComplete {...props} control={control} />;
      },
      DatePicker: (props: DatePickerPropsWithoutConnectionProps<FormType>) => (
        <DatePicker {...props} control={control} />
      ),
    }),
    [control]
  );
  return value;
}
