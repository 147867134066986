import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import {
  ExpandMore,
  FileCopy,
  History,
  MonetizationOn,
  Warning,
} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  ChipProps,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
  TypographyProps,
} from '@mui/material';
import download from 'downloadjs';
import { useSnackbar } from 'notistack';

import CommentsContainer from '@/components/CommentsContainer';
import { closeDialog } from '@/components/Dialog';
import openConfirmDialog from '@/components/openConfirmDialog';
import { formatCurrency } from '@/helpers/currency';
import { formatDate, formatDateWithTime } from '@/helpers/date';
import { formatAmount } from '@/helpers/number';
import { vehicleDisplayName } from '@/helpers/vehicles';
import { useAuthorization } from '@/hooks/auth';
import { useMeta } from '@/hooks/meta';
import AppError from '@/interfaces/AppError';
import { IInstallmentStatus } from '@/interfaces/payments/IInstallment';
import IInvoice, { IInvoiceStatus } from '@/interfaces/payments/IInvoice';
import { ISubscriptionContractType } from '@/interfaces/subscriptions/ICreateSubscriptionContractDTO';
import ISubscription from '@/interfaces/subscriptions/ISubscription';
import { UserRoles } from '@/interfaces/system-users/UserRoles';
// import ChangeVehicle from '@/pages/Subscriptions/ChangeVehicle';
import SubscriptionFiles from '@/pages/Subscriptions/Files';
import FinishSubscription from '@/pages/Subscriptions/FinishSubscription';
import openCreateInvoiceDialog from '@/pages/Subscriptions/Invoices/Create';
import SubscriptionInvoicesList from '@/pages/Subscriptions/Invoices/List';
import invoicesService from '@/services/invoices';
import subscriptionsService from '@/services/subscriptions';

import RenewSubscription from './RenewSubscription';
import Warnings from './Warnings';

function InfoListItem({
  label,
  value,
  to,
}: {
  label: string;
  value: string | number;
  to?: string;
}) {
  return (
    <ListItem style={{ width: 'auto' }}>
      <ListItemText
        primary={label}
        primaryTypographyProps={{
          variant: 'body2',
          color: 'text.secondary',
        }}
        secondary={value}
        secondaryTypographyProps={{
          variant: 'body1',
          color: 'text.primary',
          fontWeight: 'bold',
          component: to ? Link : undefined,
          to: to || '',
        }}
      />
    </ListItem>
  );
}

export default function SubscriptionDetails() {
  const { id } = useParams();
  const { setPageTitle } = useMeta();
  // const [changeVehicleDialogVisible, setChangeVehicleDialogVisible] =
  //   useState(false);
  const [finishSubscriptionDialogVisible, setFinishSubscriptionDialogVisible] =
    useState(false);
  const { checkUserHasRole } = useAuthorization();
  const isAdminUser = useMemo(
    () => checkUserHasRole([UserRoles.ADMIN]),
    [checkUserHasRole]
  );

  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [paymentsLoading, setPaymentsLoading] = useState(false);

  const [subscription, setSubscription] = useState<ISubscription | null>(null);

  const [invoices, setInvoices] = useState<IInvoice[]>([]);

  const [getContractLoading, setGetContractLoading] = useState(false);

  const onPressGetContract = useCallback(
    async (subVehicleId?: string) => {
      setGetContractLoading(true);
      try {
        let type = ISubscriptionContractType.COMPLETE;
        let contractName = `contract-${subscription!.id}.pdf`;
        if (subVehicleId) {
          if (
            subVehicleId ===
            subscription?.vehicles[subscription!.vehicles.length - 1].id
          ) {
            type = ISubscriptionContractType.INITIAL;
            contractName = `contract-initial-${subscription!.id}.pdf`;
          } else {
            type = ISubscriptionContractType.PARTIAL;
            contractName = `contract-${
              subscription!.id
            }-vehicle-${subVehicleId}.pdf`;
          }
        }

        const result = await subscriptionsService.generateContract({
          subscriptionId: subscription!.id,
          type,
          subscriptionVehicleId: subVehicleId,
        });
        download(result, contractName);
        enqueueSnackbar('Contract generated successfully', {
          variant: 'success',
        });
      } catch (error) {
        console.error(error);
        enqueueSnackbar(
          error instanceof AppError
            ? error?.message
            : 'Error on getting contract. Please, try again later',
          { variant: 'error' }
        );
      } finally {
        setGetContractLoading(false);
      }
    },
    [enqueueSnackbar, subscription]
  );

  const loadInvoices = useCallback(async () => {
    setPaymentsLoading(true);
    try {
      if (!subscription) {
        return;
      }
      const loadedInvoices = await invoicesService.listInvoices(
        subscription.id
      );
      if (!loadedInvoices.length) {
        return;
      }
      setInvoices(loadedInvoices);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(
        error instanceof AppError
          ? error?.message
          : 'Error on loading invoices. Please, try again later',
        { variant: 'error' }
      );
    } finally {
      setTimeout(() => {
        setPaymentsLoading(false);
      }, 1000);
    }
  }, [subscription, enqueueSnackbar]);

  useEffect(() => {
    loadInvoices();
  }, [loadInvoices]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        if (!id) {
          navigate('/subscriptions/list');
          return;
        }
        const sub = await subscriptionsService.getSubscription(Number(id));
        if (!sub) {
          navigate('/subscriptions/list');
          return;
        }
        setSubscription(sub);
        setPageTitle(`Subscription details: #${sub.id}`);
      } catch (error) {
        console.error(error);
        enqueueSnackbar(
          error instanceof AppError
            ? error?.message
            : 'Error on loading subscription. Please, try again later',
          { variant: 'error' }
        );
        navigate('/subscriptions/list');
      } finally {
        setLoading(false);
      }
    })();
  }, [id]);

  const paymentStatus = useMemo<{
    label: string;
    color: TypographyProps['color'];
  }>(() => {
    if (invoices.some(inv => inv.status === IInvoiceStatus.PAST_DUE)) {
      return { label: 'Past due', color: 'error' };
    }
    if (invoices.some(inv => inv.status === IInvoiceStatus.RESCHEDULED)) {
      return { label: 'Rescheduled', color: 'warning' };
    }
    if (invoices.some(inv => inv.status === IInvoiceStatus.PAID)) {
      return { label: 'Paid', color: 'success' };
    }
    return { label: 'Pending', color: 'info' };
  }, [invoices]);

  type PaymentSummaryType = {
    totalInvoice: number;
    totalPaid: number;
    totalDue: number;
    totalPastDue: number;
  };
  const paymentsSummary = useMemo<PaymentSummaryType>(
    () =>
      invoices.reduce<PaymentSummaryType>(
        (acc, cur) => {
          acc.totalInvoice += cur.totalAmount;
          cur.installments.forEach(inst => {
            if (inst.status === IInstallmentStatus.PAID) {
              acc.totalPaid += inst.totalAmount;
            }
            if (inst.status === IInstallmentStatus.OPEN) {
              acc.totalDue += inst.totalAmount;
            }
            if (
              inst.status === IInstallmentStatus.PAST_DUE ||
              inst.status === IInstallmentStatus.RESCHEDULED
            ) {
              acc.totalDue += inst.totalAmount;
              acc.totalPastDue += inst.totalAmount;
            }
          });
          return acc;
        },
        {
          totalDue: 0,
          totalInvoice: 0,
          totalPaid: 0,
          totalPastDue: 0,
        }
      ),
    [invoices]
  );
  return (
    <Box sx={{ mt: 1 }}>
      {loading ? (
        <Box>
          <Grid container direction="row" alignItems="center" spacing={4}>
            <Grid item>{loading && <CircularProgress size={32} />}</Grid>
          </Grid>
        </Box>
      ) : (
        subscription && (
          <>
            <Grid container spacing={2} mb={1}>
              <Grid sm={12} md={3} item>
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="h6">Details:</Typography>
                    <List>
                      <InfoListItem
                        label="Vehicle"
                        value={vehicleDisplayName(
                          subscription.currentVehicle.vehicle
                        )}
                        to={`/vehicles/inventory/${subscription.currentVehicle.vehicle.vin}`}
                      />
                      <InfoListItem
                        label="Current mileage"
                        value={formatAmount(
                          subscription.currentVehicle.vehicle.mileage
                        )}
                      />
                      <Stack flexDirection={{ xs: 'column', md: 'row' }}>
                        <InfoListItem
                          label="Start Date"
                          value={formatDateWithTime(subscription.startDate)}
                        />
                        <InfoListItem
                          label="Expected end date"
                          value={formatDate(subscription.expectedEndDate)}
                        />
                      </Stack>
                      {!!subscription.endDate && (
                        <Stack flexDirection={{ xs: 'column', md: 'row' }}>
                          <InfoListItem
                            label="Finish Date"
                            value={formatDateWithTime(subscription.endDate)}
                          />
                          <InfoListItem
                            label="Finish reason"
                            value={subscription.endReason?.toUpperCase() || '-'}
                          />
                          <InfoListItem
                            label="Finish reason description"
                            value={subscription.endReasonDescription || '-'}
                          />
                        </Stack>
                      )}
                      <InfoListItem
                        label="Subscription Type"
                        value={subscription.type.displayName}
                      />
                      <Stack flexDirection={{ xs: 'column', md: 'row' }}>
                        <InfoListItem
                          label="Activation"
                          value={formatCurrency(
                            subscription.currentVehicle.activation
                          )}
                        />
                        <InfoListItem
                          label="Monthly"
                          value={formatCurrency(
                            subscription.currentVehicle.monthly
                          )}
                        />
                      </Stack>
                      <Stack flexDirection={{ md: 'column' }}>
                        <InfoListItem
                          label="Customer"
                          value={`${subscription.customer.name} (Phone: ${subscription.customer.phoneNumber})`}
                          to={`/customers/list/${subscription.customer.id}`}
                        />
                        <InfoListItem
                          label="Sales"
                          value={`${subscription.sales.name} (${subscription.sales.email})`}
                          to={`/system-users/${subscription.sales.id}`}
                        />
                        {subscription.renewedFromSubscription && subscription.renewedFromSubscription.id && (
                          <InfoListItem
                            label="Renewed From:"
                            value={`#${subscription.renewedFromSubscription.id}`}
                            to={`/subscriptions/list/${subscription.renewedFromSubscription.id}`}
                          />
                        )}
                      </Stack>
                    </List>
                  </CardContent>
                  <CardActions>
                    <Stack
                      direction="row"
                      flex={1}
                      gap={1}
                      justifyContent="flex-end"
                      flexWrap="wrap"
                    >
                      <Button
                        color="info"
                        variant="outlined"
                        disabled={getContractLoading}
                        onClick={() => onPressGetContract()}
                      >
                        {getContractLoading
                          ? 'Generating contract...'
                          : 'Download contract'}
                      </Button>
                      <Button
                        color="secondary"
                        variant="outlined"
                        disabled={!!subscription.endDate || !isAdminUser}
                        onClick={() =>
                          navigate(
                            `/subscriptions/list/${subscription.id}/change-vehicle`
                          )
                        }
                      >
                        Change Vehicle
                      </Button>
                      <RenewSubscription subscription={subscription} />
                      {!subscription.endDate ? (
                        <Button
                          color="primary"
                          variant="contained"
                          disabled={!!subscription.endDate || !isAdminUser}
                          onClick={() =>
                            setFinishSubscriptionDialogVisible(true)
                          }
                        >
                          Finish Subscription
                        </Button>
                      ) : (
                        <Button
                          color="primary"
                          variant="contained"
                          disabled={!subscription.endDate}
                          onClick={() =>
                            openConfirmDialog({
                              onConfirm: async () => {
                                try {
                                  await subscriptionsService.reopenSubscription(
                                    subscription.id
                                  );
                                  enqueueSnackbar(
                                    'Subscription reopened successfully',
                                    {
                                      variant: 'success',
                                    }
                                  );
                                  closeDialog();
                                  navigate(0);
                                } catch (e) {
                                  enqueueSnackbar(
                                    'There was an error to reopen your subscription',
                                    {
                                      variant: 'error',
                                    }
                                  );
                                }
                              },
                            })
                          }
                        >
                          Re open Subscription
                        </Button>
                      )}
                    </Stack>
                  </CardActions>
                </Card>
              </Grid>
              <Grid sm md item>
                <Card variant="outlined">
                  <CardContent>
                    <>
                      <Stack mb={2}>
                        <Stack
                          direction="row"
                          justifySelf="flex-start"
                          alignItems="center"
                          spacing={1}
                        >
                          <Warning />
                          <Typography variant="h6">Warnings</Typography>
                        </Stack>

                        <Warnings
                          subscriptionId={subscription.id}
                          defaultValue={subscription.warnings}
                        />
                      </Stack>

                      <Stack
                        direction="row"
                        mb={2}
                        justifyContent="space-between"
                      >
                        <Stack
                          direction="row"
                          justifySelf="flex-start"
                          alignItems="center"
                          spacing={1}
                        >
                          <MonetizationOn />
                          <Typography variant="h6">Payment info</Typography>
                          <Chip
                            color={paymentStatus.color as ChipProps['color']}
                            label={paymentStatus.label}
                          />
                        </Stack>
                        <Stack direction="row" spacing={1}>
                          <Button
                            variant="outlined"
                            color="secondary"
                            disabled={!isAdminUser || paymentsLoading}
                            onClick={() => {
                              openCreateInvoiceDialog({
                                onSubmitSuccess: () => loadInvoices(),
                                subscriptionId: subscription.id!,
                              });
                            }}
                          >
                            Add new invoice
                          </Button>
                          <Button
                            disabled={!isAdminUser || paymentsLoading}
                            variant="contained"
                            component={Link}
                            to={`/payments/pay/${subscription.id}`}
                          >
                            Register payment
                          </Button>
                        </Stack>
                      </Stack>

                      <Stack
                        direction="row"
                        justifySelf="flex-start"
                        alignItems="center"
                        spacing={1}
                      >
                        <InfoListItem
                          label="Total Invoice"
                          value={formatCurrency(paymentsSummary.totalInvoice)}
                        />
                        <InfoListItem
                          label="Total Paid"
                          value={formatCurrency(paymentsSummary.totalPaid)}
                        />
                        <InfoListItem
                          label="Total Due"
                          value={formatCurrency(paymentsSummary.totalDue)}
                        />
                        <InfoListItem
                          label="Total Past Due"
                          value={formatCurrency(paymentsSummary.totalPastDue)}
                        />
                      </Stack>
                      <Typography variant="h6">Invoices:</Typography>
                      <Divider />
                      <SubscriptionInvoicesList
                        invoices={invoices}
                        subscriptionVehicles={subscription?.vehicles ?? []}
                        onChangeData={() => {
                          loadInvoices();
                        }}
                      />
                    </>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Box marginBottom={2}>
              <CommentsContainer
                moduleName="subscriptions"
                entityId={subscription!.id.toString()}
              />
            </Box>

            <Accordion sx={{ marginBottom: 2 }}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
              >
                <Stack gap={1} direction="row" alignItems="center">
                  <FileCopy />
                  <Typography variant="h6">Subscription files</Typography>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <SubscriptionFiles subscriptionId={subscription!.id} />
              </AccordionDetails>
            </Accordion>

            <Accordion sx={{ marginBottom: 2 }}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
              >
                <Stack gap={1} direction="row" alignItems="center">
                  <History />
                  <Typography variant="h6">
                    Vehicles history ({subscription.vehicles.length})
                  </Typography>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                {subscription.vehicles.map((subVehicle, index) => (
                  <Box key={subVehicle.id}>
                    <List>
                      <InfoListItem
                        label={`Vehicle #${
                          subscription.vehicles.length - index
                        }`}
                        value={vehicleDisplayName(subVehicle.vehicle)}
                        to={`/vehicles/inventory/${subVehicle.vehicle.vin}`}
                      />
                      <Stack flexDirection={{ xs: 'column', md: 'row' }}>
                        <InfoListItem
                          label="Start Date"
                          value={formatDateWithTime(subVehicle.startDate)}
                        />
                        {subVehicle.endDate && (
                          <InfoListItem
                            label="End Date"
                            value={formatDate(subVehicle.endDate)}
                          />
                        )}
                      </Stack>
                      <Stack flexDirection={{ xs: 'column', md: 'row' }}>
                        <InfoListItem
                          label="Activation (W/O Tax)"
                          value={formatCurrency(
                            subVehicle.activationWithoutTax
                          )}
                        />
                        <InfoListItem
                          label="Monthly (W/O Tax)"
                          value={formatCurrency(subVehicle.monthlyWithoutTax)}
                        />
                      </Stack>
                      <Stack
                        alignItems="center"
                        flexDirection={{ xs: 'column', md: 'row' }}
                      >
                        <Stack
                          flex={1}
                          flexDirection={{ xs: 'column', md: 'row' }}
                        >
                          <InfoListItem
                            label="Start mileage"
                            value={formatAmount(subVehicle.startMileage)}
                          />
                          {subVehicle.endMileage && (
                            <InfoListItem
                              label="End mileage"
                              value={formatAmount(subVehicle.endMileage)}
                            />
                          )}
                        </Stack>
                        <Stack>
                          <Button
                            color="info"
                            variant="outlined"
                            disabled={getContractLoading}
                            onClick={() => onPressGetContract(subVehicle.id)}
                          >
                            {getContractLoading && 'Generating contract...'}
                            {!getContractLoading &&
                              (index === subscription.vehicles.length - 1
                                ? 'Download initial contract'
                                : 'Download contract page')}
                          </Button>
                        </Stack>
                      </Stack>
                    </List>

                    <Divider />
                  </Box>
                ))}
              </AccordionDetails>
            </Accordion>

            {/* <ChangeVehicle */}
            {/*  open={changeVehicleDialogVisible} */}
            {/*  onClose={() => setChangeVehicleDialogVisible(false)} */}
            {/*  subscription={subscription} */}
            {/*  onSubmitSuccess={() => { */}
            {/*    setChangeVehicleDialogVisible(false); */}
            {/*    navigate(`/subscriptions/list`); */}
            {/*  }} */}
            {/* /> */}

            <FinishSubscription
              open={finishSubscriptionDialogVisible}
              onClose={() => setFinishSubscriptionDialogVisible(false)}
              subscription={subscription}
              onSubmitSuccess={() => {
                setFinishSubscriptionDialogVisible(false);
                navigate(`/subscriptions/list`);
              }}
            />
          </>
        )
      )}
    </Box>
  );
}
