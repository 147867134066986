import { useEffect, useMemo, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

import { useConnectedFormComponents } from '@/components/form';
import { currencyMask, numericMask } from '@/constants/mask';
import { useAuthorization } from '@/hooks/auth';
import { useMeta } from '@/hooks/meta';
import AppError from '@/interfaces/AppError';
import { IMaskInput } from '@/interfaces/IMaskInput';
import { UserRoles } from '@/interfaces/system-users/UserRoles';
import { IVehicleGPS } from '@/interfaces/vehicles/IVehicleGPS';
import MaintenanceExpenses from '@/pages/Maintenance/View/MaintenanceExpenses';
import VehicleMaintenanceHistory from '@/pages/Maintenance/View/VehicleMaintenanceHistory';
import vehiclesService from '@/services/vehicles';

type Form = {
  vin: string;
  category: string;
  brand: string;
  model: string;
  trim: string;
  engine: string;
  year: string;
  internalColor: string;
  customInternalColor: string;
  externalColor: string;
  customExternalColor: string;
  referencePrice: IMaskInput<number>;
  featured: boolean;
  available: boolean;
  listed: boolean;
  mileage: IMaskInput<number>;
  gps: IVehicleGPS[];
};

const schema = Yup.object().shape({
  vin: Yup.string().required('VIN is required'),
  category: Yup.string().required('Category is required'),
  brand: Yup.string().required('Brand is required'),
  model: Yup.string().required('Model is required'),
  trim: Yup.string().required('Trim is required'),
  engine: Yup.string().required('Engine is required'),
  year: Yup.string().required('Year is required'),
  internalColor: Yup.string().required('Internal color is required').nullable(),
  externalColor: Yup.string().required('External color is required').nullable(),
  referencePrice: Yup.object()
    .typeError('Reference price is required')
    .shape({
      masked: Yup.string().required(),
      unmasked: Yup.number().required(),
    })
    .required('Reference price is required'),
  mileage: Yup.object()
    .typeError('Mileage is required')
    .shape({
      masked: Yup.string().required(),
      unmasked: Yup.number().required(),
    })
    .required('Mileage is required'),
  gps: Yup.array().of(Yup.object()),
});

export default function FormVehicle() {
  const { vin } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { handleSubmit, watch, setValue, control } = useForm<
    Form & FieldValues
  >({
    defaultValues: {
      vin,
      available: true,
      listed: true,
    },
    resolver: yupResolver(schema),
  });
  const { TextInput, AutoComplete, Checkbox } =
    useConnectedFormComponents<Form>({
      control,
    });
  const { checkUserHasRole } = useAuthorization();

  const editableVin = watch('vin');
  const interiorColorSelected = watch('internalColor');
  const exteriorColorSelected = watch('externalColor');

  const { setPageTitle } = useMeta();
  const isEditing = useMemo(
    () => !!vin && searchParams.get('edit') === 'true',
    [vin, searchParams]
  );
  const isViewing = useMemo(
    () => !!vin && searchParams.get('edit') !== 'true',
    [vin, searchParams]
  );
  const isNew = !isViewing && !isEditing;
  const [vehicleInfoLoaded, setVehicleInfoLoaded] = useState(!!vin);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [interiorColors, setInteriorColors] = useState<string[]>([]);
  const [exteriorColors, setExteriorColors] = useState<string[]>([]);
  const [gpsList, setGpsList] = useState<IVehicleGPS[]>([]);
  const [infoPrefilled, setInfoPrefilled] = useState(false);

  useEffect(() => {
    if (
      (isEditing || (!isEditing && !isViewing)) &&
      !checkUserHasRole([UserRoles.ADMIN])
    ) {
      navigate('/', { replace: true });
    }
  }, [isEditing, checkUserHasRole]);

  useEffect(() => {
    if (isEditing) {
      setPageTitle('Edit Vehicle');
    } else if (isViewing) {
      setPageTitle('View Vehicle');
    } else {
      setPageTitle('New Vehicle');
    }
  }, [isEditing, isViewing, setPageTitle]);

  const onSubmit = async (data: Form) => {
    if (isViewing) {
      return;
    }
    try {
      setSubmitLoading(true);
      if (isEditing && vin) {
        await vehiclesService.updateVehicle(vin, {
          mileage: data.mileage.unmasked,
          available: data.available,
          listed: data.listed,
          featured: data.featured,
          internalColor: data.internalColor,
          externalColor: data.externalColor,
          trim: data.trim,
          referencePrice: data.referencePrice.unmasked,
          gps: data.gps?.map(gps => gps.number) ?? [],
        });
      } else {
        await vehiclesService.createVehicle({
          brand: data.brand,
          model: data.model,
          trim: data.trim,
          engine: data.engine,
          year: parseInt(data.year, 10),
          vin: data.vin,
          category: data.category,
          featured: data.featured,
          available: data.available,
          listed: data.listed,
          mileage: data.mileage.unmasked,
          referencePrice: data.referencePrice.unmasked,
          internalColor: data.customInternalColor || data.internalColor,
          externalColor: data.customExternalColor || data.externalColor,
          gps: data.gps?.map(gps => gps.number) ?? [],
        });
      }

      navigate('/vehicles/inventory');
    } catch (error) {
      console.error(error);
      enqueueSnackbar(
        error instanceof AppError
          ? error?.message
          : 'Error on creating vehicle. Please, try again later',
        { variant: 'error' }
      );
    } finally {
      setSubmitLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (isNew && editableVin && editableVin.length === 17) {
        setLoading(true);
        try {
          const vehicleInfo = await vehiclesService.getSpecifications(
            editableVin
          );

          setValue('category', vehicleInfo.style);
          setValue('brand', vehicleInfo.make);
          setValue('model', vehicleInfo.model);
          setValue('year', vehicleInfo.year);
          setValue('trim', vehicleInfo.trim);
          setValue('engine', vehicleInfo.engine);
          setInteriorColors(vehicleInfo.interiorColors || []);
          setExteriorColors(vehicleInfo.exteriorColors || []);
          setInfoPrefilled(true);
          setVehicleInfoLoaded(true);
        } catch (error) {
          enqueueSnackbar(
            'Error loading vehicle info. Please, fill the information manually or try again later',
            {
              variant: 'error',
            }
          );
          setVehicleInfoLoaded(true);
        } finally {
          setLoading(false);
        }
      } else if (!isNew && vin) {
        setLoading(true);
        try {
          const vehicle = await vehiclesService.getVehicle(vin);
          const vehicleInfo = await vehiclesService.getSpecifications(vin);

          const baseInteriorColors = vehicleInfo.interiorColors || [];
          const baseExteriorColors = vehicleInfo.exteriorColors || [];

          setInteriorColors(
            baseInteriorColors.concat(
              baseInteriorColors.includes(vehicle.internalColor)
                ? []
                : [vehicle.internalColor]
            )
          );
          setExteriorColors(
            baseExteriorColors.concat(
              baseExteriorColors.includes(vehicle.externalColor)
                ? []
                : [vehicle.externalColor]
            )
          );

          setValue('category', vehicle.category.name);
          setValue('brand', vehicle.brand.name);
          setValue('model', vehicle.model);
          setValue('year', String(vehicle.year));
          setValue('trim', vehicle.trim);
          setValue('engine', vehicle.engine);
          setValue('internalColor', vehicle.internalColor);
          setValue('externalColor', vehicle.externalColor);
          setValue('referencePrice', {
            masked: String(vehicle.referencePrice),
            unmasked: vehicle.referencePrice,
          });
          setValue('mileage', {
            masked: String(vehicle.mileage),
            unmasked: vehicle.mileage,
          });
          setValue('gps', vehicle.gps);

          setValue('available', vehicle.available);
          setValue('featured', vehicle.featured);
          setValue('listed', vehicle.listed);

          setInfoPrefilled(true);
          setVehicleInfoLoaded(true);
        } catch (error) {
          console.error(error);
          enqueueSnackbar(
            'Error loading vehicle info. Please, fill the information manually or try again later',
            {
              variant: 'error',
            }
          );
          setVehicleInfoLoaded(true);
        } finally {
          setLoading(false);
        }
      }
    })();
  }, [vin, isEditing, editableVin, isNew]);

  useEffect(() => {
    (async () => {
      try {
        const gps = await vehiclesService.listGps(vin ? undefined : true);
        setGpsList(gps);
      } catch (error) {
        enqueueSnackbar('Error loading GPS list. Please, try again later', {
          variant: 'error',
        });
      }
    })();
  }, []);

  return (
    <>
      <Stack direction="row" spacing={2}>
        {!isNew && (
          <Button
            variant="outlined"
            disabled={loading || submitLoading}
            onClick={() => navigate(`/vehicles/inventory/${vin}/images`)}
          >
            Go To Vehicle Images
          </Button>
        )}
        {isViewing && (
          <Button
            variant="outlined"
            disabled={
              loading || submitLoading || !checkUserHasRole([UserRoles.ADMIN])
            }
            onClick={() => navigate(`/vehicles/inventory/${vin}?edit=true`)}
          >
            Edit Vehicle
          </Button>
        )}
        {!isViewing && (
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading || submitLoading}
            onClick={handleSubmit(onSubmit)}
          >
            {loading || submitLoading ? 'Loading...' : 'Submit'}
          </Button>
        )}
      </Stack>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        sx={{ mt: 6, mb: 12 }}
      >
        {isNew && (
          <Typography variant="body1">
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            Please, provide vehicle's VIN, so we can pre-load some details from
            it:
          </Typography>
        )}
        <Box>
          <Grid container direction="row" alignItems="center" spacing={4}>
            <Grid item>
              <TextInput
                margin="normal"
                required
                label="Vin number"
                autoFocus
                fullWidth={false}
                disabled={!isNew}
                inputProps={{
                  maxLength: 17,
                }}
                fieldName="vin"
              />
            </Grid>
            <Grid item>{loading && <CircularProgress size={32} />}</Grid>
          </Grid>
        </Box>
        {vehicleInfoLoaded && (
          <>
            {!isEditing && (
              <Typography variant="body1">Vehicle details:</Typography>
            )}
            <Grid container direction={{ xs: 'column', md: 'row' }} spacing={2}>
              <Grid item xs={3}>
                <TextInput
                  margin="normal"
                  required
                  label="Category"
                  disabled={infoPrefilled || isViewing}
                  fullWidth
                  fieldName="category"
                />
              </Grid>
              <Grid item xs={3}>
                <TextInput
                  margin="normal"
                  required
                  label="Brand"
                  disabled={infoPrefilled || isViewing}
                  fullWidth
                  fieldName="brand"
                />
              </Grid>
              <Grid item xs={3}>
                <TextInput
                  margin="normal"
                  required
                  label="Model"
                  disabled={infoPrefilled || isViewing}
                  fullWidth
                  fieldName="model"
                />
              </Grid>
              <Grid item xs={3}>
                <TextInput
                  margin="normal"
                  required
                  label="Year"
                  disabled={infoPrefilled || isViewing}
                  fullWidth
                  fieldName="year"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} direction={{ xs: 'column', md: 'row' }}>
              <Grid item xs={2}>
                <TextInput
                  margin="normal"
                  required
                  label="Engine"
                  disabled={infoPrefilled || isViewing}
                  fullWidth
                  fieldName="engine"
                />
              </Grid>
              <Grid item xs={2}>
                <TextInput
                  margin="normal"
                  required
                  label="Trim"
                  fullWidth
                  disabled={isViewing}
                  fieldName="trim"
                />
              </Grid>
              <Grid
                item
                container
                spacing={2}
                direction={{ xs: 'column', md: 'row' }}
                xs={4}
              >
                <Grid item xs={interiorColorSelected === 'Other' ? 6 : 12}>
                  <AutoComplete
                    options={[...interiorColors, 'Other']}
                    fieldName="internalColor"
                    label="Interior color"
                    disabled={isViewing}
                  />
                </Grid>
                {interiorColorSelected === 'Other' && (
                  <Grid item xs={6}>
                    <TextInput
                      fullWidth
                      margin="normal"
                      required
                      label="Custom interior color"
                      fieldName="customInternalColor"
                      disabled={isViewing}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid
                item
                xs={4}
                container
                spacing={2}
                direction={{ xs: 'column', md: 'row' }}
              >
                <Grid item xs={exteriorColorSelected === 'Other' ? 6 : 12}>
                  <AutoComplete
                    options={[...exteriorColors, 'Other']}
                    fieldName="externalColor"
                    disabled={isViewing}
                    label="Exterior color"
                  />
                </Grid>

                {exteriorColorSelected === 'Other' && (
                  <Grid item xs={6}>
                    <TextInput
                      fullWidth
                      margin="normal"
                      required
                      label="Custom exterior color"
                      fieldName="customExternalColor"
                      disabled={isViewing}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid
                item
                container
                direction={{ xs: 'column', md: 'row' }}
                justifyContent="flex-start"
                alignItems="center"
                xs={2}
              >
                <Grid item>
                  <Checkbox
                    fieldName="featured"
                    label="Featured"
                    disabled={isViewing}
                  />
                </Grid>
                <Grid item>
                  <Checkbox
                    fieldName="available"
                    label="Available"
                    disabled={isViewing}
                  />
                </Grid>
                <Grid item>
                  <Checkbox
                    fieldName="listed"
                    label="Listed"
                    disabled={isViewing}
                  />
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <TextInput
                  margin="normal"
                  required
                  label="Reference price"
                  fullWidth
                  disabled={isViewing}
                  fieldName="referencePrice"
                  maskProps={currencyMask()}
                />
              </Grid>
              <Grid item xs={3}>
                <TextInput
                  margin="normal"
                  required
                  label="Mileage"
                  fullWidth
                  fieldName="mileage"
                  disabled={isViewing}
                  maskProps={numericMask(0)}
                />
              </Grid>
              <Grid item xs={3}>
                <AutoComplete
                  multiple
                  options={gpsList}
                  freeSolo={false}
                  getOptionLabel={gps => gps?.number ?? ''}
                  isOptionEqualToValue={(option, value) =>
                    option.number === value.number
                  }
                  disabled={isViewing}
                  fieldName="gps"
                  fullWidth
                  label="GPS"
                  textFieldProps={{
                    required: false,
                  }}
                  defaultValue={[]}
                />
              </Grid>
            </Grid>
            {vin && (
              <Stack gap={2} direction={{ md: 'row', sm: 'column' }}>
                <Box sx={{ width: '100%' }}>
                  <VehicleMaintenanceHistory vehicleId={vin} />
                </Box>

                <Box sx={{ width: '100%' }}>
                  <MaintenanceExpenses vehicleId={vin} />
                </Box>
              </Stack>
            )}
          </>
        )}
      </Box>
    </>
  );
}
