import { useCallback, useEffect, useRef, useState } from 'react';

import { Box, Button, List, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import { closeDialog } from '@/components/Dialog';
import { formatCurrency } from '@/helpers/currency';
import { formatDate } from '@/helpers/date';
import IPayment from '@/interfaces/payments/IPayment';
import paymentsService from '@/services/payments';

type DetailsProps = {
  title: string;
  value: string | number;
};

function Detail({ title, value }: DetailsProps) {
  return (
    <li>
      <Stack>
        <Typography variant="body2">{title}</Typography>
        <Typography variant="body1" fontWeight="bold">
          {value}
        </Typography>
      </Stack>
    </li>
  );
}

type Props = {
  payment: IPayment;
  refundPayment?: IPayment &
    Required<{ refundPaymentId: IPayment['refundPaymentId'] }>;
};

export default function PaymentDetails({ payment, refundPayment }: Props) {
  return (
    <List
      sx={{
        '> *:not(:last-child)': {
          mb: 2,
        },
      }}
    >
      <Detail title="Payment ID" value={payment.id} />
      {refundPayment && (
        <Detail title="Refund Payment ID" value={refundPayment.id} />
      )}
      <Stack flex={1} direction="row" gap={2}>
        <Box flex={1}>
          <Detail title="Amount" value={formatCurrency(payment.amount)} />
        </Box>
        <Box flex={1}>
          <Detail title="Charge Type" value={payment.type} />
        </Box>
      </Stack>
      <Detail title="Payment Gateway" value={payment.gateway} />
      {payment.gateway.toLowerCase() !== 'manual' &&
        !!payment.gatewayExternalPaymentId && (
          <Detail
            title="Payment Gateway External ID"
            value={payment.gatewayExternalPaymentId}
          />
        )}
      {!!payment.cardEnd && (
        <Detail title="Card Last 4 Digits" value={payment.cardEnd} />
      )}
      <Stack flex={1} direction="row" gap={2}>
        <Box flex={1}>
          <Detail title="Created at" value={formatDate(payment.createdAt)} />
        </Box>
        {refundPayment && (
          <Box flex={1}>
            <Detail
              title="Refunded at"
              value={formatDate(refundPayment.createdAt)}
            />
          </Box>
        )}
      </Stack>
    </List>
  );
}

type PaymentDetailsActionsProps = Props & {
  onRefund?: () => void | Promise<void>;
};

function PaymentDetailsActions({
  payment,
  onRefund,
  refundPayment,
}: PaymentDetailsActionsProps) {
  const [selected, setSelected] = useState(false);
  const [timeLeft, setTimeLeft] = useState(-1);
  const timer = useRef<number | null>(null);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const clearTimer = useCallback(() => {
    if (timer.current != null) {
      clearInterval(timer.current);
    }
    timer.current = null;
  }, []);

  useEffect(() => {
    if (selected) {
      timer.current = setTimeout(() => {
        setSelected(false);
      }, 5000);
    }

    return clearTimer;
  }, [selected, clearTimer]);

  useEffect(() => {
    if (timeLeft >= 0) {
      setTimeout(() => {
        setTimeLeft(prev => prev - 1);
      }, 1000);
    }
  }, [timeLeft]);

  return (
    <Button
      disabled={!!payment.refundPaymentId || loading || !!refundPayment}
      color="error"
      variant={selected ? 'contained' : 'outlined'}
      onClick={async () => {
        if (!selected) {
          setTimeLeft(5);
          setSelected(true);
          return;
        }
        setLoading(true);
        setTimeLeft(-1);
        clearTimer();
        try {
          await paymentsService.refundPayment(payment.id);
          enqueueSnackbar('Payment refund successful', {
            variant: 'success',
          });
          setLoading(false);
          if (onRefund) {
            onRefund();
          }
          closeDialog();
        } catch (e) {
          setLoading(false);
          console.error(e);
          enqueueSnackbar('There was an error on refund the payment', {
            variant: 'error',
          });
        }
      }}
    >
      {!loading &&
        (selected && timeLeft > 0
          ? `(${timeLeft}s) Are you sure? (Yes, refund)`
          : 'Refund')}
      {loading && 'Loading...'}
    </Button>
  );
}

PaymentDetails.Actions = PaymentDetailsActions;
