import { Delete, Edit } from '@mui/icons-material';
import {
  IconButton,
  ListItem,
  ListItemText,
  Stack,
  Tooltip,
} from '@mui/material';

interface Props {
  description: string;
  onClickEdit: () => void;
  onClickRemove: () => void;
}

export default function TaskItem(props: Props) {
  const { description, onClickEdit, onClickRemove } = props;

  return (
    <ListItem
        sx={{
          borderRadius: 1,
          border: '1px solid #e0e0e0',

          '&:hover': {
            backgroundColor: '#f5f5f5',
          },
        }}
        secondaryAction={
          <Stack direction="row" gap={1}>
            <Tooltip title="Edit task">
              <IconButton edge="end" onClick={onClickEdit}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete task">
              <IconButton edge="end" onClick={onClickRemove}>
                <Delete />
              </IconButton>
            </Tooltip>
          </Stack>
        }
      >
        <ListItemText primary={description} />
      </ListItem>
  );
}
