import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import download from 'downloadjs';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

import { useConnectedFormComponents } from '@/components/form';
import GridRowResponsive from '@/components/GridRowResponsive';
import { currencyMask } from '@/constants/mask';
import { formatCurrency } from '@/helpers/currency';
import { formatDate } from '@/helpers/date';
import { formatAmount } from '@/helpers/number';
import {
  emptyStringToNull,
  vehicleAndSubTypeSelectedErrorString,
} from '@/helpers/validations';
import { vehicleDisplayName } from '@/helpers/vehicles';
import AppError from '@/interfaces/AppError';
import IGetSubscriptionPriceResult from '@/interfaces/subscriptions/IGetSubscriptionPriceResult';
import ISubscriptionType from '@/interfaces/subscriptions/ISubscriptionType';
import IVehicle from '@/interfaces/vehicles/IVehicle';
import subscriptionsService from '@/services/subscriptions';
import vehiclesService from '@/services/vehicles';

const schema = Yup.object().shape({
  vehicle: Yup.object().required('Vehicle is required'),
  subscriptionType: Yup.object().required('Subscription type is required'),
  activationValue: Yup.object()
    .shape({
      masked: Yup.string().required(),
      unmasked: Yup.number()
        .transform(emptyStringToNull)
        .min(0, 'Activation value must be greater than 0')
        .nullable(),
    })
    .nullable(),
  dealerFee: Yup.object()
    .typeError('Dealer Fee is required')
    .shape({
      masked: Yup.string().required(),
      unmasked: Yup.number()
        .transform(emptyStringToNull)
        .min(0, 'Deealer Fee value must be greater than or equal to 0'),
    }),
  monthlyValue: Yup.object()
    .shape({
      masked: Yup.string().required(),
      unmasked: Yup.number()
        .transform(emptyStringToNull)
        .min(0, 'Monthly value must be greater than 0')
        .nullable(),
    })
    .nullable(),
});

type Form = {
  vehicle: IVehicle;
  subscriptionType: ISubscriptionType;
  dealerFee: {
    masked: string;
    unmasked: number;
  };
  activationValue?: {
    masked: string;
    unmasked: string;
  };
  monthlyValue?: {
    masked: string;
    unmasked: string;
  };
};

type SimulationData = IGetSubscriptionPriceResult & {
  vehicle: IVehicle;
  subscriptionType: ISubscriptionType;
};

const SURCHARGE_FEE = 64.2;

export default function SimulateSubscription() {
  const { control, watch, setValue, handleSubmit } = useForm<Form>({
    resolver: yupResolver(schema),
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const { AutoComplete, TextInput } = useConnectedFormComponents<Form>({
    control,
  });
  const [vehiclesList, setVehiclesList] = useState<IVehicle[]>([]);
  const [subscriptionTypeList, setSubscriptionTypeList] = useState<
    ISubscriptionType[]
  >([]);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [onChangeLoading, setOnChangeLoading] = useState(false);
  const [simulationResult, setSimulationResult] =
    useState<SimulationData | null>(null);

  const subscriptionTypeSelected = watch('subscriptionType');
  const vehicleSelected = watch('vehicle');
  const activationValue = watch('activationValue');
  const monthlyValue = watch('monthlyValue');
  const dealerFee = watch('dealerFee');

  const onSubmit = useCallback(async (data: Form) => {
    setSubmitLoading(true);
    const result = await subscriptionsService.generateSimulation({
      subscriptionTypeId: data.subscriptionType.id,
      vin: data.vehicle.vin,
      activationValue: Number(data.activationValue?.unmasked) || undefined,
      monthlyValue: Number(data.monthlyValue?.unmasked) || undefined,
      dealerFee: Number(data.dealerFee.unmasked),
    });
    download(result, `simulation-${data.vehicle.vin}.pdf`, 'application/pdf');

    setSubmitLoading(false);
  }, []);

  const onFinishFillForm = useCallback(
    async (
      vehicle: IVehicle,
      subscriptionType: ISubscriptionType,
      values?: Pick<Form, 'activationValue' | 'monthlyValue' | 'dealerFee'>
    ) => {
      setOnChangeLoading(true);
      const result = await subscriptionsService.getPrice({
        subscriptionTypeId: subscriptionType.id,
        vin: vehicle.vin,
        activationValue: Number(values?.activationValue?.unmasked) || undefined,
        monthlyValue: Number(values?.monthlyValue?.unmasked) || undefined,
        dealerFee: values?.dealerFee?.unmasked || 200,
      });
      setSimulationResult({
        ...result,
        vehicle,
        subscriptionType,
      });

      setOnChangeLoading(false);
    },
    []
  );

  useEffect(() => {
    if (vehicleSelected && subscriptionTypeSelected) {
      onFinishFillForm(vehicleSelected, subscriptionTypeSelected, {
        activationValue,
        monthlyValue,
        dealerFee,
      });
    }
  }, [
    dealerFee,
    vehicleSelected,
    subscriptionTypeSelected,
    activationValue,
    monthlyValue,
  ]);

  const fetchInfo = useCallback(async () => {
    setLoading(true);
    try {
      const [vehicles, subscriptionTypes] = await Promise.all([
        vehiclesService.listVehicles(),
        subscriptionsService.listSubscriptionTypes(),
      ]);

      setVehiclesList(vehicles);
      setSubscriptionTypeList(subscriptionTypes);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(
        error instanceof AppError
          ? error?.message
          : 'Error on fetching vehicle information. Please, try again later',
        { variant: 'error' }
      );
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchInfo();
  }, []);

  useEffect(() => {
    const vehicleVinParam = searchParams.get('vin');
    if (!loading && vehicleVinParam && vehiclesList.length) {
      const vehicle = vehiclesList.find(v => v.vin === vehicleVinParam);
      if (vehicle) {
        setValue('vehicle', vehicle);
      }
      searchParams.delete('vin');
      setSearchParams(searchParams, { replace: true });
    }
  }, [loading, searchParams, setSearchParams, setValue, vehiclesList]);

  return (
    <>
      <Card>
        <CardContent>
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              <GridRowResponsive>
                <AutoComplete
                  options={vehiclesList}
                  getOptionLabel={vehicle => vehicleDisplayName(vehicle)}
                  isOptionEqualToValue={(option, value) =>
                    option.vin === value.vin
                  }
                  freeSolo={false}
                  fieldName="vehicle"
                  label="Vehicle"
                />
              </GridRowResponsive>
              <GridRowResponsive>
                <AutoComplete
                  options={subscriptionTypeList}
                  freeSolo={false}
                  getOptionLabel={type => type.displayName}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  fieldName="subscriptionType"
                  label="Subscription Type"
                />
              </GridRowResponsive>
              <GridRowResponsive>
                <TextInput
                  margin="normal"
                  label="Activation Value"
                  fullWidth
                  fieldName="activationValue"
                  InputLabelProps={{
                    shrink:
                      !!subscriptionTypeSelected?.activationBaseValue ||
                      undefined,
                  }}
                  maskProps={currencyMask()}
                  helperText={
                    subscriptionTypeSelected && vehicleSelected
                      ? `Default is: ${formatCurrency(
                          (subscriptionTypeSelected?.activationBaseValue ?? 0) +
                            vehicleSelected.referencePrice *
                              (subscriptionTypeSelected?.activationMultiplierValue ||
                                1)
                        )}`
                      : vehicleAndSubTypeSelectedErrorString(
                          vehicleSelected,
                          subscriptionTypeSelected
                        )
                  }
                />
                <TextInput
                  margin="normal"
                  label="Monthly Value"
                  fullWidth
                  fieldName="monthlyValue"
                  InputLabelProps={{
                    shrink:
                      !!subscriptionTypeSelected?.monthlyBaseValue || undefined,
                  }}
                  maskProps={currencyMask()}
                  helperText={
                    subscriptionTypeSelected && vehicleSelected
                      ? `Default is: ${formatCurrency(
                          (subscriptionTypeSelected?.monthlyBaseValue ?? 0) +
                            vehicleSelected.referencePrice *
                              (subscriptionTypeSelected?.monthlyMultiplierValue ||
                                1)
                        )}`
                      : vehicleAndSubTypeSelectedErrorString(
                          vehicleSelected,
                          subscriptionTypeSelected
                        )
                  }
                />
                <TextInput
                  margin="normal"
                  label="Dealer Fee"
                  fullWidth
                  fieldName="dealerFee"
                  maskProps={currencyMask()}
                  helperText="Default is: $200"
                />
              </GridRowResponsive>
              <Button
                disabled={submitLoading}
                variant="contained"
                onClick={handleSubmit(onSubmit)}
              >
                {submitLoading ? 'Loading...' : 'Submit'}
              </Button>
            </>
          )}
        </CardContent>
      </Card>
      {simulationResult && !loading && !submitLoading && !onChangeLoading && (
        <Card sx={{ my: 1 }}>
          <CardHeader title="Simulation Result" />
          <CardContent>
            <List
              sx={{
                '& .MuiListItem-root': {
                  '& .MuiListItemText-primary': {
                    color: 'text.caption',
                    fontSize: '0.9rem',
                  },
                  '& .MuiListItemText-secondary': {
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    color: 'text.primary',
                  },
                },
              }}
            >
              <GridRowResponsive>
                <ListItem>
                  <ListItemText
                    primary="Vehicle"
                    secondary={vehicleDisplayName(simulationResult.vehicle)}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="VIN"
                    secondary={simulationResult.vehicle.vin}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Mileage"
                    secondary={formatAmount(simulationResult.vehicle.mileage)}
                  />
                </ListItem>
              </GridRowResponsive>
              <GridRowResponsive>
                <ListItem>
                  <ListItemText
                    primary="Monthly Rate"
                    secondary={formatCurrency(simulationResult.monthly)}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Monthly Tax"
                    secondary={formatCurrency(simulationResult.monthlyTax)}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Total Monthly"
                    secondary={formatCurrency(simulationResult.totalMonthly)}
                  />
                </ListItem>
              </GridRowResponsive>
              <GridRowResponsive>
                <ListItem>
                  <ListItemText
                    primary="Activation Payment"
                    secondary={formatCurrency(simulationResult.activation)}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Activation Tax"
                    secondary={formatCurrency(simulationResult.activationTax)}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Total Activation"
                    secondary={formatCurrency(simulationResult.totalActivation)}
                  />
                </ListItem>
              </GridRowResponsive>
              <GridRowResponsive>
                <ListItem>
                  <ListItemText
                    primary="Florida Surcharge Fee (inc. tax)"
                    secondary={formatCurrency(SURCHARGE_FEE)}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Dealer Fee"
                    secondary={formatCurrency(simulationResult.dealerFee)}
                  />
                </ListItem>

                <ListItem>
                  <ListItemText
                    primary="Total Due At Signing"
                    secondary={formatCurrency(
                      simulationResult.totalActivation +
                        simulationResult.totalMonthly +
                        simulationResult.dealerFee +
                        SURCHARGE_FEE
                    )}
                  />
                </ListItem>
              </GridRowResponsive>
              <GridRowResponsive>
                <ListItem>
                  <ListItemText
                    primary="Date"
                    secondary={formatDate(new Date())}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Monthly miles allowed"
                    secondary={
                      simulationResult.subscriptionType.mileageMonthlyLimit
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Mileage overage penalty"
                    secondary={formatCurrency(0.25)}
                  />
                </ListItem>
              </GridRowResponsive>
            </List>
          </CardContent>
        </Card>
      )}
    </>
  );
}
