import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';

import { Task } from '@mui/icons-material';
import { Box, Divider } from '@mui/material';
import { debounce } from 'lodash';
import { useSnackbar } from 'notistack';

import AppError from '@/interfaces/AppError';
import { IMaintenanceTasks } from '@/interfaces/maintenance/IMaintenanceTasks';
import maintenancesService from '@/services/maintenances';

import MaintenanceAccordion from '../MaintenanceAccordion';
import CreateMaintenanceTask from './components/CreateMaintenanceTask';
import MaintenanceTask from './components/MaintenanceTask';

interface Props {
  maintenanceId: string;
  readOnly?: boolean;
  canAddNewTask?: boolean;
}

export default function MaintenanceTasks({ maintenanceId, readOnly, canAddNewTask }: Props) {
  const [tasksInfo, setTasksInfo] = useState<IMaintenanceTasks>();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    (async () => {
      const tasksResponse = await maintenancesService.listMaintenanceTasks({
        maintenanceId,
      });

      setTasksInfo(tasksResponse);
    })();
  }, []);

  const handleOnSubmit = async (tasksData: Record<string, boolean>) => {
    try {
      await maintenancesService.updateMaintenanceTasks({
        maintenanceId,
        tasks: tasksData,
      });

      enqueueSnackbar('Tasks updated with success.', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(
        error instanceof AppError
          ? error?.message
          : 'Error on updating maintenance tasks. Please, try again later',
        { variant: 'error' }
      );
    }
  };

  const debounceSubmit = useCallback(
    debounce((data: Record<string, boolean>) => {
      handleOnSubmit(data);
    }, 500),
    []
  );

  const tasks = useMemo(() => tasksInfo?.tasks || {}, [tasksInfo]);

  const completedTasksCount = useMemo(
    () => Object.values(tasksInfo?.tasks || {}).filter(task => !!task).length,
    [tasksInfo]
  );

  const taskCount = useMemo(
    () => Object.keys(tasksInfo?.tasks || {}).length,
    [tasksInfo]
  );

  return (
    <MaintenanceAccordion
      title={`Tasks (${completedTasksCount}/${taskCount})`}
      icon={<Task />}
    >
      {canAddNewTask && (
        <CreateMaintenanceTask
          maintenanceId={maintenanceId}
          onCreate={info => setTasksInfo(info)}
        />
      )}

      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {Object.keys(tasks).map((taskInfo, index) => (
          <Fragment key={`task-info-${taskInfo}`}>
            <MaintenanceTask
              checked={!!tasks[taskInfo]}
              description={taskInfo}
              readOnly={readOnly}
              onChange={checked => {
                setTasksInfo(prev => {
                  if (!prev) {
                    return prev;
                  }
                  const updatedTasks = { ...prev };
                  updatedTasks.tasks[taskInfo] = checked;
                  debounceSubmit(updatedTasks.tasks);
                  return updatedTasks;
                });
              }}
            />

            {index < taskCount - 1 && <Divider />}
          </Fragment>
        ))}
      </Box>
    </MaintenanceAccordion>
  );
}
