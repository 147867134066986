import React from 'react';

import { useDraggable } from '@dnd-kit/core';
import { Box, BoxProps } from '@mui/material';

type Props = {
  draggableId: string;
} & BoxProps;

export function Draggable({ draggableId, children, sx, ...rest }: Props) {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: draggableId,
  });

  return (
    <Box
      ref={setNodeRef}
      sx={{
        cursor: 'grab',
        ...(sx || {}),
      }}
      {...rest}
      {...listeners}
      {...attributes}
    >
      {children}
    </Box>
  );
}
