/* eslint-disable react/no-unstable-nested-components */
import { useEffect, useMemo, useState } from 'react';

import { Box, Chip, CircularProgress, Typography } from '@mui/material';
import { endOfDay, startOfDay, subMonths } from 'date-fns';
import MUIDataTable from 'mui-datatables';

import DatePicker from '@/components/DatePicker';
import DownloadbleDataTable from '@/components/DownloadbleDataTable';
import { formatCurrency } from '@/helpers/currency';
import { formatDateWithTime } from '@/helpers/date';
import IDataTableColumn from '@/interfaces/IDataTableColumn';
import { IPaymentReport } from '@/interfaces/payments/IPayment';
import paymentsService from '@/services/payments';

export default function PaymentsReport() {
  const [paymentsList, setPaymentsList] = useState<IPaymentReport[]>([]);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState<Date | null>(
    subMonths(new Date(), 1)
  );
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [tableTotalAmount, setTableTotalAmount] = useState<number>(0);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        if (startDate) {
          const payments = await paymentsService.listPaymentsReport(
            startDate,
            endDate
          );
          setPaymentsList(payments);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [startDate, endDate]);

  const columns: IDataTableColumn[] = useMemo<IDataTableColumn[]>(
    () => [
      {
        name: 'created_at',
        label: 'Date',
        options: {
          filter: false,
          customBodyRender(value) {
            return formatDateWithTime(value);
          },
        },
      },
      {
        name: 'subscriptionId',
        label: 'Subscription Id',
        options: {
          filter: true,
          filterType: 'textField',
        },
      },
      {
        name: 'customerName',
        label: 'Customer Name',
        options: {
          filter: true,
          filterType: 'textField',
        },
      },
      {
        name: 'vin',
        label: 'VIN',
        options: {
          filter: true,
          filterType: 'textField',
        },
      },
      {
        name: 'amount',
        label: 'Amount',
        options: {
          filter: true,
          filterType: 'textField',
          customBodyRender(value, tableMeta) {
            const totalAmount: any = Number(value);
            const taxRate = 0.07;
            const amount: any = Number(totalAmount / (1 + Number(taxRate)));
            const isRefund = tableMeta.rowData[tableMeta.rowData.length - 1];
            const formattedAmount = formatCurrency(amount);
            if (isRefund) {
              return `(${formattedAmount})`;
            }
            return formattedAmount;
          },
        },
      },
      {
        name: 'amount',
        label: 'Tax',
        options: {
          filter: true,
          filterType: 'textField',
          customBodyRender(value, tableMeta) {
            const totalAmount: any = Number(value);
            const taxRate = 0.07;
            const amount: any = Number(totalAmount / (1 + Number(taxRate)));
            const taxAmount = totalAmount - amount;
            const isRefund = tableMeta.rowData[tableMeta.rowData.length - 1];
            const formattedAmount = formatCurrency(taxAmount);
            if (isRefund) {
              return `(${formattedAmount})`;
            }
            return formattedAmount;
          },
        },
      },
      {
        name: 'amount',
        label: 'Total Amount',
        options: {
          filter: true,
          filterType: 'textField',
          customBodyRender(value, tableMeta) {
            const isRefund = tableMeta.rowData[tableMeta.rowData.length - 1];
            const formattedAmount = formatCurrency(value);
            if (isRefund) {
              return `(${formattedAmount})`;
            }
            return formattedAmount;
          },
        },
      },
      {
        name: 'type',
        label: 'Payment Type',
        options: {
          filter: true,
          filterType: 'multiselect',
          customBodyRenderLite(dataIndex) {
            const { type } = paymentsList[dataIndex];
            return type.substring(0, 1).toUpperCase() + type.substring(1);
          },
        },
      },
      {
        name: 'card_end',
        label: 'Card End',
        options: {
          filter: true,
          filterType: 'textField',
          customBodyRenderLite(dataIndex) {
            const { card_end: cardEnd } = paymentsList[dataIndex] as any;
            return cardEnd || '-';
          },
        },
      },
      {
        name: 'notes',
        label: 'Notes',
        options: {
          filter: false,
          filterType: 'multiselect',
          customBodyRenderLite(dataIndex) {
            const { notes } = paymentsList[dataIndex] as any;
            return notes || '-';
          },
        },
      },
      {
        name: 'refund_payment_id',
        label: 'Transaction Type',
        options: {
          filter: false,
          customBodyRender(value) {
            return value ? (
              <Chip label="Refund" color="error" size="small" />
            ) : (
              <Chip label="Payment" color="success" size="small" />
            );
          },
          customCSVBodyRender(value) {
            return value ? `Refund` : 'Payment';
          },
        },
      },
    ],
    [paymentsList]
  );

  return (
    <DownloadbleDataTable
      title="Payments"
      data={paymentsList}
      columns={columns}
      options={{
        downloadOptions: {
          filename: 'payments.csv',
          filterOptions: {
            useDisplayedColumnsOnly: true,
            useDisplayedRowsOnly: true,
          },
        },
        onTableChange: (action, tableState) => {
          if (action === 'filterChange' || action === 'propsUpdate') {
            setTableTotalAmount(
              tableState.displayData.reduce((acc, row) => {
                const amount = row.data[6]
                  .replaceAll('$', '')
                  .replaceAll(',', '')
                  .trim();
                if (amount.includes('(')) {
                  return (
                    acc - Number(amount.replaceAll('(', '').replaceAll(')', ''))
                  );
                }
                return acc + Number(amount);
              }, 0)
            );
          }
        },
        rowsPerPage: 100,
        customToolbar: () => (
          <>
            <DatePicker
              value={startDate}
              onChange={(date: Date) => setStartDate(date ? startOfDay(date) : date)}
              label="Start Date"
            />
            <DatePicker
              value={endDate}
              onChange={(date: Date) => setEndDate(date ? endOfDay(date) : date)}
              label="End Date"
            />
            <Box mt={1}>
              <Typography variant="body2">
                Total Amount: {formatCurrency(tableTotalAmount)}
              </Typography>
            </Box>
          </>
        ),
        rowsPerPageOptions: [100, 200, 500, 1000, 10000],
        sortOrder: {
          name: 'created_at',
          direction: 'desc',
        },
        print: false,
        enableNestedDataAccess: '.',
        rowHover: true,
        selectableRows: 'none',
        textLabels: {
          body: {
            noMatch: loading ? (
              <CircularProgress />
            ) : (
              'Sorry, no matching records found'
            ),
          },
        },
      }}
    />
  );
}
