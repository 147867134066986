import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { useSnackbar } from 'notistack';

import FullPageLoading from '@/components/FullPageLoading';
import { userHasRole } from '@/helpers/roles';
import { IUser } from '@/interfaces/IUser';
import { UserRoles } from '@/interfaces/system-users/UserRoles';
import authService from '@/services/auth';

interface AuthContextType {
  user: IUser | null;
  loading: boolean;
  initialLoading: boolean;
  signIn: (user: string, password: string) => Promise<void>;
  signOut: () => void;
}

const AuthContext = React.createContext<AuthContextType>({
  user: null,
  loading: false,
  initialLoading: false,
  signIn: async () => {},
  signOut: () => {},
});

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<IUser | null>(null);
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    authService.init({
      onLogout: () => {
        setUser(null);
        enqueueSnackbar('You have been logged out', { variant: 'info' });
      },
    });
    setUser(authService.user);
    setInitialLoading(false);
  }, []);

  const signIn = async (newUser: string, password: string) => {
    setLoading(true);
    try {
      await authService.signIn(newUser, password);
      setUser(authService.user);
    } catch (error) {
      enqueueSnackbar(String(error), { variant: 'error' });
    }
    setLoading(false);
  };

  const signOut = () => {
    setLoading(true);
    authService.signOut();
    setLoading(false);
  };

  const value = useMemo(
    () => ({ user, loading, initialLoading, signIn, signOut }),
    [user, loading, initialLoading]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  const context = React.useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
}

export function useAuthorization() {
  const { user } = useAuth();

  let checkUserHasRole;

  if (!user) {
    checkUserHasRole = () => false;
  } else {
    checkUserHasRole = (roles: UserRoles[]) => userHasRole(user, roles);
  }

  return {
    checkUserHasRole,
  };
}

export function RequireAuth() {
  const { user, initialLoading } = useAuth();
  const location = useLocation();

  if (initialLoading) {
    return <FullPageLoading />;
  }

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <Outlet />;
}

export function RequireNotAuth() {
  const { user, initialLoading } = useAuth();
  const location = useLocation();

  if (initialLoading) {
    return <FullPageLoading />;
  }

  if (user) {
    const from =
      (location.state as Record<string, Record<string, string>>)?.from
        ?.pathname || '/';
    return <Navigate to={from} replace />;
  }

  return <Outlet />;
}

export function RequireRole({
  roles,
  children,
}: {
  roles: UserRoles[];
  children: ReactNode;
}) {
  const { user, initialLoading } = useAuth();

  if (initialLoading) {
    return <FullPageLoading />;
  }

  if (!user || !userHasRole(user, roles)) {
    return <Navigate to="/" replace />;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
