import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import { Add, Delete, OpenInNew } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { subYears } from 'date-fns';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

import { useConnectedFormComponents } from '@/components/form';
import GridRowResponsive from '@/components/GridRowResponsive';
import openConfirmDialog from '@/components/openConfirmDialog';
import UploadDropzoneDialog from '@/components/UploadDropzoneDialog';
import { useMeta } from '@/hooks/meta';
import AppError from '@/interfaces/AppError';
import { ICustomer } from '@/interfaces/customers/ICustomer';
import ICustomerPersonalFile from '@/interfaces/customers/ICustomerPersonalFile';
import customersService from '@/services/customers';

const schema = yup.object().shape({
  name: yup.string().required('Name is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  birthDate: yup
    .date()
    .max(subYears(new Date(), 18), 'You must be at least 18 years old')
    .required('Birthdate is required'),
  socialSecurityNumber: yup
    .object()
    .shape({
      unmasked: yup.string().length(9, 'SSN must have 9 digits'),
    })
    .nullable(),
  phoneNumber: yup
    .object()
    .shape({
      unmasked: yup
        .string()
        .min(10, 'Phone must have at least 10 digits')
        .max(11, 'Phone must have at most 11 digits')
        .required('Phone is required'),
    })
    .nullable()
    .required('Phone is required')
    .typeError('Phone is required'),
  address: yup.object().shape({
    address: yup.string().required('Address is required'),
    complement: yup.string(),
    city: yup.string().required('City is required'),
    state: yup
      .string()
      .length(2, 'State must have 2 characters')
      .required('State is required'),
    zipCode: yup
      .object()
      .shape({
        unmasked: yup
          .string()
          .length(5, 'Zip Code must have 5 digits')
          .required('Zipcode is required'),
      })
      .nullable()
      .required('Zipcode is required')
      .typeError('Zipcode is required'),
  }),
  driverLicense: yup.object().shape({
    country: yup.string().required('DL Country is required'),
    otherCountryName: yup.mixed().when('country', {
      is: 'OTHER',
      then: yup
        .string()
        .min(2, 'Country name must have at least 3 characters')
        .typeError('Country name is required')
        .required('Country name is required'),
    }),
    state: yup.string().when('country', {
      is: 'USA',
      then: yup.string().required('DL State is required'),
    }),
    number: yup.object().when('country', {
      is: 'USA',
      then: yup
        .object()
        .shape({
          unmasked: yup
            .string()
            .min(1, 'DL number must have at least 1 digit')
            .max(19, 'DL number must have at most 19 digits')
            .required('DL number is required'),
        })
        .nullable(),
    }),
    expirationDate: yup
      .date()
      .nullable()
      .min(new Date(), 'DL Expiration date must be in the future'),
  }),
});

// todo: fix yup.InferType not handling right types
// it transforms the final type to `never`
type Form = {
  name: string;
  email: string;
  birthDate: Date;
  socialSecurityNumber: {
    unmasked: string;
    masked: string;
  };
  phoneNumber: {
    unmasked: string;
    masked: string;
  };
  address: {
    address: string;
    complement: string;
    city: string;
    state: string;
    zipCode: {
      unmasked: string;
      masked: string;
    };
  };
  driverLicense: {
    number: {
      unmasked: string;
      masked: string;
    };
    state: string;
    country: string;
    otherCountryName: string;
    expirationDate: Date;
  };
};

export default function CustomerForm() {
  const { handleSubmit, control, setValue, watch } = useForm<Form>({
    resolver: yupResolver(schema),
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { TextInput, DatePicker, AutoComplete } =
    useConnectedFormComponents<Form>({
      control,
    });
  const { id } = useParams();
  const [customer, setCustomer] = useState<ICustomer | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [driverLicenseLoading, setDriverLicenseLoading] = useState(false);
  const [personalFilesLoading, setPersonalFilesLoading] = useState(false);
  const [uploadDLDialog, setUploadDLDialog] = useState(false);
  const [uploadPersonalFilesDialog, setUploadPersonalFilesDialog] =
    useState(false);
  const { setPageTitle } = useMeta();

  const isEditing = useMemo(
    () => !!id && searchParams.get('edit') === 'true',
    [id, searchParams]
  );
  const isViewing = useMemo(() => !!id && !isEditing, [id, isEditing]);
  const [personalFiles, setPersonalFiles] = useState<ICustomerPersonalFile[]>(
    []
  );
  const driverLicenseCountryValue = watch('driverLicense.country');
  const driverLicenseIsUsa = useMemo(
    () => driverLicenseCountryValue === 'USA',
    [driverLicenseCountryValue]
  );

  const fetchCustomer = async () => {
    if (id) {
      try {
        const fetchedCustomer = await customersService.getCustomer(id);
        setCustomer(fetchedCustomer);

        setPageTitle(
          `${isEditing ? 'Edit ' : ''}Customer - ${fetchedCustomer.name}`
        );

        setValue('name', fetchedCustomer.name);
        setValue('email', fetchedCustomer.email);
        setValue('birthDate', fetchedCustomer.birthDate);
        if (fetchedCustomer.socialSecurityNumber) {
          setValue('socialSecurityNumber', {
            unmasked: fetchedCustomer.socialSecurityNumber,
            masked: fetchedCustomer.socialSecurityNumber,
          });
        }
        setValue('phoneNumber', {
          unmasked: fetchedCustomer.phoneNumber,
          masked: fetchedCustomer.phoneNumber,
        });
        const address = fetchedCustomer.addresses.find(add => add.main);
        if (address) {
          setValue('address.address', address.address);
          if (address.complement) {
            setValue('address.complement', address.complement);
          }
          setValue('address.city', address.city);
          setValue('address.zipCode', {
            unmasked: address.zipCode,
            masked: address.zipCode,
          });
          setValue('address.state', address.state);
        }
        if (fetchedCustomer.driverLicense) {
          if (fetchedCustomer.driverLicense.country === 'USA') {
            setValue(
              'driverLicense.country',
              fetchedCustomer.driverLicense.country
            );
            setValue(
              'driverLicense.state',
              fetchedCustomer.driverLicense.state!
            );
          } else {
            setValue('driverLicense.country', 'OTHER');
            setValue(
              'driverLicense.otherCountryName',
              fetchedCustomer.driverLicense.country
            );
          }
          if (fetchedCustomer.driverLicense.expirationDate) {
            setValue(
              'driverLicense.expirationDate',
              fetchedCustomer.driverLicense.expirationDate
            );
          }

          if (fetchedCustomer.driverLicense.number) {
            setValue('driverLicense.number', {
              unmasked: fetchedCustomer.driverLicense.number,
              masked: fetchedCustomer.driverLicense.number,
            });
          }
        }
      } catch (error) {
        enqueueSnackbar(
          error instanceof AppError
            ? error?.message
            : 'Error on fetching customer. Please, try again later',
          { variant: 'error' }
        );
      }
    }
  };

  const fetchPersonalFiles = useCallback(async () => {
    if (id) {
      try {
        setDriverLicenseLoading(true);
        const files = await customersService.getCustomerPersonalFiles(id);
        setPersonalFiles(files);
      } catch (error) {
        enqueueSnackbar(
          error instanceof AppError
            ? error?.message
            : 'Error on fetching customer personal files. Please, try again later',
          { variant: 'error' }
        );
      } finally {
        setDriverLicenseLoading(false);
      }
    }
  }, [id]);

  const deletePersonalFile = useCallback(
    async (fileId: string) => {
      try {
        setDriverLicenseLoading(true);
        await customersService.deleteCustomerPersonalFile(id!, fileId);
      } catch (error) {
        enqueueSnackbar(
          error instanceof AppError
            ? error?.message
            : 'Error on deleting customer personal file. Please, try again later',
          { variant: 'error' }
        );
      } finally {
        setDriverLicenseLoading(false);
      }
    },
    [id]
  );

  useEffect(() => {
    fetchCustomer();
    fetchPersonalFiles();
  }, [id, searchParams]);

  const onSubmit = async (data: Form) => {
    try {
      setSubmitLoading(true);
      const formattedData = {
        ...data,
        socialSecurityNumber: data.socialSecurityNumber?.unmasked || undefined,
        address: {
          ...data.address,
          zipCode: data.address.zipCode.unmasked,
          complement: data.address.complement || undefined,
        },
        driverLicense: {
          ...data.driverLicense,
          otherCountryName: undefined,
          number: data.driverLicense.number?.unmasked || undefined,
          country:
            data.driverLicense.country === 'OTHER'
              ? data.driverLicense.otherCountryName
              : data.driverLicense.country,
        },
        phoneNumber: data.phoneNumber.unmasked,
      };
      if (id) {
        await customersService.updateCustomer(id, formattedData);
      } else {
        await customersService.createCustomer(formattedData);
      }
      navigate('/customers/list');
    } catch (error) {
      console.error(error);
      enqueueSnackbar(
        error instanceof AppError
          ? error?.message
          : `Error on ${
              id ? 'updating' : 'creating'
            } customer. Please, try again later`,
        { variant: 'error' }
      );
    } finally {
      setSubmitLoading(false);
    }
  };

  const defaultBirthDate = useMemo(() => subYears(new Date(), 18), []);

  const renderPersonalFilesTable = useCallback(
    (files: ICustomerPersonalFile[]) => (
      <List dense>
        {files.map(personalFile => (
          <ListItem
            key={personalFile.id}
            secondaryAction={
              <>
                <Tooltip title="Preview">
                  <IconButton
                    aria-label="preview"
                    onClick={() =>
                      window.open(encodeURI(personalFile.url!), '_blank')
                    }
                  >
                    <OpenInNew />
                  </IconButton>
                </Tooltip>
                {isEditing && (
                  <Tooltip title="Delete file">
                    <IconButton
                      aria-label="delete"
                      onClick={() => {
                        openConfirmDialog({
                          onConfirm: () => deletePersonalFile(personalFile.id),
                        });
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            }
          >
            <ListItemText primary={personalFile.path} />
          </ListItem>
        ))}
      </List>
    ),
    [isEditing]
  );

  const [personalFilesColumn1, personalFilesColumn2] = useMemo(
    () =>
      personalFiles.reduce<[ICustomerPersonalFile[], ICustomerPersonalFile[]]>(
        (acc, cur, index) => {
          if (index % 2) {
            acc[1].push(cur);
          } else {
            acc[0].push(cur);
          }
          return acc;
        },
        [[], []]
      ),
    [personalFiles]
  );

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      sx={{ mt: 1 }}
    >
      <Card>
        <CardContent>
          <Typography variant="h6">Customer Details</Typography>
          <Grid
            container
            direction={{ xs: 'column', md: 'row' }}
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={3}>
              <TextInput
                margin="normal"
                required
                label="Name"
                fullWidth
                fieldName="name"
                disabled={submitLoading || isViewing}
              />
            </Grid>
            <Grid item xs={3}>
              <TextInput
                margin="normal"
                required
                label="Email"
                fullWidth
                fieldName="email"
                disabled={submitLoading || isViewing}
              />
            </Grid>
            <Grid item xs={3}>
              <TextInput
                margin="normal"
                required
                label="Phone Number"
                fullWidth
                fieldName="phoneNumber"
                disabled={submitLoading || isViewing}
                maskProps={{
                  mask: '(000) 000-0000',
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            direction={{ xs: 'column', md: 'row' }}
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={3}>
              <DatePicker
                fieldName="birthDate"
                label="Birthday"
                disabled={submitLoading || isViewing}
                textFieldProps={{
                  fullWidth: true,
                }}
                maxDate={defaultBirthDate}
                defaultValue={defaultBirthDate}
              />
            </Grid>
            <Grid item xs={3}>
              <TextInput
                margin="normal"
                disabled={submitLoading || isViewing}
                label="Social Security Number"
                fullWidth
                fieldName="socialSecurityNumber"
                maskProps={{
                  mask: '000-00-0000',
                }}
              />
            </Grid>
          </Grid>
          {isEditing && (
            <Typography variant="caption">
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              When changing something on the Driver's License information, if
              you already uploaded a file, it will be removed. It means that you
              will need to upload a new file with the new information.
            </Typography>
          )}
          <GridRowResponsive gridItemProps={{ xs: 3 }}>
            <AutoComplete
              textFieldProps={{
                margin: 'normal',
              }}
              options={['USA', 'OTHER']}
              label="Driver License Country"
              disabled={submitLoading || isViewing}
              fullWidth
              fieldName="driverLicense.country"
            />
            {!driverLicenseIsUsa ? (
              <TextInput
                margin="normal"
                label="Specify Country Name"
                required
                disabled={submitLoading || isViewing}
                fullWidth
                fieldName="driverLicense.otherCountryName"
              />
            ) : (
              <TextInput
                margin="normal"
                required
                label="Driver License State"
                disabled={submitLoading || isViewing}
                fullWidth
                fieldName="driverLicense.state"
                inputProps={{
                  maxLength: 2,
                }}
              />
            )}
            <TextInput
              margin="normal"
              required={driverLicenseIsUsa}
              label="Driver License Number"
              disabled={submitLoading || isViewing}
              fullWidth
              fieldName="driverLicense.number"
              maskProps={{
                mask: /[\d\w]+/,
                maskOptions: {
                  maxLength: 19,
                  prepare: (str: string) => str.toUpperCase(),
                },
              }}
            />
            <DatePicker
              fieldName="driverLicense.expirationDate"
              label="Driver License Expiration Date"
              disabled={submitLoading || isViewing}
              textFieldProps={{
                fullWidth: true,
              }}
              minDate={new Date()}
              defaultValue={new Date()}
            />
          </GridRowResponsive>
          <GridRowResponsive>
            {!!customer && isEditing && !customer.driverLicense?.path && (
              <Button
                variant="outlined"
                color="secondary"
                disabled={driverLicenseLoading}
                onClick={() => setUploadDLDialog(true)}
              >
                {driverLicenseLoading ? 'Uploading DL...' : 'Upload DL File'}
              </Button>
            )}
            {!!customer &&
              customer.driverLicense?.path &&
              (isEditing || isViewing) && (
                <Button
                  variant="outlined"
                  color="secondary"
                  endIcon={<OpenInNew />}
                  onClick={() => {
                    window.open(customer.driverLicense!.url, '_blank');
                  }}
                >
                  Show DL File
                </Button>
              )}
          </GridRowResponsive>
        </CardContent>
      </Card>

      <Divider sx={{ my: 4 }} />

      <Card>
        <CardContent>
          <Typography variant="h6">Address</Typography>

          <Grid
            container
            direction={{ xs: 'column', md: 'row' }}
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={3}>
              <TextInput
                margin="normal"
                required
                disabled={submitLoading || isViewing}
                label="Address"
                fullWidth
                fieldName="address.address"
              />
            </Grid>
            <Grid item xs={3}>
              <TextInput
                margin="normal"
                required
                disabled={submitLoading || isViewing}
                label="City"
                fullWidth
                fieldName="address.city"
              />
            </Grid>
            <Grid item xs={3}>
              <TextInput
                margin="normal"
                required
                disabled={submitLoading || isViewing}
                label="State"
                fullWidth
                fieldName="address.state"
              />
            </Grid>
          </Grid>
          <Grid
            container
            direction={{ xs: 'column', md: 'row' }}
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={3}>
              <TextInput
                margin="normal"
                required
                disabled={submitLoading || isViewing}
                label="Zip Code"
                fullWidth
                fieldName="address.zipCode"
                maskProps={{
                  mask: '00000',
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextInput
                margin="normal"
                label="Complement"
                disabled={submitLoading || isViewing}
                fullWidth
                fieldName="address.complement"
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {(isViewing || isEditing) && (
        <>
          <Divider sx={{ my: 4 }} />

          <Card>
            <CardContent>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="h6">Personal Files</Typography>
                {isEditing && (
                  <Button
                    startIcon={<Add />}
                    size="small"
                    variant="outlined"
                    color="primary"
                    disabled={personalFilesLoading}
                    onClick={() => setUploadPersonalFilesDialog(true)}
                  >
                    Add new personal file(s)
                  </Button>
                )}
              </Stack>
              {personalFilesLoading ? (
                <CircularProgress />
              ) : (
                <Stack flex={1} direction={{ xs: 'column', md: 'row' }}>
                  {personalFiles.length ? (
                    <>
                      <Box flex={1}>
                        {renderPersonalFilesTable(personalFilesColumn1)}
                      </Box>
                      <Box flex={1}>
                        {renderPersonalFilesTable(personalFilesColumn2)}
                      </Box>
                    </>
                  ) : (
                    <Typography variant="body2" mt={2}>
                      No personal files uploaded
                    </Typography>
                  )}
                </Stack>
              )}
            </CardContent>
          </Card>
        </>
      )}

      <UploadDropzoneDialog
        open={uploadDLDialog}
        filesLimit={1}
        dialogTitle="Upload Driver License"
        dropzoneText="Drag and drop a file here or click to upload"
        onSave={async files => {
          try {
            setDriverLicenseLoading(true);
            setUploadDLDialog(false);

            await customersService.uploadDriverLicense(id!, files[0]);
            setDriverLicenseLoading(false);
            enqueueSnackbar('Driver license uploaded successfully', {
              variant: 'success',
            });
            fetchCustomer();
          } catch (e) {
            setDriverLicenseLoading(false);
            enqueueSnackbar('Error on upload driver license', {
              variant: 'error',
            });
          }
        }}
        onClose={() => setUploadDLDialog(false)}
      />

      <UploadDropzoneDialog
        open={uploadPersonalFilesDialog}
        dialogTitle="Upload Personal Files"
        acceptedFiles={[
          'image/jpeg',
          'image/png',
          'image/bmp',
          'application/pdf',
        ]}
        onSave={async files => {
          try {
            setPersonalFilesLoading(true);
            setUploadPersonalFilesDialog(false);

            await customersService.uploadPersonalFiles(id!, files);
            setPersonalFilesLoading(false);
            enqueueSnackbar('Personal Files uploaded successfully', {
              variant: 'success',
            });
            fetchPersonalFiles();
          } catch (e) {
            setDriverLicenseLoading(false);
            enqueueSnackbar('Error on uploading personal files', {
              variant: 'error',
            });
          }
        }}
        onClose={() => setUploadPersonalFilesDialog(false)}
      />

      <Grid mb={8} mt={4}>
        {isViewing ? (
          <Button
            variant="outlined"
            color="primary"
            type="button"
            disabled={submitLoading}
            onClick={e => {
              e.preventDefault();
              searchParams.set('edit', 'true');
              setSearchParams(searchParams, { replace: true });
            }}
          >
            Edit customer
          </Button>
        ) : (
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={submitLoading}
            onClick={handleSubmit(onSubmit)}
          >
            {submitLoading ? 'Loading...' : 'Submit'}
          </Button>
        )}
      </Grid>
    </Box>
  );
}
