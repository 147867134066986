import React from 'react';

import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

import { closeDialog, openDialog } from '@/components/Dialog';
import { ConnectedFormComponents } from '@/components/form';
import { useAuth } from '@/hooks/auth';
import AppError from '@/interfaces/AppError';
import systemUsersService from '@/services/system-users';

const passwordFormSchema = yup.object().shape({
  oldPassword: yup
    .string()
    .required('Old password is required')
    .min(6, 'Old password must be at least 6 characters'),
  password: yup
    .string()
    .required('Password is required')
    .min(6, 'Password must be at least 6 characters'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

type Props = {
  open: boolean;
  onClose: () => void;
};

export function MyAccount({ open, onClose }: Props) {
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>My Account</DialogTitle>
      <DialogContent>
        <p>
          ID: <Chip component="span" label={user?.id} />
        </p>
        <p>
          Name: <Chip component="span" label={user?.name} />
        </p>
        <p>
          Email: <Chip component="span" label={user?.email} />
        </p>
        <p>
          My Roles:{' '}
          {user?.roles!.map(role => (
            <Chip size="small" key={role} component="span" label={role} />
          ))}
        </p>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            openDialog({
              title: 'Change Password',
              content: {
                schema: passwordFormSchema,
                onSubmit: async (
                  values: yup.InferType<typeof passwordFormSchema>
                ) => {
                  try {
                    await systemUsersService.updateMyself({
                      password: values.password,
                      oldPassword: values.oldPassword,
                    });
                    enqueueSnackbar('Password changed', {
                      variant: 'success',
                    });
                    closeDialog();
                  } catch (error) {
                    console.error(error);
                    enqueueSnackbar(
                      error instanceof AppError
                        ? error?.message
                        : 'Error on changing password. Please, try again later',
                      { variant: 'error' }
                    );
                  }
                },
                inputs: [
                  {
                    key: 'oldPassword',
                    type: ConnectedFormComponents.TEXT_INPUT,
                    props: {
                      fieldName: 'oldPassword',
                      label: 'Old Password',
                      required: true,
                      type: 'password',
                    },
                  },
                  {
                    key: 'password',
                    type: ConnectedFormComponents.TEXT_INPUT,
                    props: {
                      fieldName: 'password',
                      label: 'New Password',
                      required: true,
                      type: 'password',
                    },
                  },
                  {
                    key: 'confirmPassword',
                    type: ConnectedFormComponents.TEXT_INPUT,
                    props: {
                      fieldName: 'confirmPassword',
                      label: 'Confirm Password',
                      required: true,
                      type: 'password',
                    },
                  },
                ],
              },
            });
          }}
        >
          Change password
        </Button>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
