const Formatter = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  maximumFractionDigits: 0,
});

const createDecimalFormatter = (decimalPlaces = 2) =>
  new Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: decimalPlaces,
  });

export function formatAmount(value: number | string): string {
  const amount = Number(value);
  if (Number.isNaN(amount)) {
    return '';
  }

  return Formatter.format(amount);
}

export function formatDecimalAmount(
  value: number | string,
  decimalPlaces = 2
): string {
  const amount = Number(value);
  if (Number.isNaN(amount)) {
    return '';
  }

  return createDecimalFormatter(decimalPlaces).format(amount);
}
