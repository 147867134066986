import { Box } from '@mui/material';

import MaintenanceScheduler from './Scheduler';

export default function NewMaintenance() {
  return (
    <Box>
      <MaintenanceScheduler />
    </Box>
  );
}
