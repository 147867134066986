import { ProcessedEvent } from '@aldabil/react-scheduler/types';
import { formatDuration, intervalToDuration, parseISO } from 'date-fns';

import { MAINTENANCE_STATUS } from '@/constants/maintenance-status';
import {
  IMaintenance,
  IMaintenanceStatus,
} from '@/interfaces/maintenance/IMaintenance';

export function convertMaintenanceToProcessedEvent(
  maintenance: IMaintenance
): ProcessedEvent {
  const startDate = parseISO(maintenance.expectedStartDate);
  const endDate = parseISO(maintenance.expectedEndDate);

  return {
    event_id: maintenance.id,
    title: `${maintenance.vehicle.vin} - ${maintenance.type.displayName}`,
    start: startDate,
    end: endDate,
    deletable: false,
    color: maintenance.status
      ? MAINTENANCE_STATUS[maintenance.status].color
      : MAINTENANCE_STATUS.PENDING.color,
    editable:
      maintenance.status && maintenance.status === IMaintenanceStatus.PENDING,
    maintenance,
    draggable:
    maintenance.status && maintenance.status === IMaintenanceStatus.PENDING,
  };
}

export function parseDurationInSecondsToText(
  durationInSeconds: number
): string {
  const intervalDuration = intervalToDuration({
    start: 0,
    end: durationInSeconds * 1000,
  });

  const text = formatDuration(intervalDuration, {
    delimiter: ' ',
    format: ['hours', 'minutes'],
  });

  return text;
}

export function parseMaintenanceDatesISOToDuration(
  startDateISO: string,
  endDateISO: string
) {
  return (
    (new Date(endDateISO).getTime() - new Date(startDateISO).getTime()) / 1000
  );
}
