import ICreateSystemUserDTO from '@/interfaces/system-users/ICreateSystemUserDTO';
import { ISystemUser } from '@/interfaces/system-users/ISystemUser';
import { IUpdateSystemUserMyselfDTO } from '@/interfaces/system-users/IUpdateSystemUserMyselfDTO';
import { http } from '@/services/http';

class SystemUsersService {
  static BASE_PATH = 'system-users';

  async listSystemUsers(): Promise<ISystemUser[]> {
    const { data } = await http.get(`/${SystemUsersService.BASE_PATH}`);
    return data;
  }

  async getSystemUser(id: string): Promise<ISystemUser> {
    const { data } = await http.get(`/${SystemUsersService.BASE_PATH}/${id}`);
    return data;
  }

  async createSystemUser(
    customerData: ICreateSystemUserDTO
  ): Promise<ISystemUser> {
    const { data } = await http.post(
      `/${SystemUsersService.BASE_PATH}`,
      customerData
    );
    return data;
  }

  async updateSystemUser(
    id: string,
    customerData: ICreateSystemUserDTO
  ): Promise<ISystemUser> {
    const { data } = await http.put(
      `/${SystemUsersService.BASE_PATH}/${id}`,
      customerData
    );
    return data;
  }

  async updateMyself(
    userData: IUpdateSystemUserMyselfDTO
  ): Promise<ISystemUser> {
    const { data } = await http.put(
      `/${SystemUsersService.BASE_PATH}`,
      userData
    );
    return data;
  }
}

const systemUsersService = new SystemUsersService();

export default systemUsersService;
