import { DropzoneDialog, DropzoneDialogProps } from 'mui-file-dropzone';

type Props = DropzoneDialogProps;

export default function UploadDropzoneDialog(props: Props) {
  return (
    <DropzoneDialog
      fileObjects={[]}
      dropzoneText="Drag and drop images here or click to upload"
      dialogTitle="Upload Images"
      acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
      showPreviews
      maxFileSize={5000000}
      showFileNamesInPreview={false}
      filesLimit={10}
      previewGridProps={{
        container: {
          sx: {
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 1fr))',
            gridGap: '1rem',
          },
        },
        item: {
          sx: {
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: 'gray',
          },
        },
      }}
      {...props}
    />
  );
}
