import ICreateCustomerRequestDTO from '@/interfaces/customers/ICreateCustomerRequestDTO';
import { ICustomer } from '@/interfaces/customers/ICustomer';
import ICustomerPersonalFile from '@/interfaces/customers/ICustomerPersonalFile';
import IUpdateCustomerDTO from '@/interfaces/customers/IUpdateCustomerDTO';
import { http } from '@/services/http';

class CustomersService {
  static BASE_PATH = 'customers';

  async listCustomers(): Promise<ICustomer[]> {
    const { data } = await http.get(`/${CustomersService.BASE_PATH}`);
    return data;
  }

  async createCustomer(
    customerData: ICreateCustomerRequestDTO
  ): Promise<ICustomer> {
    const { data } = await http.post(
      `/${CustomersService.BASE_PATH}`,
      customerData
    );
    return data;
  }

  async updateCustomer(
    customerId: string,
    customerData: IUpdateCustomerDTO
  ): Promise<ICustomer> {
    const { data } = await http.put(
      `/${CustomersService.BASE_PATH}/${customerId}`,
      customerData
    );
    return data;
  }

  async uploadDriverLicense(
    customerId: string,
    file: File
  ): Promise<ICustomer> {
    const formData = new FormData();

    formData.append('driverLicense', file);

    const { data } = await http.post(
      `/${CustomersService.BASE_PATH}/documents/dl/${customerId}`,
      formData
    );
    return data;
  }

  async uploadPersonalFiles(
    customerId: string,
    files: File[]
  ): Promise<ICustomer> {
    const formData = new FormData();

    files.forEach(file => {
      formData.append('personalFiles', file);
    });

    const { data } = await http.post(
      `/${CustomersService.BASE_PATH}/documents/personal-files/${customerId}`,
      formData
    );
    return data;
  }

  async getCustomer(customerId: string): Promise<ICustomer> {
    const { data } = await http.get(
      `/${CustomersService.BASE_PATH}/${customerId}`
    );
    return data;
  }

  async getCustomerPersonalFiles(
    customerId: string
  ): Promise<ICustomerPersonalFile[]> {
    const { data } = await http.get(
      `/${CustomersService.BASE_PATH}/documents/personal-files/${customerId}`
    );
    return data;
  }

  async deleteCustomerPersonalFile(
    customerId: string,
    fileId: string
  ): Promise<void> {
    await http.delete(
      `/${CustomersService.BASE_PATH}/documents/personal-files/${customerId}/${fileId}`
    );
  }
}

const customersService = new CustomersService();

export default customersService;
