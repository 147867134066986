import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Add,
  Collections,
  Edit,
  PictureAsPdf,
  Visibility,
} from '@mui/icons-material';
import { Button, CircularProgress, IconButton, Tooltip } from '@mui/material';
import MUIDataTable, { MUIDataTableColumn } from 'mui-datatables';

import { formatCurrency } from '@/helpers/currency';
import { formatAmount } from '@/helpers/number';
import { vehicleDisplayName } from '@/helpers/vehicles';
import { useAuthorization } from '@/hooks/auth';
import { UserRoles } from '@/interfaces/system-users/UserRoles';
import IVehicle from '@/interfaces/vehicles/IVehicle';
import vehiclesService from '@/services/vehicles';

function EditButton({ vin }: { vin: string }) {
  const navigate = useNavigate();
  const { checkUserHasRole } = useAuthorization();

  const handleClick = useCallback(() => {
    navigate(`/vehicles/inventory/${vin}?edit=true`);
  }, [navigate, vin]);

  return (
    <IconButton
      onClick={handleClick}
      disabled={!checkUserHasRole([UserRoles.ADMIN])}
    >
      <Edit />
    </IconButton>
  );
}

function ViewButton({ vin }: { vin: string }) {
  const navigate = useNavigate();
  const handleClick = useCallback(() => {
    navigate(`/vehicles/inventory/${vin}`);
  }, [navigate, vin]);

  return (
    <IconButton onClick={handleClick}>
      <Visibility />
    </IconButton>
  );
}

function SimulateButton({
  vin,
  disabled,
}: {
  vin: string;
  disabled?: boolean;
}) {
  const navigate = useNavigate();
  const handleClick = useCallback(() => {
    navigate(`/subscriptions/simulate?vin=${vin}`);
  }, [navigate, vin]);

  return (
    <Tooltip title="Simulate vehicle">
      <span>
        <IconButton disabled={disabled} onClick={handleClick}>
          <PictureAsPdf />
        </IconButton>
      </span>
    </Tooltip>
  );
}

function VehicleImagesButton({ vin }: { vin: string }) {
  const navigate = useNavigate();
  const handleClick = useCallback(() => {
    navigate(`/vehicles/inventory/${vin}/images`);
  }, [navigate, vin]);

  return (
    <IconButton onClick={handleClick}>
      <Collections />
    </IconButton>
  );
}

export default function ListVehicles() {
  const navigate = useNavigate();
  const { checkUserHasRole } = useAuthorization();

  const [vehiclesList, setVehiclesList] = useState<IVehicle[]>([]);
  const [loading, setLoading] = useState(true);

  const columns: MUIDataTableColumn[] = useMemo<MUIDataTableColumn[]>(
    () => [
      {
        name: 'vin',
        label: 'VIN',
        options: {
          filter: true,
          filterType: 'textField',
          sort: true,
        },
      },
      {
        name: 'brand.name',
        label: 'Brand',
        options: {
          display: false,
          print: false,
          download: false,
        },
      },
      {
        name: 'model',
        label: 'Model',
        options: {
          display: false,
          print: false,
          download: false,
        },
      },
      {
        name: 'year',
        label: 'Year',
        options: {
          display: false,
          print: false,
          download: false,
        },
      },
      {
        name: 'displayName',
        label: 'Vehicle',
        options: {
          filter: false,
          filterType: 'textField',
          sort: true,
        },
      },
      {
        name: 'externalColor',
        label: 'Color',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'mileage',
        label: 'Mileage',
        options: {
          filter: false,
          sort: true,
          customBodyRender: (mileage: number) =>
          mileage && formatAmount(mileage),
        },
      },
      {
        name: 'available',
        label: 'Available',
        options: {
          filter: true,
          sort: true,
          customBodyRender: (available: boolean) => (available ? 'Yes' : 'No'),
        },
      },
      {
        name: 'listed',
        label: 'Listed',
        options: {
          filter: true,
          sort: true,
          customBodyRender: (available: boolean) => (available ? 'Yes' : 'No'),
        },
      },
      {
        name: 'featured',
        label: 'Featured',
        options: {
          filter: true,
          sort: true,
          customBodyRender: (featured: boolean) => (featured ? 'Yes' : 'No'),
        },
      },
      {
        name: 'referencePrice',
        label: 'Reference Price',
        options: {
          display: false,
          sort: true,
          filter: true,

          customBodyRender: (value: string) =>
          formatCurrency(value)
        },
      },
      {
        name: '',
        label: 'Actions',
        options: {
          filter: false,
          sort: false,
          print: false,
          viewColumns: false,
          download: false,
          // eslint-disable-next-line react/no-unstable-nested-components
          customBodyRenderLite: (index: number) => (
            <>
              <ViewButton vin={vehiclesList[index].vin} />
              <EditButton vin={vehiclesList[index].vin} />
              <SimulateButton vin={vehiclesList[index].vin} />
              <VehicleImagesButton vin={vehiclesList[index].vin} />
            </>
          ),
        },
      },
    ],
    [vehiclesList]
  );

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const vehicles = await vehiclesService.listVehicles();
        setVehiclesList(
          vehicles.map(vehicle => ({
            ...vehicle,
            displayName: vehicleDisplayName(vehicle, true),
          }))
        );
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const addButton = useCallback(
    () => (
      <Button
        onClick={() => {
          navigate('/vehicles/new');
        }}
        variant="outlined"
        disabled={!checkUserHasRole([UserRoles.ADMIN])}
        startIcon={<Add />}
      >
        Add vehicle
      </Button>
    ),
    [checkUserHasRole]
  );

  return (
    <MUIDataTable
      title="Vehicles inventory"
      data={vehiclesList}
      columns={columns}
      options={{
        storageKey: '@driveon:vehicles:list',
        searchAlwaysOpen: true,
        rowHover: true,
        enableNestedDataAccess: '.',
        selectableRows: 'none',
        customToolbar: addButton,
        textLabels: {
          body: {
            noMatch: loading ? (
              <CircularProgress />
            ) : (
              'Sorry, no matching records found'
            ),
          },
        },
      }}
    />
  );
}
