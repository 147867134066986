import { Button, Stack } from '@mui/material';

import { closeDialog, openDialog } from '@/components/Dialog';
import openConfirmDialog from '@/components/openConfirmDialog';
import {
  IMaintenance,
  IMaintenanceStatus,
} from '@/interfaces/maintenance/IMaintenance';
import maintenancesService from '@/services/maintenances';

import MaintenanceStateForm from '../MaintenanceStateForm';

interface Props {
  id: string;
  status: IMaintenanceStatus;
  onUpdate: (updatedMaintenance: IMaintenance) => void;
}

export default function MaintenanceActions({ id, status, onUpdate }: Props) {
  const openStateDialog = (isFinish?: boolean) => {
    openDialog({
      title: !isFinish ? 'Start maintenance' : 'Finish maintenance',
      content: (
        <MaintenanceStateForm
          maintenanceId={id}
          isFinish={isFinish}
          onUpdate={onUpdate}
        />
      ),
    });
  };

  const openCancelDialog = () => {
    openConfirmDialog({
      title: 'Cancel maintenance',
      onConfirm: async () => {
        const updatedMaintenance = await maintenancesService.cancelMaintenance(
          id
        );

        onUpdate(updatedMaintenance);
        closeDialog();
      },
    });
  };

  return (
    <Stack
      direction="row"
      flex={1}
      gap={1}
      justifyContent="flex-end"
      flexWrap="wrap"
    >
      {status === IMaintenanceStatus.PENDING && (
        <Button
          color="primary"
          variant="contained"
          onClick={() => openStateDialog()}
        >
          Start maintenance
        </Button>
      )}

      {status === IMaintenanceStatus.ACTIVE && (
        <Button
          color="primary"
          variant="contained"
          onClick={() => openStateDialog(true)}
        >
          Finish maintenance
        </Button>
      )}

      {status === IMaintenanceStatus.PENDING && (
        <Button color="error" variant="contained" onClick={openCancelDialog}>
          Cancel maintenance
        </Button>
      )}
    </Stack>
  );
}
