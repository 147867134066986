import React from 'react';

import { useDroppable } from '@dnd-kit/core';
import { Box, BoxProps } from '@mui/material';

type Props = {
  droppableId: string;
} & BoxProps;

export function Droppable({ droppableId, children, sx, ...rest }: Props) {
  const { isOver, setNodeRef } = useDroppable({
    id: droppableId,
  });

  return (
    <Box
      ref={setNodeRef}
      sx={{
        backgroundColor: isOver ? 'lightgrey' : undefined,
        ...(sx || {}),
      }}
      {...rest}
    >
      {children}
    </Box>
  );
}
