import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

import { closeDialog } from '@/components/Dialog';
import { useConnectedFormComponents } from '@/components/form';
import { currencyMask } from '@/constants/mask';
import { formatCurrency } from '@/helpers/currency';
import { formatDecimalAmount } from '@/helpers/number';
import { vehicleDisplayName } from '@/helpers/vehicles';
import AppError from '@/interfaces/AppError';
import { IInstallmentStatus } from '@/interfaces/payments/IInstallment';
import IInvoice from '@/interfaces/payments/IInvoice';
import ISubscriptionVehicle from '@/interfaces/subscriptions/ISubscriptionVehicle';
import invoicesService from '@/services/invoices';

type Props = {
  invoice: IInvoice;
  onEditSuccess?: () => void | Promise<void>;
  subscriptionVehicles: ISubscriptionVehicle[];
};

export default function EditInvoice({
  invoice,
  onEditSuccess,
  subscriptionVehicles,
}: Props) {
  const totalPaidAmount = useMemo(
    () =>
      invoice.installments.reduce<number>((acc, installment) => {
        if (installment.status === IInstallmentStatus.PAID) {
          return acc + installment.totalAmount;
        }

        return acc;
      }, 0),
    [invoice]
  );
  const schema = yup.object().shape({
    name: yup.string().required(),
    subscriptionVehicle: yup.object().required(),
    totalAmount: yup
      .mixed()
      .test(
        'total-amount-less-than-paid',
        `Total amount can not be less than the total already paid amount: ${formatCurrency(
          totalPaidAmount
        )}`,
        value => Number(value.unmasked) >= totalPaidAmount
      ),
  });
  const defaultValues = useMemo(
    () => ({
      name: invoice.name,
      type: invoice.type,
      subscriptionVehicle:
        (subscriptionVehicles.find(
          subVehicle => subVehicle.id === invoice.subscriptionVehicle.id
        ) as any) ?? null,
      totalAmount: {
        masked: formatDecimalAmount(invoice.totalAmount),
        unmasked: invoice.totalAmount.toString(),
      },
    }),
    [invoice]
  );
  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues,
  });
  const { TextInput, AutoComplete } = useConnectedFormComponents({
    control,
  });
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = useCallback(
    async (values: typeof defaultValues) => {
      try {
        await invoicesService.editInvoice(invoice.id, {
          name: values.name,
          totalAmount: Number(Number(values.totalAmount.unmasked).toFixed(2)),
          subscriptionVehicleId:
            values.subscriptionVehicle.id !== invoice.subscriptionVehicle.id
              ? values.subscriptionVehicle.id
              : undefined,
        });
        closeDialog();
        enqueueSnackbar('Invoice edited successfully', {
          variant: 'success',
        });
        if (onEditSuccess) {
          onEditSuccess();
        }
      } catch (error) {
        console.error(error);
        enqueueSnackbar(
          error instanceof AppError
            ? error?.message
            : 'Error on editing invoice. Please, try again later',
          { variant: 'error' }
        );
      }
    },
    [enqueueSnackbar, invoice, onEditSuccess]
  );

  return (
    <>
      <TextInput fieldName="name" label="Name" />
      <TextInput fieldName="type" label="Type" disabled />
      <TextInput
        fieldName="totalAmount"
        label="Total Amount"
        maskProps={currencyMask()}
      />
      <AutoComplete
        options={subscriptionVehicles}
        getOptionLabel={subVehicle =>
          `${vehicleDisplayName(subVehicle.vehicle)}`
        }
        textFieldProps={{
          required: subscriptionVehicles.length > 1,
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        fieldName="subscriptionVehicle"
        disabled={subscriptionVehicles.length === 1}
        label="Vehicle"
      />

      <Stack direction="row" gap={2} justifyContent="flex-end">
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => closeDialog()}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={!isValid}
          onClick={handleSubmit(onSubmit)}
        >
          Submit
        </Button>
      </Stack>
    </>
  );
}
