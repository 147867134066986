import { ComponentProps, PropsWithChildren, useMemo } from 'react';

import { Grid } from '@mui/material';

type Props = PropsWithChildren<{
  gridContainerProps?: ComponentProps<typeof Grid>;
  gridItemProps?: ComponentProps<typeof Grid>;
  ignoreGridItemWrapper?: boolean;
}>;

export default function GridRowResponsive({
  children,
  gridItemProps,
  gridContainerProps,
  ignoreGridItemWrapper,
}: Props) {
  const xs = useMemo(
    () => (Array.isArray(children) ? Math.trunc(12 / children.length) : 12),
    [children]
  );
  const childrenContent = useMemo(() => {
    if (Array.isArray(children)) {
      return children.map((child, index) => {
        const key = String(index);
        return (
          <Grid item xs={xs} key={key} {...(gridItemProps || {})}>
            {child}
          </Grid>
        );
      });
    }
    if (ignoreGridItemWrapper) {
      return children;
    }
    return (
      <Grid item xs={12} {...gridItemProps}>
        {children}
      </Grid>
    );
  }, [children, gridItemProps, ignoreGridItemWrapper, xs]);

  return (
    <Grid
      container
      direction={{ xs: 'column', md: 'row' }}
      alignItems="center"
      spacing={2}
      {...(gridContainerProps || {})}
    >
      {childrenContent}
    </Grid>
  );
}
