import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

import { useConnectedFormComponents } from '@/components/form';
import AppError from '@/interfaces/AppError';
import { IMaintenanceType } from '@/interfaces/maintenance/IMaintenanceType';
import { Task } from '@/interfaces/maintenance/IMaintenanceTypeTask';
import maintenancesService from '@/services/maintenances';

import DurationSelect from '../../components/DurationSelect';
import TaskList from './components/TaskList';

type Form = {
  displayName: string;
  duration: number;
};

const schema = Yup.object().shape({
  displayName: Yup.string().required('displayName is required'),
  duration: Yup.number().min(1).required('duration is required'),
});

export default function MaintenanceTypeForm() {
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [maintenanceType, setMaintenanceType] = useState<IMaintenanceType>();
  const [tasks, setTasks] = useState<Task[]>([]);

  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const { id } = useParams();

  const { handleSubmit, setValue, reset, control } = useForm<Form>({
    resolver: yupResolver(schema),
  });

  const { TextInput } = useConnectedFormComponents<Form>({
    control,
  });

  const fetchMaintenanceTypes = useCallback(async () => {
    if (id) {
      const maintenanceTypeResponse =
        await maintenancesService.getMaintenanceType(id);
      reset(maintenanceTypeResponse);
      setMaintenanceType(maintenanceTypeResponse);
    }
  }, [id, reset]);

  useEffect(() => {
    fetchMaintenanceTypes();
  }, [fetchMaintenanceTypes]);

  useEffect(() => {
    setTasks(maintenanceType?.tasks || []);
  }, [maintenanceType]);

  const onSubmit = async (data: Form) => {
    console.log("Aqui")

    try {
      setSubmitLoading(true);

      if (!id) {
        await maintenancesService.createMaintenanceType({
          displayName: data.displayName,
          duration: data.duration,
          tasks: tasks.map(task => task.description),
        });

        navigate(`/maintenance/types`);
      } else {
        const updatedType = await maintenancesService.updateMaintenanceType(
          id,
          {
            displayName: data.displayName,
            duration: data.duration,
            tasks: tasks.map(task => ({
              id: !task.id.startsWith('dummy') ? task.id : undefined,
              description: task.description,
            })),
          }
        );

        setMaintenanceType(updatedType);
        setSubmitLoading(false);

        enqueueSnackbar(
          `Maintenance type "${data.displayName}" updated with success`,
          { variant: 'success' }
        );
      }
    } catch (error) {
      console.error(error);
      setSubmitLoading(false);
      enqueueSnackbar(
        error instanceof AppError
          ? error?.message
          : 'Error on creating maintenance type. Please, try again later',
        { variant: 'error' }
      );
    }
  };

  const submitText = maintenanceType ? 'Save' : 'Create';

  return (
    <Box sx={{ mt: 1 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          label="Display name"
          fullWidth
          fieldName="displayName"
          required
        />

        <DurationSelect
          defaultValue={maintenanceType?.duration}
          onChange={value => setValue('duration', value)}
          disableMargin
        />

        <TaskList
          tasks={tasks.map(task => ({
            id: task.id,
            description: task.description,
          }))}
          addTask={task => setTasks(prev => [...prev, task])}
          removeTask={taskId =>
            setTasks(prev => prev.filter(t => t.id !== taskId))
          }
          editTask={task =>
            setTasks(prev => {
              const updatedTasks = [...prev];
              const taskIndex = prev.findIndex(
                currentTask => currentTask.id === task.id
              );
              if (taskIndex !== -1) {
                updatedTasks[taskIndex] = {
                  id: task.id,
                  description: task.description,
                };
              }
              return updatedTasks;
            })
          }
        />

        <Button
          sx={{ marginTop: 2 }}
          variant="contained"
          color="primary"
          disabled={submitLoading}
          type='submit'
        >
          {submitLoading ? 'Loading...' : submitText}
        </Button>
      </form>
    </Box>
  );
}
