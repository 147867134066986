import type IInstallment from '@/interfaces/payments/IInstallment';
import type ISubscriptionVehicle from '@/interfaces/subscriptions/ISubscriptionVehicle';

export enum IInvoiceStatus {
  OPEN = 'OPEN',
  PAID = 'PAID',
  PAST_DUE = 'PAST_DUE',
  RESCHEDULED = 'RESCHEDULED',
}

export enum ChargeType {
  MONTHLY = 'MONTHLY',
  ACTIVATION = 'ACTIVATION',
  FEE = 'FEE',
  OTHER = 'OTHER',
  MILES = 'MILES',
  INSURANCE = 'INSURANCE',
}

export default interface IInvoice {
  id: string;
  name: string;
  type: ChargeType;
  subscriptionVehicle: ISubscriptionVehicle;
  amount: number;
  taxRate: number;
  totalAmount: number;
  readonly status: IInvoiceStatus;
  createdAt: Date;
  updatedAt: Date;
  installments: IInstallment[];
  nextDueDate?: Date;
}
