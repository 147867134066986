import React, { MouseEventHandler, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { Paper, SvgIconProps, Typography } from '@mui/material';

type Props = {
  path: string;
  title: string | ((path: string) => string);
  CardIcon: React.ComponentType<SvgIconProps>;
};

export default function PageCard({ path, title, CardIcon }: Props) {
  const onClickPaper: MouseEventHandler<HTMLAnchorElement> = useCallback(e => {
    e.preventDefault();
  }, []);

  return (
    <Paper
      elevation={3}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minWidth: '250px',
        minHeight: '250px',
        justifyContent: 'center',
        alignItems: 'center',
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline',
        },
      }}
      component={Link}
      to={path}
      onClick={onClickPaper}
    >
      <CardIcon fontSize="large" sx={{ mb: '.5rem' }} />
      <Typography variant="h5">{typeof title === 'string' ? title : title(path)}</Typography>
    </Paper>
  );
}
