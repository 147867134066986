import {
  forwardRef,
  JSXElementConstructor,
  ReactNode,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog as MUIDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from '@mui/material';
import * as yup from 'yup';

import { useConnectedFormComponents } from '@/components/form';

import { CustomDialogProps, DialogProps, isDialogContentInput } from './types';

const Dialog = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState<ReactNode>(null);
  const [title, setTitle] = useState('');
  const [actions, setActions] = useState<ReactNode>(null);
  const [schema, setSchema] = useState<yup.AnyObjectSchema | null>(null);
  const [dialogProps, setDialogProps] = useState<CustomDialogProps>({});
  const [onSubmit, setOnSubmit] = useState<
    ((params: Record<string, unknown>) => void | Promise<void>) | null
  >(() => null);

  const { handleSubmit, control, reset, getValues } = useForm({
    resolver: schema ? yupResolver(schema) : undefined,
  });
  const connectedComponents = useConnectedFormComponents({
    control,
  });

  const openDialog = useCallback((params: DialogProps) => {
    setContent(params.content);
    setTitle(params.title);
    setActions(params.actions);
    setDialogProps(params.customDialogProps || {});
    if (isDialogContentInput(params.content)) {
      setSchema(params.content.schema || null);
      const onSubmitParam = params.content.onSubmit;
      setOnSubmit(() => () => onSubmitParam(getValues()));
    }
    setOpen(true);
  }, []);

  const updateOpenedDialog = useCallback((params: Partial<DialogProps>) => {
    if (params.content) {
      setContent(params.content);
    }
    if (params.title) {
      setTitle(params.title);
    }
    if (params.actions) {
      setActions(params.actions);
    }
    if (params.customDialogProps) {
      setDialogProps(params.customDialogProps || {});
    }
    if (params.content) {
      if (isDialogContentInput(params.content)) {
        setSchema(params.content.schema || null);
        const onSubmitParam = params.content.onSubmit;
        setOnSubmit(() => () => onSubmitParam(getValues()));
      }
    }
  }, []);

  const closeDialog = useCallback(() => {
    setOpen(false);
    setActions(null);
    setContent(null);
    setTitle('');
    setSchema(null);
    setOnSubmit(() => null);
    reset();
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      openDialog,
      closeDialog,
      updateOpenedDialog,
    }),
    [openDialog, closeDialog, updateOpenedDialog]
  );

  const renderContent = useMemo(() => {
    if (isDialogContentInput(content)) {
      return (
        <Stack gap={1}>
          {content.description}
          {content.inputs.map((inputOrInputs, index) => {
            if (Array.isArray(inputOrInputs)) {
              return (
                <Stack
                  direction="row"
                  gap={2}
                  key={`row-inputs-${String(index)}`}
                >
                  {inputOrInputs.map(input => {
                    const Component = connectedComponents[
                      input.type
                    ] as JSXElementConstructor<unknown>;
                    return (
                      <Box key={input.key} flex={1}>
                        <Component
                          key={`input-child-${input.key}`}
                          {...input.props}
                        />
                      </Box>
                    );
                  })}
                </Stack>
              );
            }
            const Component = connectedComponents[
              inputOrInputs.type
            ] as JSXElementConstructor<unknown>;
            return (
              <Component key={inputOrInputs.key} {...inputOrInputs.props} />
            );
          })}
        </Stack>
      );
    }
    return content;
  }, [connectedComponents, content]);

  const renderActions = useMemo(() => {
    if (onSubmit) {
      return (
        <>
          {actions}
          <Button onClick={closeDialog}>Cancel</Button>
          <Button onClick={handleSubmit(onSubmit)}>Submit</Button>
        </>
      );
    }
    return actions;
  }, [actions, closeDialog, handleSubmit, onSubmit]);

  return (
    <MUIDialog
      open={open}
      onClose={closeDialog}
      {...(dialogProps.dialogProps || {})}
    >
      <DialogTitle
        sx={{ paddingRight: 8 }}
        {...(dialogProps.dialogTitleProps || {})}
      >
        {title}
        <IconButton
          onClick={closeDialog}
          sx={{
            position: 'absolute',
            right: 12,
            top: 12,
            color: theme => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent {...(dialogProps.dialogContentProps || {})}>
        {renderContent}
      </DialogContent>
      {!!renderActions && (
        <DialogActions {...(dialogProps.dialogActionsProps || {})}>
          {renderActions}
        </DialogActions>
      )}
    </MUIDialog>
  );
});

export default Dialog;
