import { useEffect, useState } from 'react';

import { ExpandMore, History } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material';

import { IMaintenance } from '@/interfaces/maintenance/IMaintenance';
import maintenancesService from '@/services/maintenances';

import MaintenanceDetails from '../MaintenanceDetails';

interface Props {
  vehicleId: string;
}

export default function VehicleMaintenanceHistory({ vehicleId }: Props) {
  const [loading, setLoading] = useState<boolean>(false);

  const [maintenanceList, setMaintenanceList] = useState<IMaintenance[]>([]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const maintenanceListResponse =
          await maintenancesService.listVehicleMaintenances(vehicleId);
        setMaintenanceList(maintenanceListResponse);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <Accordion sx={{ marginBottom: 2 }}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
      >
        <Stack gap={1} direction="row" alignItems="center">
          <History />
          <Typography variant="h6">
            {`Maintenances History (${maintenanceList.length})`}
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{ maxHeight: 500, overflow: 'auto' }}>
        {loading ? (
          <CircularProgress size={18} color="primary" />
        ) : (
          maintenanceList.map(maintenance => (
            <MaintenanceDetails
              key={maintenance.id}
              maintenance={maintenance}
              hideVehicleDetails
              showDivider
            />
          ))
        )}
      </AccordionDetails>
    </Accordion>
  );
}
