import * as React from 'react';
import { useCallback } from 'react';
import { Controller, Path, UseControllerProps } from 'react-hook-form';

import { TextField, TextFieldProps, Typography } from '@mui/material';

import MaskedInput, { MaskedInputProps } from '@/components/form/MaskedInput';

type Props<FormType> = TextFieldProps &
  Omit<UseControllerProps<FormType>, 'name'> & {
    fieldName: Path<FormType>;
    maskProps?: Omit<MaskedInputProps, 'onChange'>;
  };

export type TextInputPropsWithoutConnectionProps<FormType> = Omit<
  Props<FormType>,
  'control'
>;

export default function TextInput<FormType>({
  fieldName,
  control,
  defaultValue,
  maskProps,
  InputProps,
  inputProps,
  helperText,
  ...props
}: Props<FormType>) {
  const getErrorMessage = useCallback(
    (error?: any): string => {
      if (!error || Object.keys(error).length === 0) {
        return '';
      }
      if (error.message) {
        return error.message;
      }
      if (maskProps) {
        return error?.unmasked?.message ?? '';
      }
      return '';
    },
    [maskProps]
  );

  const renderField = useCallback(
    ({ field, fieldState: { error } }) => {
      const errorMessage = getErrorMessage(error);

      return (
        <TextField
          margin="normal"
          fullWidth
          {...props}
          error={!!error}
          helperText={
            <>
              {errorMessage}
              {!!errorMessage && !!helperText && <br />}
              {!!helperText && (
                <Typography
                  variant="inherit"
                  component="span"
                  color="text.secondary"
                >
                  {helperText}
                </Typography>
              )}
            </>
          }
          value={maskProps ? field.value.masked : field.value}
          onChange={e => {
            field.onChange(
              maskProps
                ? {
                    masked: e.target.value,
                    unmasked: (e.target as any).unmasked,
                  }
                : e.target.value
            );
          }}
          InputProps={{
            ...(InputProps || {}),
            inputComponent: maskProps ? (MaskedInput as any) : undefined,
            inputProps: {
              ...(InputProps?.inputProps || {}),
              ...(inputProps || {}),
              ...(maskProps
                ? { ...maskProps, ...(maskProps.maskOptions || {}) }
                : {}),
            },
          }}
        />
      );
    },
    [InputProps, getErrorMessage, helperText, inputProps, maskProps, props]
  );

  return (
    <Controller
      render={renderField}
      control={control}
      name={fieldName}
      defaultValue={defaultValue != null ? defaultValue : ('' as any)} // todo: fix typing
    />
  );
}
