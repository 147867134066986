import { useState } from 'react';

import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';

import ISubscription from '@/interfaces/subscriptions/ISubscription';

import SubscriptionForm from '../../Form';

interface Props {
  subscription: ISubscription;
  onClose: () => void;
}

export default function RenewSubscriptionModal(props: Props) {
  const { subscription, onClose } = props;

  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  return (
    <Dialog maxWidth="sm" fullWidth open onClose={onClose}>
      <DialogTitle>Renew Subscription</DialogTitle>
      <DialogContent>
        <SubscriptionForm
          subscription={subscription}
          onSuccess={onClose}
          onAfterSubmit={() => setSubmitting(false)}
        >
            <Button
            sx={{ marginLeft: 'auto' }}
            variant="contained"
            disabled={isSubmitting}
            onClick={onClose}
          >
            Cancel
          </Button>
        </SubscriptionForm>
      </DialogContent>
    </Dialog>
  );
}
