import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Add, Edit } from '@mui/icons-material';
import { Button, CircularProgress, IconButton, Tooltip } from '@mui/material';
import MUIDataTable, { MUIDataTableColumn } from 'mui-datatables';

import { parseDurationInSecondsToText } from '@/helpers/maintenances';
import { useAuthorization } from '@/hooks/auth';
import { IMaintenanceType } from '@/interfaces/maintenance/IMaintenanceType';
import { UserRoles } from '@/interfaces/system-users/UserRoles';
import maintenancesService from '@/services/maintenances';

function EditButton({ maintenanceTypeId }: { maintenanceTypeId: number }) {
  const navigate = useNavigate();
  const handleClick = useCallback(() => {
    navigate(`/maintenance/types/${maintenanceTypeId}`);
  }, [navigate, maintenanceTypeId]);

  const { checkUserHasRole } = useAuthorization();

  return (
    <Tooltip title="Edit">
      <IconButton
        onClick={handleClick}
        disabled={!checkUserHasRole([UserRoles.ADMIN])}
      >
        <Edit />
      </IconButton>
    </Tooltip>
  );
}

const columns: MUIDataTableColumn[] = [
  {
    name: 'id',
    label: 'ID',
    options: {
      filter: false,
      sort: false,
      display: false,
      print: false,
      download: false,
      viewColumns: false,
    },
  },
  {
    name: 'displayName',
    label: 'Display Name',
    options: {
      filter: true,
      filterType: 'textField',
      sort: true,
    },
  },
  {
    name: 'duration',
    label: 'Duration',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value: number) => parseDurationInSecondsToText(value),
    },
  },
  {
    name: 'Actions',
    options: {
      filter: false,
      sort: false,
      print: false,
      download: false,
      empty: true,
      customBodyRender: (value, tableMeta) => (
        <EditButton maintenanceTypeId={tableMeta.rowData[0]} />
      ),
    },
  },
];

export default function ListMaintenancesTypes() {
  const navigate = useNavigate();
  const { checkUserHasRole } = useAuthorization();

  const [maintenancesTypesList, setMaintenancesTypesList] = useState<
    IMaintenanceType[]
  >([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const maintenancesTypes =
          await maintenancesService.listMaintenanceTypes();
        setMaintenancesTypesList(maintenancesTypes);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const addButton = useCallback(
    () => (
      <Button
        onClick={() => {
          navigate('/maintenance/types/new');
        }}
        variant="outlined"
        disabled={!checkUserHasRole([UserRoles.ADMIN])}
        startIcon={<Add />}
      >
        New maintenance type
      </Button>
    ),
    [checkUserHasRole]
  );

  return (
    <MUIDataTable
      title="Maintenances Types"
      data={maintenancesTypesList}
      columns={columns}
      options={{
        print: false,
        download: false,
        viewColumns: false,
        filter: false,
        rowHover: true,
        selectableRows: 'none',
        customToolbar: addButton,
        textLabels: {
          body: {
            noMatch: loading ? (
              <CircularProgress />
            ) : (
              'Sorry, no matching records found'
            ),
          },
        },
      }}
    />
  );
}
