import Dialog from './Dialog';
import { DialogProps, DialogRef } from './types';

let ref: DialogRef;

export function setDialogRef(dialogRef: DialogRef) {
  ref = dialogRef;
}

export function openDialog(props: DialogProps) {
  if (ref) {
    ref.openDialog(props);
  }
}

export function updateOpenedDialog(props: Partial<DialogProps>) {
  if (ref) {
    ref.updateOpenedDialog(props);
  }
}

export function closeDialog() {
  if (ref) {
    ref.closeDialog();
  }
}

export default Dialog;
