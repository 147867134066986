import { useCallback, useEffect, useMemo, useState } from 'react';

import { ExpandMore, Task } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  List,
  Stack,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';

import { closeDialog } from '@/components/Dialog';
import openConfirmDialog from '@/components/openConfirmDialog';
import { formatCurrency } from '@/helpers/currency';
import { formatDateWithTime } from '@/helpers/date';
import AppError from '@/interfaces/AppError';
import { IVehicleExpense } from '@/interfaces/vehicles/IVehicleExpense';
import maintenancesService from '@/services/maintenances';
import vehiclesService from '@/services/vehicles';

import ExpenseForm from './components/ExpenseForm';
import ExpenseItem from './components/ExpenseItem';

interface Props {
  vehicleId: string;
  maintenanceId?: string;
  readOnly?: boolean;
}

export default function MaintenanceExpenses({
  readOnly,
  vehicleId,
  maintenanceId,
}: Props) {
  const [expenses, setExpenses] = useState<IVehicleExpense[]>([]);
  const [selectedExpenseId, setSelectedExpenseId] = useState<string>();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchExpenses = async () => {
      try {
        if (maintenanceId) {
          const expensesResponse =
            await maintenancesService.listMaintenanceExpenses(maintenanceId);
          setExpenses(expensesResponse);
        } else {
          const expensesResponse =
            await maintenancesService.listVehicleExpenses(vehicleId);
          setExpenses(expensesResponse);
        }
      } catch (error) {
        console.error(error);
        enqueueSnackbar(
          error instanceof AppError
            ? error?.message
            : 'Error on loading expenses. Please, try again later',
          { variant: 'error' }
        );
      }
    };

    fetchExpenses();
  }, [enqueueSnackbar, maintenanceId, vehicleId]);

  const selectedExpense = useMemo(
    () => expenses.find(expense => expense.id === selectedExpenseId),
    [selectedExpenseId, expenses]
  );

  const expensesCost = useMemo(
    () => expenses.reduce((agg, exp) => agg + exp.amount, 0),
    [expenses]
  );

  const handleRemove = useCallback(
    async (expenseId: string) => {
      openConfirmDialog({
        title: 'Delete Expense',
        onConfirm: async () => {
          try {
            await vehiclesService.deleteVehicleExpense(expenseId);
            setExpenses(prev => [...prev].filter(exp => exp.id !== expenseId));
            enqueueSnackbar('Expense deleted with success.', {
              variant: 'success',
            });
          } catch (error) {
            console.error(error);
            enqueueSnackbar(
              error instanceof AppError
                ? error?.message
                : 'Error on deleting expense. Please, try again later',
              { variant: 'error' }
            );
          }
          closeDialog();
        },
      });
    },
    [enqueueSnackbar]
  );

  return (
    <>
      {!readOnly && (
        <ExpenseForm
          vehicleId={vehicleId}
          maintenanceId={maintenanceId}
          open={typeof selectedExpenseId === 'string'}
          onClose={() => setSelectedExpenseId(undefined)}
          onSuccess={expense => {
            if (!selectedExpense) {
              setExpenses(prev => [...prev, expense]);
            } else {
              setExpenses(prev => [
                ...prev.filter(e => e.id !== selectedExpense.id),
                expense,
              ]);
            }
          }}
          initialData={
            selectedExpense && {
              expenseId: selectedExpense.id,
              amount: selectedExpense.amount,
              description: selectedExpense.description,
              expenseCategory: {
                id: selectedExpense.expenseCategory.id,
                name: selectedExpense.expenseCategory.name,
              },
            }
          }
        />
      )}

      <Accordion
        sx={{ width: '100%', marginBottom: 2 }}
        defaultExpanded={!!maintenanceId}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Stack gap={1} direction="row" alignItems="center">
              <Task />
              <Typography variant="h6">
                {`Expenses (Total: ${formatCurrency(expensesCost)})`}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center">
              {!readOnly && (
                <Button
                  sx={{ marginRight: '16px' }}
                  variant="contained"
                  onClick={() => setSelectedExpenseId('')}
                >
                  New expense
                </Button>
              )}
            </Stack>
          </Stack>
        </AccordionSummary>
        <AccordionDetails sx={{ maxHeight: 500, overflow: 'auto' }}>
          {expenses.length === 0 ? (
            <Typography>No expense was found.</Typography>
          ) : (
            <List
              sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
              disablePadding
            >
              {expenses.map(expense => (
                <ExpenseItem
                  key={`maintenance-expense-${expense.id}`}
                  amount={expense.amount}
                  categoryName={expense.description}
                  createdDate={formatDateWithTime(expense.createdAt)}
                  maintenanceId={expense.maintenance?.id}
                  categoryDescription={expense.expenseCategory.name}
                  onClickEdit={
                    !readOnly
                      ? () => setSelectedExpenseId(expense.id)
                      : undefined
                  }
                  onClickRemove={
                    !readOnly ? () => handleRemove(expense.id) : undefined
                  }
                />
              ))}
            </List>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
}
