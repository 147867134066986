export enum ISubscriptionContractType {
  INITIAL = 'INITIAL',
  PARTIAL = 'PARTIAL',
  COMPLETE = 'COMPLETE',
}

export interface ICreateSubscriptionContractDTO {
  subscriptionId: number;
  type: ISubscriptionContractType;
  subscriptionVehicleId?: string;
}
