import { useEffect, useMemo, useState } from 'react';

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from '@mui/material';

import { parseDurationInSecondsToText } from '@/helpers/maintenances';

const formatDurations = (maxHours: number) => {
  const durations = Array.from({ length: maxHours * 2 }).map((_, index) => {
    const duration = (index + 1) * 30 * 60;

    return {
      text: parseDurationInSecondsToText(duration),
      duration,
    };
  });

  return durations;
};

interface Props extends Omit<SelectProps, 'value' | 'onChange'> {
  disableMargin?: boolean;
  onChange: (duration: number) => void;
}

export default function DurationSelect({
  defaultValue,
  disableMargin,
  onChange,
  ...rest
}: Props) {
  const durations = useMemo(() => formatDurations(5), []);

  const [selectedDuration, setSelectedDuration] = useState<string>(
    defaultValue ? String(defaultValue) : String(durations[0].duration)
  );

  useEffect(() => {
    if (defaultValue) {
      setSelectedDuration(String(defaultValue));
    }
  }, [defaultValue]);

  return (
    <FormControl sx={!disableMargin ? { marginTop: 2, marginBottom: 2 } : undefined} fullWidth>
      <InputLabel id="duration">Duration</InputLabel>
      <Select
        labelId="duration"
        label="Duration"
        value={selectedDuration}
        onChange={e => {
          setSelectedDuration(String(e.target.value));
          onChange(parseInt(e.target.value, 10));
        }}
        {...rest}
      >
        {durations.map(duration => (
          <MenuItem
            key={`duration-${duration.duration}`}
            value={duration.duration}
          >
            {duration.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
