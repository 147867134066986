import { useCallback, useEffect, useState } from 'react';

import { Add, Delete, OpenInNew } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';

import { closeDialog } from '@/components/Dialog';
import openConfirmDialog, {
  updateOpenedConfirmDialog,
} from '@/components/openConfirmDialog';
import UploadDropzoneDialog from '@/components/UploadDropzoneDialog';
import AppError from '@/interfaces/AppError';
import ISubscriptionFile from '@/interfaces/subscriptions/ISubscriptionFile';
import subscriptionsService from '@/services/subscriptions';

type Props = {
  subscriptionId: number;
};

export default function SubscriptionFiles({ subscriptionId }: Props) {
  const [subFiles, setSubFiles] = useState<ISubscriptionFile[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadFilesDialog, setUploadFilesDialog] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const fetchSubscriptionFiles = useCallback(async () => {
    try {
      setIsLoading(true);
      const fetchedSubFiles = await subscriptionsService.listSubscriptionFiles(
        subscriptionId
      );
      setSubFiles(fetchedSubFiles);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(
        error instanceof AppError
          ? error?.message
          : 'Error on fetching subscription files. Please, try again later',
        { variant: 'error' }
      );
    }
    setIsLoading(false);
  }, [subscriptionId]);

  useEffect(() => {
    fetchSubscriptionFiles();
  }, [fetchSubscriptionFiles]);

  const deleteSubscriptionFile = useCallback(
    (fileId: string) => {
      openConfirmDialog({
        onConfirm: async () => {
          try {
            updateOpenedConfirmDialog({
              confirmButtonText: 'Deleting...',
              confirmButtonProps: {
                disabled: true,
              },
              cancelButtonProps: {
                disabled: true,
              },
            });
            await subscriptionsService.deleteSubscriptionFile(
              subscriptionId,
              fileId
            );
            closeDialog();
            fetchSubscriptionFiles();
          } catch (error) {
            closeDialog();
            console.error(error);
            enqueueSnackbar(
              error instanceof AppError
                ? error?.message
                : 'Error on deleting subscription file. Please, try again later',
              { variant: 'error' }
            );
          }
        },
      });
    },
    [enqueueSnackbar, fetchSubscriptionFiles, subscriptionId]
  );

  return (
    <div>
      <Stack direction="row" mb={2} justifyContent="space-between">
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Box pt={2}>
            {subFiles.length === 0 ? (
              <Typography variant="body2">No subscription files</Typography>
            ) : (
              <Typography variant="body1">
                These are the files related to this subscription:
              </Typography>
            )}
          </Box>
        )}
        <Button
          variant="outlined"
          onClick={() => setUploadFilesDialog(true)}
          startIcon={<Add />}
          disabled={isLoading}
        >
          Add files
        </Button>
      </Stack>
      <List dense>
        {subFiles.map(subFile => {
          console.log(subFile.url);
          return (
            <ListItem
              key={subFile.id}
              secondaryAction={
                <>
                  <Tooltip title="Preview">
                    <IconButton
                      aria-label="preview"
                      onClick={() =>
                        window.open(encodeURI(subFile.url!), '_blank')
                      }
                    >
                      <OpenInNew />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete file">
                    <IconButton
                      aria-label="delete"
                      onClick={() => {
                        deleteSubscriptionFile(subFile.id);
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </>
              }
            >
              <ListItemText primary={subFile.path} />
            </ListItem>
          );
        })}
      </List>

      <UploadDropzoneDialog
        open={uploadFilesDialog}
        dialogTitle="Upload Subscription Files"
        acceptedFiles={[
          'image/jpeg',
          'image/png',
          'image/bmp',
          'application/pdf',
        ]}
        onSave={async files => {
          try {
            setIsLoading(true);
            setUploadFilesDialog(false);

            await subscriptionsService.uploadSubscriptionFiles(
              subscriptionId,
              files
            );
            setIsLoading(false);
            enqueueSnackbar('Subscription Files uploaded successfully', {
              variant: 'success',
            });
            fetchSubscriptionFiles();
          } catch (error) {
            setIsLoading(false);
            console.error(error);
            enqueueSnackbar(
              error instanceof AppError
                ? error?.message
                : 'Error on uploading subscription files',
              { variant: 'error' }
            );
          }
        }}
        onClose={() => setUploadFilesDialog(false)}
      />
    </div>
  );
}
