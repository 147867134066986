import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import {
  Breadcrumbs as MUIBreadcrumbs,
  BreadcrumbsProps,
  Grid,
  Typography,
} from '@mui/material';

import { getPageTitle } from '@/helpers/page';
import { useMeta } from '@/hooks/meta';

import LinkRouter from './LinkRouter';

export default function Breadcrumbs(props: BreadcrumbsProps) {
  const location = useLocation();
  const pathnames = useMemo(
    () => location.pathname.split('/').filter(p => !!p),
    [location]
  );
  const { pageTitle } = useMeta();

  const title = useMemo(() => {
    const path = location.pathname;
    return getPageTitle(path, pathnames.at(-1) ?? 'DriveOn');
  }, [location, pathnames]);

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Typography variant="h4" noWrap component="div">
          {pageTitle || title}
        </Typography>
      </Grid>
      <Grid item>
        <MUIBreadcrumbs aria-label="breadcrumb" {...props}>
          <LinkRouter underline="hover" color="inherit" to="/">
            DriveOn Subscriptions
          </LinkRouter>
          {pathnames.map((value, index) => {
            const to = `${pathnames.slice(0, index + 1).join('/')}`;
            const pathTitle = getPageTitle(`/${to}`, value);

            return (
              <LinkRouter underline="hover" color="inherit" to={to} key={to}>
                {pathTitle}
              </LinkRouter>
            );
          })}
        </MUIBreadcrumbs>
      </Grid>
    </Grid>
  );
}
