import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

import { useConnectedFormComponents } from '@/components/form';
import { IMaintenanceTasks } from '@/interfaces/maintenance/IMaintenanceTasks';
import maintenancesService from '@/services/maintenances';

type Form = {
  description: string;
};

const schema = Yup.object().shape({
  description: Yup.string().required('description is required'),
});

interface Props {
  maintenanceId: string;
  onCreate: (maintenanceTasks: IMaintenanceTasks) => void;
}

export default function CreateMaintenanceTask({
  maintenanceId,
  onCreate,
}: Props) {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const { handleSubmit, reset, control } = useForm<Form>({
    resolver: yupResolver(schema),
  });

  const { TextInput } = useConnectedFormComponents<Form>({
    control,
  });

  const { enqueueSnackbar } = useSnackbar();

  const handleFormSubmit = async (data: Form) => {
    try {
      setSubmitLoading(true);

      const tasks = await maintenancesService.createMaintenanceTask({
        maintenanceId,
        task: {
          description: data.description,
        },
      });

      onCreate(tasks);
      setOpen(false);
      reset();
      enqueueSnackbar('Task created with success', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar('Error creating task', { variant: 'error' });
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={isOpen}
        onClose={() => setOpen(false)}
      >
        <DialogTitle>New task</DialogTitle>

        <form>
          <DialogContent>
            <TextInput
              margin="none"
              label="Description"
              fullWidth
              fieldName="description"
              required
              multiline
            />
          </DialogContent>
          <DialogActions>
            <Button disabled={submitLoading} onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button
              disabled={submitLoading}
              onClick={handleSubmit(handleFormSubmit)}
            >
              Create
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Button
        sx={{ display: 'flex', marginLeft: 'auto', marginBottom: 2 }}
        variant="contained"
        onClick={() => setOpen(true)}
      >
        New task
      </Button>
    </>
  );
}
