import * as React from 'react';
import { useCallback, useState } from 'react';
import { Controller, FieldValues, Path, UseControllerProps } from 'react-hook-form';

import { Autocomplete, AutocompleteProps, TextFieldProps } from '@mui/material';
import { AutocompleteRenderInputParams } from '@mui/material/Autocomplete/Autocomplete';
import TextField from '@mui/material/TextField';

type MUIAutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> = Omit<
  AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
  'renderInput'
>;

type Props<
  TFieldValues extends FieldValues,
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> = MUIAutocompleteProps<T, Multiple, DisableClearable, FreeSolo> &
  Omit<UseControllerProps<TFieldValues>, 'name'> & {
    options: T[];
    renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
    label?: string;
    fieldName: Path<TFieldValues>;
    textFieldProps?: Omit<TextFieldProps, 'error' | 'helperText' | 'label'>;
  };

export type AutoCompletePropsWithoutConnectionProps<
  TFieldValues extends FieldValues,
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> = Omit<
  Props<TFieldValues, T, Multiple, DisableClearable, FreeSolo>,
  'control'
>;

export default function AutoComplete<
  TFieldValues extends FieldValues,
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>({
  options,
  fieldName,
  control,
  renderInput,
  label,
  textFieldProps,
  multiple,
  ...props
}: Props<TFieldValues, T, Multiple, DisableClearable, FreeSolo>) {
  const [inputValue, setInputValue] = useState('');

  const renderField = useCallback(
    ({ field, fieldState: { error } }) => (
      <Autocomplete<T, Multiple, DisableClearable, FreeSolo>
        disablePortal
        multiple={multiple}
        options={options}
        value={field.value || (multiple ? [] : null)}
        onChange={(event, value) => {
          field.onChange(value);
          let labelValue: string;
          if (props.getOptionLabel && value) {
            labelValue = props.getOptionLabel(value as T);
          } else if (!value) {
            labelValue = '';
          } else {
            labelValue = String(value);
          }
          setInputValue(labelValue);
        }}
        inputValue={inputValue || ''}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={
          renderInput ||
          (params => (
            <TextField
              margin="normal"
              required
              {...(textFieldProps || {})}
              {...params}
              error={!!error}
              helperText={error?.message}
              label={label}
            />
          ))
        }
        {...props}
      />
    ),
    [multiple, options, inputValue, renderInput, props, textFieldProps, label]
  );

  return <Controller render={renderField} control={control} name={fieldName} />;
}
