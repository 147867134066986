import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import * as Yup from 'yup';

import { useConnectedFormComponents } from '@/components/form';

type Form = {
  description: string;
};

interface Props {
  initialData?: Partial<Form>;
  open: boolean;
  onClose: () => void;
  onSubmit: (data: Form) => void;
}

const schema = Yup.object().shape({
  description: Yup.string().required('description is required'),
});

export default function NewTask(props: Props) {
  const { initialData, open, onClose, onSubmit } = props;

  const { handleSubmit, control, reset } = useForm<Form>({
    resolver: yupResolver(schema),
  });

  const { TextInput } = useConnectedFormComponents<Form>({
    control,
  });

  const handleFormSubmit = (data: Form) => {
    onSubmit(data);
    onClose();
    reset();
  };

  useEffect(() => {
    if (!initialData) {
      reset({
        description: '',
      });
    }

    reset(initialData);
  }, [initialData, reset]);

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={onClose}>
      <DialogTitle>{!initialData ? 'New task' : 'Edit task'}</DialogTitle>

      <form>
        <DialogContent>
          <TextInput
            margin="none"
            label="Description"
            fullWidth
            fieldName="description"
            required
            multiline
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleSubmit(handleFormSubmit)}>
            {!initialData ? 'Create' : 'Save'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
